import React from "react";
export default class Loader extends React.Component {

  render() {

    if (this.props.forInput){
      return <div className="spinner-border spinner-border-sm float-right" style={{marginTop:"-26px",marginRight:"10px"}} role="status">
      <span className="sr-only">Loading...</span>
    </div>
    }

    if (this.props.forButton){
      return <div className="spinner-border spinner-border-sm" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    }
    return <div className={`${this.props.containerClass || 'd-flex justify-content-center py-5'} ${this.props.textColor && this.props.textColor}`}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>;
  }
}
