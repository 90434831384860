import React from "react"
import Loader from '../helpers/Loader'
import {AppContext} from '../core/AppContext'

export default class CourseSearchInput extends React.Component {

  state = {
    loading:false,
    courses:[],
    search_input:'',
    chosen:false,
    error:false
  }

  clearSearchTimer = () => this.searchTimer && clearTimeout(this.searchTimer)

  searchCourses = () => {
    let {search_input} = this.state;
    this.clearSearchTimer()
    this.setState({loading:true})
    fetch(`/courses.json?q=${search_input}&partial=true`).then(response => response.json()).then(data => this.setState({courses:data,loading:false}));
  }

  handleSearch = (e) => {
    let target = e.target;
    let {name,value} = target;

    this.setState({search_input:value})
    
    if (value.length > 0 && e.keyCode !== 8){
      this.clearSearchTimer()
      this.searchTimer = setTimeout(this.searchCourses, 1000);
    }

  }

  loadCourse = (course) => {
    fetch(`/courses/${course.id}.json`).then(response => response.json()).then(data => {
      this.setState({loading:false})
      this.props.onChosen && this.props.onChosen(data)
    });
  }

  setCourse = (course) => {
    this.setState({search_input:course.name,courses:[],chosen:true,loading:true},() => this.loadCourse(course))
  }
  
  render() {

    let {loading,courses,chosen,search_input} = this.state;

    return <AppContext.Consumer>
    {
      (context) =>  
        <div className="form-group">
          <div className="d-flex justify-content-between">
            <label htmlFor="courseSearcherInput">Course</label><small id="courseHelp" className="form-text text-muted">Where are you playing?</small>
          </div>
          <input type="text" name="course" value={search_input} placeholder="Search Courses...." className="form-control rounded-pill" id="courseSearcherInput" onChange={this.handleSearch} aria-describedby="courseHelp" />
          {loading && <Loader forInput={true} />}
          {chosen && <button className="btn btn-sm btn-link rounded-circle float-right" style={{marginTop:"-34px",marginRight:"3px"}} onClick={() => {
            this.setState({search_input:'',chosen:false},() => {if (this.props.onRemoved) this.props.onRemoved()})
            }}><i className="fas fa-times"></i></button>}
          {
            courses.length > 0 &&
            <div className="search-results position-absolute">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col">
                    <div className="list-group shadow">
                      {courses.map((c) => <button key={`course_search_result_${c.id}`} onClick={() => this.setCourse(c)} class="list-group-item list-group-item-action">{c.name} <br/><small><em>{c.locality}, {c.region}</em></small></button> )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </AppContext.Consumer>;


  }
}