import React from "react"
import Loader from '../helpers/Loader'
import {getCsrf} from "../helpers/CsrfTags"

export default class Note extends React.Component {

  state = {
    note:this.props.note || {notable_id:this.props.notable_id,notable_type:this.props.notable_type},
    saving:false,
    editing:false,
    deleting:false,
    deleted:false,
    error:false
  }

  updateNote = (e) => {
    
    let target = e.target;
    let {name,value} = target;
    let {note} = this.state;
    
    note[name] = value;

    this.setState({note:note});
  }

  deleteNote = () =>{
    
    let {note} = this.state;
    this.setState({deleting:true})

    fetch(`/notes/${note.id}.json`, {
      method: 'delete',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.text()
    }).then(data => {
      this.setState({note:{},deleting:false,editing:false,deleted:true},() => {
        if (this.props.afterDelete) this.props.afterDelete()
      })
    })
    .catch((response) => {
      response.json().then(error => {
        let field = Object.keys(error)[0];
        this.setState({deleting:false,error:`${field} ${error[field]}`})
      })
    });
  }

  saveNote = () =>{
    
    let {note,saving} = this.state;

    if (saving || (!note || note.content.trim() == '')) return;

    this.setState({saving:true})

    let is_new = !note.id;
 
    fetch(`/notes${!is_new ? `/${note.id}`:''}.json`, {
      method: !is_new ? 'put' : 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({note:note})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      this.setState({note:data,saving:false,editing:false,deleted:is_new},() => {
        if (this.props.afterSave) this.props.afterSave()
      })
    })
    .catch((response) => {
      response.json().then(error => {
        let field = Object.keys(error)[0];
        this.setState({saving:false,error:`${field} ${error[field]}`})
      })
    });
  }

  render() {
    let {note,editing,saving,deleting,deleted} = this.state;
    
    if (deleted) return <span />;

    if (editing) return <div>
        <div className="form-group">
          <textarea className="form-control" autoFocus onBlur={this.saveNote} disabled={saving} rows="3" value={note.content} placeholder="Hit it straight you fuck" onChange={this.updateNote} name="content"></textarea>
        </div>
        <div className="mb-3">
          <button className="btn btn-primary rounded-pill shadow-sm mr-2" onClick={this.saveNote} >Save Note {saving && <Loader forButton={true} /> }</button>
          {note.id && <button className="btn btn-danger rounded-pill shadow-sm" onClick={this.deleteNote} >Delete Note {deleting && <Loader forButton={true} /> }</button> }
        </div>
      </div>;
    return note.id ? <li className="list-group-item" onClick={() => this.setState({editing:true})} >{note.content}</li> : <a className="btn btn-dark btn-lg rounded-pill shadow-sm my-4 mx-3" onClick={() => this.setState({editing:true})}>+ Add Note</a>;
  }
}
