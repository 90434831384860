import React from "react"
import Loader from '../helpers/Loader'
import {getCsrf,setObjectValueFromDotNotation} from "../helpers/CsrfTags"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default class YardageBook extends React.Component {

  state = {
    yardage_book:false,
    loading:true,
    saving:false,
    saved:false,
    editing:false,
    error:false
  }

  componentDidMount(){
    this.loadYardageBook();
  }

  loadYardageBook(){
    fetch(`${this.props.url || location.pathname}.json`).then(response => response.json()).then(data => this.setState({yardage_book:data,loading:false}));
  }

  updateYardageBook = (e,save) =>{
    
    let target = e.target;
    let {name,value} = target;
    let {yardage_book} = this.state;
    setObjectValueFromDotNotation(yardage_book,name,value)

    this.setState({yardage_book:yardage_book},() => {
      if (save) this.saveYardageBook(false);
    })

  }

  saveYardageBook = (switch_editing = true) =>{
    
    let {yardage_book} = this.state;
    this.setState({saving:true})
    
    fetch(`${this.props.url || location.pathname}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({yardage_book:yardage_book})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      this.setState({yardage_book:data,saving:false,saved:true,error:false,editing:switch_editing ? false : true})
    })
    .catch((response) => {
      response.json().then(error => {
        let field = Object.keys(error)[0];
        this.setState({saving:false,error:`${field} ${error[field]}`})
      })
    });

  }

  render() {

    let {yardage_book,loading,editing,saving,saved} = this.state;
    
    if (loading) return <Loader />

    return <div className="card flex-fill align-self-start rounded-0 border-0">
            <div className="card-header text-center bg-dark text-white  rounded-0">
              <strong>{yardage_book.name}</strong>
              <button disabled={saving} className={`btn btn-sm btn-${editing ? 'success' : 'secondary' } shadow-sm top-right position-absolute ml-5`} onClick={editing ? this.saveYardageBook : () => this.setState({editing:!editing})}>
                {editing ? "Save" : "Edit"} {saving && <Loader forButton={true} /> } {saved && <i className="fas fa-check text-white"></i> }
              </button>
            </div>
            <table className="table bg-dark text-white table-bordered mb-0">
              <thead className="bg-dark text-white text-center">
                <tr>
                  <th scope="col">Club</th>
                  {yardage_book.yardage_book_breakpoints_attributes.map((b,index) => <th scope="col" key={`yardage_book_${yardage_book.id}_breakpoint_${b.id}`}>{editing ? <input className="form-control" type="text" value={b.name} name={`yardage_book_breakpoints_attributes.${index}.name`} onChange={this.updateYardageBook} /> : b.name}</th> )}
                </tr>
              </thead>
              <tbody className="text-center">
                {
                yardage_book.yardage_book_clubs_attributes.map((c,club_index) => 
                    <tr key={`yardage_book_${yardage_book.id}_club_${c.id}`}>
                      <th scope="row">
                        {
                          editing && <div className="btn-group" role="group" aria-label="Basic example">
                            <button className="button" className="btn btn-secondary" onClick={() =>  this.updateYardageBook({target:{name:`yardage_book_clubs_attributes.${club_index}.order`,value:c.order + 1}},true)}><FontAwesomeIcon icon={["fas", "arrow-down"]} /></button>
                            <button className="button" className="btn btn-secondary" onClick={() =>  this.updateYardageBook({target:{name:`yardage_book_clubs_attributes.${club_index}.order`,value:c.order - 1}},true)}><FontAwesomeIcon icon={["fas", "arrow-up"]} /></button>
                          </div>
                        }
                        {editing ? <input className="form-control" type="text" value={c.name}  name={`yardage_book_clubs_attributes.${club_index}.name`} onChange={this.updateYardageBook} ></input> : c.name}
                      </th>
                      {c.yardage_book_club_yardage_book_breakpoints_attributes.map((b,club_breakpoint_index) => <td key={`yardage_book_${yardage_book.id}_club_${c.id}_breakpoint_${b.id}`} className="bg-white text-primary" >{editing ? <input className="form-control" type="number" value={b.value} name={`yardage_book_clubs_attributes.${club_index}.yardage_book_club_yardage_book_breakpoints_attributes.${club_breakpoint_index}.value`} onChange={this.updateYardageBook} /> : b.value}</td> )}
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>;
  }
}
