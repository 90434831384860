import React from "react";
import Loader from "../helpers/Loader"
import DatePicker from 'react-datepicker'
import { getCsrf } from "../helpers/CsrfTags"
import { NavLink, Redirect } from 'react-router-dom'
import Moment from 'react-moment'
import "react-datepicker/dist/react-datepicker.css"
import LeaderboardButton from "./LeaderboardButton";
import { AppContext } from "../core/AppContext";

export default class Tournament extends React.Component {

  state = {
    tournament: false,
    users: [],
    loadingUsers: true,
    is_new: this.props.isNew,
    loading: true,
    saving: false,
    editing: this.props.editing
  }

  componentDidMount() {
    let { is_new } = this.state;
    if (is_new) this.setState({ tournament: {}, loading: false, loadingUsers: false });
    else {
      this.loadTournament();
      this.loadUsers();
    }
  }

  loadTournament() {
    fetch(`${location.pathname.replace("/edit", "")}.json`).then(response => response.json()).then(data => this.setState({ tournament: data, loading: false }));
  }

  loadUsers() {
    fetch('/users.json').then(response => response.json()).then(data => this.setState({ users: data, loadingUsers: false }));
  }

  submitTournament = () => {
    let { tournament, editing } = this.state;
    this.setState({ saving: true })
    fetch(`/tournaments${editing ? `/${tournament.id}` : ''}.json`, {
      method: editing ? 'put' : 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({ tournament: tournament })
    }).then(response => response.json()).then(data => this.setState({ tournament: data, saving: false, is_new: false, editing: false }));
  }

  addPlayer = (player) => {
    let { tournament } = this.state;

    fetch(`/tournaments/${tournament.id}/tournament_players.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({
        tournament_player: {
          player_id: player.id
        }
      })
    }).then(response => response.json()).then(_ => window.location.reload());

  }

  removePlayer = (player) => {
    let { tournament } = this.state;

    fetch(`/tournaments/${tournament.id}/tournament_players/${player.id}.json`, {
      method: 'delete',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(_ => window.location.reload());
  }

  updateTournament = (e) => {
    let { tournament } = this.state;
    let target = e.target;
    let { name, value } = target;
    tournament[name] = value;
    this.setState({ tournament: tournament });
  }

  render() {

    let { tournament, users, loading, loadingUsers, saving, editing, is_new } = this.state;

    if (this.props.isNew && tournament.id) return <Redirect to={`/tournaments/${tournament.id}`} />;

    if (loading || loadingUsers) return <Loader />;

    const tournamentPlayerIds = tournament.players.map(player => player.id);
    const availablePlayers = users.filter(user => !tournamentPlayerIds.includes(user.id));

    return <AppContext.Consumer>
      {
        (context) => <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
          <div className="card shadow flexbox-container rounded-0">
            <div className="card-header text-center">
              {is_new ? <strong>Create new tourney</strong> : tournament.name}
              {!is_new && context.current_user.role.name == 'admin' && <NavLink to={`/tournaments/${tournament.id}/edit`} className="btn btn-sm bg-white shadow-sm top-right position-absolute ml-5" ><i className="fas fa-pencil-alt"></i></NavLink>}
            </div>
            {tournament.id && <LeaderboardButton key={`leaderboard_for_tournament_${tournament.id}`} tournament_id={tournament.id} />}
            <div className="card-body pt-0 pb-5 px-0 px-lg-3">
              {
                (is_new || editing) ?
                  <form className="p-3">
                    <div className="form-group">
                      <label>Name</label>
                      <input placeholder="New tournament" className="form-control" type="text" name="name" value={tournament.name} onChange={this.updateTournament} />
                    </div>
                    <div className="form-group">
                      <label>Starts</label> <br />
                      <DatePicker selected={tournament.start_time ? new Date(tournament.start_time) : null} className="form-control" onChange={date => this.updateTournament({ target: { name: "start_time", value: date } })} />
                    </div>
                    <div className="form-group">
                      <label>End</label> <br />
                      <DatePicker selected={tournament.end_time ? new Date(tournament.end_time) : null} className="form-control" onChange={date => this.updateTournament({ target: { name: "end_time", value: date } })} />
                    </div>
                    <div className="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" defaultChecked={tournament.add_all_players} id="allPlayersSwitch" onChange={(e) => this.updateTournament({ target: { name: "add_all_players", value: !tournament.add_all_players } })} />
                        <label className="custom-control-label" for="allPlayersSwitch">Add all players</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" defaultChecked={tournament.auto_add_new_signups} id="autoAddSignupsSwitch" onChange={(e) => this.updateTournament({ target: { name: "auto_add_new_signups", value: !tournament.auto_add_new_signups } })} />
                        <label className="custom-control-label" for="autoAddSignupsSwitch">Auto add new sign ups</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" defaultChecked={tournament.add_leaderboard} id="addLeaderbaord" onChange={(e) => this.updateTournament({ target: { name: "add_leaderboard", value: !tournament.add_leaderboard } })} />
                        <label className="custom-control-label" for="addLeaderbaord">Add leaderboard</label>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button onClick={this.submitTournament} className="btn btn-lg btn-block btn-secondary rounded-pill shadow-sm" disabled={saving}>{editing ? 'Update Tournament' : 'Create Tournament'} {saving && <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span>} </button>
                    </div>
                  </form> :
                  <div className="p-3">
                    <label><strong>When</strong></label> <br />
                    <h5><Moment format="MM/DD/YYYY">{tournament.start_time}</Moment> - <Moment format="MM/DD/YYYY">{tournament.end_time}</Moment></h5>
                    <hr />
                    <label><strong>Rounds</strong></label><br />
                    <ul className="list-group">
                      {tournament.round_settings.map((round) => <NavLink to={context.current_user.role.name == 'admin' ? `/round_settings/${round.id}` : `/rounds/${round.id}`} className="list-group-item" >{round.name}</NavLink>)}
                      {context.current_user.role.name == 'admin' && <NavLink to={`/tournaments/${tournament.id}/rounds/new`} className="btn btn-block btn-sm btn-secondary shadow-sm mt-2" ><i className="fas fa-plus"></i> Add Round</NavLink>}
                    </ul>
                    <hr />
                    <label><strong>Players</strong> ({tournament.players.length})</label> <br />
                    <ul className="list-group">
                      {tournament.players.map((player) => <li className="list-group-item position-relative">
                        {player.name}
                        <button onClick={() => this.removePlayer(player)} className="btn btn-sm btn-danger shadow-sm top-right position-absolute ml-5" ><i className="fas fa-trash"></i></button>
                      </li>)}
                    </ul>
                    <hr />
                    <hr />
                    <label><strong>Available Players</strong> ({availablePlayers.length})</label> <br />
                    <ul className="list-group">
                      {availablePlayers.map((player) => <li className="list-group-item position-relative">
                        {player.name}
                        <button onClick={() => this.addPlayer(player)} className="btn btn-sm btn-primary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i></button>
                      </li>)}
                    </ul>
                  </div>
              }
            </div>
          </div>
        </div>}</AppContext.Consumer>;
  }
}
