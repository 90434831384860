import React from "react";
import Loader from '../helpers/Loader'
import actionCable from 'actioncable'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LeaderboardRow from "./LeaderboardRow";

export default class Leaderboard extends React.Component {

  state = {
    leaderboard:{},
    with_handicap:true,
    with_score:true,
    loading:true,
    sort_field:'net_under',
    sort_direction:1,
    live_updates:true
  }

  componentDidMount(){

    this._ismounted = true;

    this.cableApp = {}
    this.cableApp.cable = actionCable.createConsumer();
    this.subscription = this.cableApp.cable.subscriptions.create({
      channel:"LeaderboardChannel"
    },{
      connected: () => this.setState({live_updates:true}),
      disconnected: () => this.setState({live_updates:false}),
      rejected: () => this.setState({live_updates:false}),
      received: this.updateLeaderboard
    })

    this.loadLeaderboard();
  }

  componentWillUnmount() {
    this._ismounted = false;
    if(this.subscription) this.cableApp.cable.subscriptions.remove(this.subscription);
  }

  updateLeaderboard = (data) => {
    if (this._ismounted) this.setState({leaderboard:data})
  }

  toggleWithScore = (e) => {
   this.setState({with_score:!this.state.with_score})
  }

  toggleWithHandicap = (e) => {
    this.setState({with_handicap:!this.state.with_handicap})
   }

  loadLeaderboard(){
    fetch(`${this.props.leaderboard_url || location.pathname}.json`).then(response => response.json()).then(data => this.setState({leaderboard:data,loading:false}));
  }

  render() {

    let {leaderboard,live_updates,loading,with_handicap,with_score,sort_field,sort_direction} = this.state;
    let {with_header} = this.props;
    let previous_index = -1;

    return <div className="col px-0 px-lg-2">
      {with_header && <nav className="navbar bg-white shadow-sm px-2" style={{zIndex:1}}>
            <a className="navbar-brand mx-auto" href="/users/sign_in"><h5 className="mb-0"><span className="text-secondary">We</span>Golf</h5><img src="/img/logo-text-only.png" className="img-fluid d-none" style={{"maxHeight":"27px"}} /></a>
          </nav>}
      {!live_updates && <div className={`alert alert-warning mb-0 text-center rounded-0`} role="alert">
          <strong>Live updates currently off</strong> <em>Hit '<FontAwesomeIcon icon={["fas","sync"]} />' or refresh page to update</em>
      </div>}
    <div className="card shadow flexbox-container rounded-0">
      <div className="card-header text-center">
      <div className="top-left position-absolute">
        {
          (this.props.match && this.props.match.params.id) &&
          <NavLink className="btn btn-sm bg-white shadow-sm mr-2" to="/tournaments" >
            <FontAwesomeIcon icon={["fas","clock"]} />
          </NavLink>
        }  
        <span className="btn btn-sm btn-light shadow-sm" onClick={(e) => { e.target.blur();this.setState({loading:true},this.loadLeaderboard) }} >
          {!loading && <FontAwesomeIcon icon={["fas","sync"]} />} {loading && <Loader forButton={true} />}
        </span>  
      </div>
        Leaderboard
        <div className="top-right position-absolute d-flex mt-1">
          <div className="custom-control custom-switch mr-1">
            <input type="checkbox" checked={with_score} onChange={this.toggleWithScore} class="custom-control-input" id="underSwitch" />
            <label className="custom-control-label" for="underSwitch">Par</label>
          </div>
          <div className="custom-control custom-switch">
            <input type="checkbox" checked={with_handicap} onChange={this.toggleWithHandicap} class="custom-control-input" id="hcpSwitch" />
            <label className="custom-control-label" for="hcpSwitch">HCP</label>
          </div>
          <span className={`d-none btn btn-sm ${!with_score ? 'border border-dark text-dark' : 'bg-dark text-white'} shadow-sm mr-2`} key={`score_button_leaderboard`} onClick={this.toggleWithScore} >
            Under
          </span>  
          <span className={`d-none btn btn-sm ${!with_handicap ? 'border border-secondary text-secondary' : 'bg-secondary text-white'} shadow-sm`} key={`handicap_button_leaderboard`} onClick={this.toggleWithHandicap} >
            HCP
          </span>
        </div>
      </div>
      <div className="card-body pt-0 px-0 px-lg-3">
      <table className="table table-striped table-bordered table-sm">
        {
          leaderboard.name &&
          <thead className="thead-light text-center">
            <tr><th colspan="6">{leaderboard.name}</th></tr>
          </thead>
        }
          <thead className="thead-light text-center">
            <tr>
              <th onClick={() => this.setState({sort_field:'net_under',sort_direction:1,with_handicap:true,with_score:true})}><small className={`${sort_field !== 'net_under' || sort_direction !== 1 || !with_handicap || !with_score ? 'text-primary' : ''}`}>#</small></th>
              <th className="text-left"><small>{leaderboard.has_teams ? "Team" : "Player"}</small></th>
              <th onClick={() => this.setState({sort_direction:sort_direction === -1 ? 1 : -1,sort_field:with_handicap ? with_score ? 'net_under' : 'net_score' : with_score ? 'under' : 'total_score'})}>
                <small>{!with_score ? 'Tot':'Under'}
                  <FontAwesomeIcon icon={["fas","caret-down"]} className={`ml-2 ${(with_handicap && ['net_under','net_score'].indexOf(sort_field) > -1 && sort_direction === -1) || (!with_handicap && ['under','total_score'].indexOf(sort_field) > -1 && sort_direction === -1) ? 'text-primary' : 'text-muted'}`} />
                  <FontAwesomeIcon icon={["fas","caret-up"]} className={`ml-2 ${(with_handicap && ['net_under','net_score'].indexOf(sort_field) > -1 && sort_direction === 1) || (!with_handicap && ['under','total_score'].indexOf(sort_field) > -1 && sort_direction === 1)  ? 'text-primary' : 'text-muted'}`} />
                </small>
              </th>
              {!leaderboard.has_teams && <th onClick={() => this.setState({sort_direction:sort_direction === -1 ? 1 : -1,sort_field:with_handicap ? with_score ? 'net_today_under' : 'net_today_score' : with_score ? 'today_under' : 'today_score'})}>
                <small>
                  Today
                  <FontAwesomeIcon icon={["fas","caret-down"]} className={`ml-2 ${(with_handicap && ['net_today_under','net_today_score'].indexOf(sort_field) > -1 && sort_direction === -1) || (!with_handicap && ['today_under','today_score'].indexOf(sort_field) > -1 && sort_direction === -1) ? 'text-primary' : 'text-muted'}`} />
                  <FontAwesomeIcon icon={["fas","caret-up"]} className={`ml-2 ${(with_handicap && ['net_today_under','net_today_score'].indexOf(sort_field) > -1 && sort_direction === 1) || (!with_handicap && ['today_under','today_score'].indexOf(sort_field) > -1 && sort_direction === 1) ? 'text-primary' : 'text-muted'}`} />
                </small>
              </th>}
              <th><small>Thru</small></th>
              {!leaderboard.has_teams && <th><small>Thru Tot</small></th>}
            </tr>
          </thead>
          <tbody id="leaderboard">
            {
              leaderboard.scores && [...leaderboard.scores].sort((a,b) => (a[sort_field] > b[sort_field]) ? sort_direction : ((b[sort_field] > a[sort_field]) ? -sort_direction : 0)).map((score) => {
                
                let tied = false;

                let index = leaderboard.scores.findIndex(({player:{id}}) => id === score.player.id)

                if (leaderboard.scores[previous_index]){
                  if (score.net_under === leaderboard.scores[previous_index].net_under) tied = true;
                }

                if (!tied) previous_index = index

                return <LeaderboardRow has_teams={leaderboard.has_teams} score={score} index={index} tied={tied} previous_index={previous_index} with_score={with_score} with_handicap={with_handicap} />
              }
            )
            }
        </tbody>
      </table>
      <br/>
      </div>
    </div>
  </div>;
  }
}
