import React from "react"
import Loader from "../helpers/Loader"
import { NavLink, Redirect } from 'react-router-dom'
import {AppContext} from '../core/AppContext'
import Grouping from "./Grouping"
import {getCsrf,createRange,setObjectValueFromDotNotation} from "../helpers/CsrfTags"
export default class Game extends React.Component {

  state = {
    game:{
      round_setting_id:this.props.match.params.round_setting_id,
      game_settings:{},
      groupings:[],
      rounds:[]
    },
    is_new:this.props.match.params.id == "new",
    loading:true,
    saving:false,
    editing:this.props.editing
  }

  componentDidMount(){
    let {is_new} = this.state;
    if (is_new) this.setState({loading:false})
    else this.loadGame();
  }

  loadGame(){
    fetch(`${location.pathname.replace("/edit","")}.json`).then(response => response.json()).then(data => this.setState({game:data,loading:false}));
  }

  submitGame = () => {
    let {game,editing} = this.state;
    this.setState({saving:true})
    fetch(`/games${editing ? `/${game.id}` : ''}.json`, {
      method: editing ? 'put' : 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({game:game})
    }).then(response =>  response.json()).then(data => this.setState({game:data,saving:false,editing:false}));
  }

  updateGame= (e) => {
    let {game} = this.state;
    let target = e.target;
    let {name,value} = target;
    setObjectValueFromDotNotation(game,name,value)
    this.setState(game);
  }

  render() {
    
    let {game,loading,saving,editing,is_new} = this.state;

    if (loading) return <Loader />;

    if (game.id && is_new) return <Redirect to={location.pathname.replace("new",game.id)} />;

    let grouping_round_ids = game.groupings.map((g) => g.grouping_rounds_attributes.map((r) => r.round.id ).flat() ).flat();
    let available_rounds = game.rounds.filter((r) => grouping_round_ids.indexOf(r.id) == -1 );

    return <AppContext.Consumer>
    {
      (context) =>  <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
    <div className="card shadow flexbox-container rounded-0">
      <div className="card-header text-center">
        {is_new ? <strong>New Gamble</strong> : game.name }
        {(!is_new && context.current_user.role.name == "admin") && <NavLink to={`/round_settings/${this.props.match.params.round_setting_id}/games/${game.id}/edit`} className="btn btn-sm bg-white shadow-sm top-right position-absolute ml-5" ><i className="fas fa-pencil-alt"></i></NavLink>}
      </div>
      <div className="card-body pt-0 pb-5 px-0 px-lg-3">
      {
        (is_new || editing) &&
        <form className="p-3">
            <div className="form-group mb-3">
              <label>Name</label>
              <input placeholder="Blue 40 two" className="form-control" type="text" name="name" value={game.name} onChange={this.updateGame} />
            </div>
            <div className="form-group mb-3">
              <label>Format</label> <br/>
              <div className="btn-group shadow-sm" role="group" aria-label="Basic example">
                <button type="button" className={`btn btn-${game.type == 'Game::RandomDraw' ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"type",value:"Game::RandomDraw"}})}>Random Draw</button>
                <button type="button" className={`btn btn-${game.type == 'Game::Skins' ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"type",value:"Game::Skins"}})}>Skins</button>
              </div>
            </div>
            {
              game.type == 'Game::RandomDraw' &&
              <>
              <div className="form-group mb-3">
                <label>Team size</label> <br/>
                <div className="d-flex overflow-auto py-2">
                {createRange(2,4).map((n) => <button type="button" key={`game_${game.id}_team_size_${n}`}  className={`btn btn-lg mr-2 ${n == game.team_size ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => this.updateGame({target:{name:"team_size",value:n}})} >{n}</button>)}
                </div>
              </div>
              <div className="form-group">
                <label>Scoring</label> <br/>
                <div className="btn-group shadow-sm" role="group" aria-label="Basic example">
                  <button type="button" className={`btn btn-${(game.game_settings.scoring && game.game_settings.scoring == 'points') ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"game_settings.scoring",value:"points"}})}>Points</button>
                  <button type="button" className={`btn btn-${(game.game_settings.scoring && game.game_settings.scoring == 'best_ball') ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"game_settings.scoring",value:"best_ball"}})}>Best Ball</button>
                </div>
              </div>
              </>
            }

            {
              (game.type == 'Game::Skins' || (game.game_settings.scoring && game.game_settings.scoring == 'best_ball')) &&
              <div className="form-group mt-3">
                <label>Adjust for HCP?</label> <br/>
                <div className="btn-group shadow-sm" role="group" aria-label="Basic example">
                  <button type="button" className={`btn btn-${game.game_settings.adjusted ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"game_settings.adjusted",value:true}})}>YES</button>
                  <button type="button" className={`btn btn-${!game.game_settings.adjusted ? 'dark' : 'light'}`} onClick={() => this.updateGame({target:{name:"game_settings.adjusted",value:false}})}>NO</button>
                </div>
              </div>
            }

            {
              game.type == 'Game::Skins' &&
              <div className="form-group mt-3">
                <label>How much a skin?</label> <br/>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input placeholder="5" className="form-control" type="number" name="game_settings.payout_per_hole" value={game.game_settings.payout_per_hole} onChange={this.updateGame} />
                  </div>
                </div>
            }

            <div className="form-group mt-5">
              <button onClick={this.submitGame} className="btn btn-lg btn-block btn-secondary rounded-pill shadow-sm" disabled={saving}>{editing ? 'Update Gamble' : 'Create Gamble'} {saving && <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span>} </button>
            </div>
        </form>
      }
      {
        (!is_new && !editing) &&
        <div className="p-3">
          <label><strong>Name</strong></label> <br/>
          <p>{game.name}</p>
          <label><strong>Format</strong></label> <br/>
          <p>{game.type.replace("Game::","")} - <em>{game.game_settings.scoring} {game.game_settings.adjusted && "HCP adjusted"}</em></p>
          {
          game.type == "Game::RandomDraw" &&
            <>
            <label><strong>Scores</strong></label> <br/>
            <ol className="list-group">
              {
                game.scores.map((score,index) => {
                  
                return <li className="list-group-item">
                    <div className="row">
                      <div className="col-1">{index + 1}</div>
                      <div className="col">{score.name}</div>
                      {game.game_settings.scoring == "points" && <div className="col-3">{score.scores.map((s) => s.points ).join(" + ")}</div>}
                      <div className="col-3 text-right"><strong className={index == 0 && 'text-success'}>{game.game_settings.scoring == "points" ? score.points : score.total}</strong></div>
                    </div>
                  </li>
                })
              }
            </ol>
            
            <hr/>

            <label><strong>Available Players</strong></label> <br/>
            {
              available_rounds.length > 0 &&
              <ul className="list-group">
                {
                  available_rounds.map((round) => <li className="list-group-item">
                  {
                  creatingGroups && <div className="custom-control custom-switch pull-left mr-2">
                      <input type="checkbox" className="custom-control-input" disabled={saving} id={`groupSwitchRound${round.id}`} onClick={(e) => this.updateRoundOnGrouping(e,round)} />
                      <label className="custom-control-label" for={`groupSwitchRound${round.id}`}></label>
                    </div>
                  }
                    <div className="row">
                      <div className="col">
                        <strong>{round.player.name}</strong>
                      </div>
                      <div className="col">
                        <strong>{round.final_score}</strong>
                      </div>
                    </div>
                  </li> )
                }
              </ul>
            }
            {
              available_rounds.length == 0 && <p className="text-info text-center">All players have been assigned a team</p>
            } 
            </>

          }
          {
          game.type == "Game::Skins" &&
            <>
            <label><strong>Skins</strong></label> <br/>
            <ol className="list-group px-0">
              {
                Object.keys(game.scores[0].winners).map((key) => {
                  let score = game.scores[0].winners[key][0];

                return <li className="list-group-item">
                    <div className="row">
                      <div className="col">Hole {key}</div>
                      <div className="col">{score.player}</div>
                      <div className="col-3 text-center"><strong>{score.score}</strong></div>
                      <div className="col-3 text-right"><strong className={'text-success'}>${game.scores[0].payout}</strong></div>
                    </div>
                  </li>
                })
              }
            </ol>
            </>
          }

        </div>
      }
      </div>
    </div>
    </div>}</AppContext.Consumer>;
  }
}
