import * as turf from '@turf/turf';

const useBearing = () => {
  
  const radians = (n) => {
    return n * (Math.PI / 180);
  }
  const degrees = (n) => {
    return n * (180 / Math.PI);
  }
  
  const getBearing = (startLngLat,endLngLat) =>{
    let startLat = radians(startLngLat[1]),
    startLong = radians(startLngLat[0]),
    endLat = radians(endLngLat[1]),
    endLong = radians(endLngLat[0]);
  
    var dLong = endLong - startLong;
  
    var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
    if (Math.abs(dLong) > Math.PI){
      if (dLong > 0.0)
         dLong = -(2.0 * Math.PI - dLong);
      else
         dLong = (2.0 * Math.PI + dLong);
    }
  
    return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
  }

  const middlePoint = (lnglat1, lnglat2) => {
    var point1 = turf.point(lnglat1);
    var point2 = turf.point(lnglat2);
    let midpoint = turf.midpoint(point1, point2)
    return midpoint.geometry.coordinates;
  }

  return {getBearing,middlePoint}

}

export default useBearing;