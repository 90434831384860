import React from "react"
import { NavLink } from 'react-router-dom'
import Loader from '../helpers/Loader'
import {getCsrf} from "../helpers/CsrfTags"
import Modal from 'react-bootstrap/Modal'
import Grouping from "./Grouping"
import Moment from 'react-moment'
import 'moment-timezone'

Moment.globalLocal = true;

export default class RoundSettingJoin extends React.Component {

  state = {
    loading:true,
    round_setting:false,
    showModal:false,
    confirmationType:false,
    focused_group_index:0,
    grouping_rounds:[],
    creatingGroups:false,
    saving:false
  }

  componentDidMount(){
    this.loadRoundSetting();
  }

  loadRoundSetting = () =>{
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data =>{ 
      console.log("group setting",data)
      this.setState({round_setting:data,loading:false})
    });
  }

  updateRoundOnGrouping = (e,round) => {

    let {grouping_rounds} = this.state;
    let checked = e.target.checked;
    
    if (checked) grouping_rounds.push({round_id:round.id,player:round.player})
    else grouping_rounds = grouping_rounds.filter((r) => r.round_id != round.id );

    this.setState({grouping_rounds:grouping_rounds});

  }

  saveGroupings = () => {
    
    let {round_setting,grouping_rounds} = this.state;
    
    this.setState({saving:true});

    fetch(`${location.pathname}/groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({grouping:{round_setting_id:round_setting.id,grouping_rounds_attributes:grouping_rounds,showModal:false}})
    }).then(response =>  response.json()).then(data => this.setState({saving:false,creatingGroups:false,grouping_rounds:[]},this.loadRoundSetting));
  }

  randomizeGroupings = () => {
    this.setState({saving:true})
    fetch(`${location.pathname}/randomize_groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.json()).then(data => this.setState({round_setting:data,saving:false,showModal:false}));
  }

  standingsGroupings = () => {
    this.setState({saving:true})
    fetch(`${location.pathname}/standings_groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.json()).then(data => this.setState({round_setting:data,saving:false}));
  }

  render() {
    let {
      loading,
      round_setting,
      saving,
      creatingGroups,
      grouping_rounds,
      showModal,
      confirmationType,
      focused_group_index
    } = this.state;

    if (loading) return <Loader />;

    let grouping_round_ids = round_setting.groupings.map((g) => g.grouping_rounds_attributes.map((r) => r.round.id ).flat() ).flat();

    return <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
    <div className="card shadow flexbox-container rounded-0">
      <div className="alert alert-secondary rounded-0 mb-0 text-center">
        You've been invited by <strong>{round_setting.creator && round_setting.creator.name}</strong> <br/> to join this round
      </div>
      <div className="card-header text-center">
          <strong>{round_setting.name || `Round Setting ${round_setting.id}`}</strong> <br/> <em><Moment format="ddd MMM Qo [at] h:mm A">{round_setting.start_time}</Moment></em>
      </div>
      <div className="card-body pt-0 pb-5 px-0 px-lg-3">
        <div className="p-3">
        <div className="d-flex justify-content-between">
          <label>Tee time</label>
          <small className="form-text text-muted">Which group are you with?</small>
        </div>

        {
          round_setting.groupings.map((g,index) => {
            return <div class={`card border rounded mt-2 ${index === focused_group_index && 'border border-dark shadow'}`} onClick={() => this.setState({focused_group_index:index})}>
              <div className={`card-header px-2 py-1 ${index === focused_group_index && 'font-weight-bold'} d-flex justify-content-between`}>
                  <span>Group {index + 1}</span>
                  <small className="d-flex align-items-center">
                    <i className="fas fa-clock mr-1"></i>
                    <Moment format="M/D/YY [at] h:mm A">
                      {g.start_time}
                    </Moment>
                  </small>
              </div>
              <div className="card-body px-2 pt-1 pb-2">
                {g.grouping_rounds_attributes.length == 0 && <button className={`btn btn-sm btn-light rounded-pill border border-muted mr-2 mt-2`} disabled={index !== focused_group_index} >No players yet</button>}
                {g.grouping_rounds_attributes.map((grouping_rounds_attribute) => <button className={`btn btn-sm btn-outline-dark rounded-pill shadow-sm mr-2 mt-2`} >{grouping_rounds_attribute.round.player.name}</button>)}
                </div>
            </div>
          })
        }
        
      <button className="btn btn-lg btn-secondary rounded-pill shadow btn-block mt-5">Join Group {round_setting.groupings.length > 1 && (focused_group_index + 1)}</button>
        <button className="btn btn-lg btn-light rounded-pill btn-block mt-2 border shadow-sm mt-2">Tell them you can't go</button>
              
        </div>
      </div>
    </div>
  </div>;

  }
}
