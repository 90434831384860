import React from "react"
import Loader from '../helpers/Loader'
import { AppContext } from '../core/AppContext'
import RoundSettingShare from './RoundSettingShare'
import Moment from 'react-moment'

import 'moment-timezone'

Moment.globalLocal = true;

export default class PlayingWithInput extends React.Component {

  state = {
    playing_with_others: false,
    loading: false,
    editing_group_start_time: false,
    courses: [],
    following: [],
    filtered_following: [],
    playing: [{ players: [this.props.context.current_user], start_time: new Date(), max_players: 4 }],
    teams: [],
    search_input: '',
    focused_group_index: 0,
    error: false
  }

  componentDidMount = () => {
    this.loadFollowing()
  }

  loadFollowing = () => {
    fetch(`/users/following.json`).then(response => response.json()).then(data => {
      console.log('data', data);
      this.setState({ following: data, loading: false })
    });
  }

  handleSearch = (e) => {
    let target = e.target;
    let { value } = target;
    let { following } = this.state;
    let filtered_following = !!value ? following.filter((player) => player.name.indexOf(value) > -1) : following;

    this.setState({ filtered_following: filtered_following, search_input: value })

  }

  addGroup = (callback) => {
    let { playing, focused_group_index } = this.state;
    let previous_group = playing[playing.length - 1];

    playing.push({ players: [], start_time: new Date(new Date(previous_group['start_time']).getTime() + 10 * 60000), max_players: previous_group['max_players'] })
    focused_group_index = focused_group_index + 1;
    this.setState({ playing: playing, focused_group_index: focused_group_index },
      () => {
        if (callback && typeof (callback) === "function") {
          callback()
        }
      })
  }

  removeGroup = (index, e) => {

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    let { playing, focused_group_index } = this.state;

    playing.splice(index, 1)

    if (focused_group_index === index && playing[index - 1]) focused_group_index = index - 1
    else if (playing[index]) focused_group_index = index

    this.setState({ focused_group_index: focused_group_index, playing: playing }, () => {
      if (this.props.onUpdate) this.props.onUpdate(playing)
    })

  }

  addPlayer = (player, e) => {

    let { playing, focused_group_index } = this.state;

    let group = playing[focused_group_index];

    if (group.players.length + 1 > group.max_players) this.addGroup(() => { this.addPlayer(player, e) })
    else {
      playing[focused_group_index].players.push(player);
      this.setState({ playing: playing, error: false }, () => {
        if (this.props.onUpdate) this.props.onUpdate(playing)
      })
    }

  }

  clickPlayingTime = (group_index, e) => {

    let { playing } = this.state;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState({ editing_group_start_time: group_index }, () => this[`datetime_group_${group_index}`].focus())

  }

  setMaxPlayers = (group_index, e) => {

    let { playing } = this.state;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    playing[group_index]['max_players'] = parseInt(e.target.value);

    this.setState({ playing: playing }, () => {
      if (this.props.onUpdate) this.props.onUpdate(playing)
    })

  }

  setPlayingTime = (group_index, time) => {
    let { playing } = this.state;
    playing[group_index]['start_time'] = new Date(time);
    playing.forEach((g, index) => {
      if (index != group_index && index > group_index) {
        let previous_group = playing[index - 1];
        if (previous_group) g['start_time'] = new Date(new Date(previous_group['start_time']).getTime() + 10 * 60000)
      }
    })
    this.setState({ playing: playing, error: false }, () => {
      if (this.props.onUpdate) this.props.onUpdate(playing)
    })
  }

  removePlayer = (player, e) => {
    e.target.blur();
    e.preventDefault();

    let { playing, focused_group_index } = this.state;

    if (playing[focused_group_index].players.length == 1 && playing.length == 1) return this.setState({ error: "You must have at least one player playing..." })

    let playerIndex = playing[focused_group_index].players.findIndex((p) => p.id == player.id)
    playing[focused_group_index].players.splice(playerIndex, 1)

    this.setState({ playing: playing }, () => {
      if (this.props.onUpdate) this.props.onUpdate(playing)
    })

  }

  render() {

    let { loading, following, editing_group_start_time,
      filtered_following, playing, search_input,
      playing_with_others, focused_group_index, error } = this.state;
    let { round_setting } = this.props;
    let following_to_use = filtered_following.length > 0 ? filtered_following : following
    let playing_ids = playing.map((p) => p.players.map((player) => player.id)).reduce((a, b) => a.concat(b))

    return <AppContext.Consumer>
      {
        (context) =>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label>Grouping</label>
              <small id="courseHelp" className="form-text text-muted">Playing with anyone?</small>
            </div>
            <div className="btn-group rounded-pill shadow-sm" role="group" aria-label="Basic example">
              <button type="button" className={`btn btn-${playing_with_others ? 'dark' : 'outline-dark'}`} onClick={() => this.setState({ playing_with_others: true })}>Yes</button>
              <button type="button" className={`btn btn-${!playing_with_others ? 'dark' : 'outline-dark'}`} onClick={() => this.setState({ playing_with_others: false, playing: [{ players: [context.current_user] }] }, () => { if (this.props.onUpdate) this.props.onUpdate([{ players: [context.current_user] }]) })}>No</button>
            </div>
            {
              playing_with_others &&
              <>
                {
                  error && <div className="alert alert-danger text-center mt-3">
                    <strong>Uh oh!</strong> {error}
                  </div>
                }
                <div className="card mt-3">
                  <div className="card-body px-2 pt-2 pb-1">
                    <button className="btn btn-sm btn-light rounded-pill shadow-sm float-right" onClick={this.addGroup}>+ Add Group</button>
                    <h5 className="card-title mt-1">Tee times</h5>
                    {
                      playing.map((p, index) => {
                        return <div class={`card border rounded mt-2 ${index === focused_group_index && 'border border-dark shadow'}`} key={`group_${index}_focused_${focused_group_index}`} onClick={() => this.setState({ focused_group_index: index })}>
                          <div className={`card-header px-2 py-1 ${index === focused_group_index && 'font-weight-bold'} d-flex justify-content-between`}>
                            <span>Group {index + 1}</span>
                            <input className={`form-control-sm w-50 ${editing_group_start_time !== index && 'd-none'}`} type="datetime-local" autoFocus={editing_group_start_time === index} onBlur={() => this.setState({ editing_group_start_time: false })} ref={input => this[`datetime_group_${index}`] = input} value={p.start_time} onChange={e => this.setPlayingTime(index, e.target.value)} />
                            {editing_group_start_time !== index && <button className="btn btn-sm px-2 py-0 btn-light d-flex align-items-center border border-muted rounded-pill shadow-sm" onClick={(e) => this.clickPlayingTime(index, e)}>
                              <i className="fas fa-clock mr-1"></i>
                              <Moment format="M/D/YY [at] h:mm A">
                                {p.start_time}
                              </Moment>
                            </button>}

                          </div>
                          <div className="card-body px-2 pt-1 pb-2">
                            {p.players.length == 0 && <button className={`btn btn-sm btn-light rounded-pill border border-muted mr-2 mt-2`} disabled={index !== focused_group_index} >Waiting for Players</button>}
                            {p.players.map((player) => <button className={`btn btn-sm btn-${player.id === context.current_user.id ? 'dark' : 'outline-dark'} rounded-pill shadow-sm mr-2 mt-2`} onClick={(e) => this.removePlayer(player, e)}>{player.id === context.current_user.id ? "You" : player.name} <i className="ml-2 fas fa-times"></i></button>)}
                          </div>
                          <div className="card-footer px-2 py-2 bg-white d-flex justify-content-between">
                            <select style={{ height: "auto", fontSize: ".8rem", width: "40%" }} onChange={(e) => this.setMaxPlayers(index, e)} className="form-control-sm bg-light rounded-pill border py-0 px-2 custom-select" id="inputGroupSelect01">
                              <option selected={p.max_players == 1} value={1}>1 players max</option>
                              <option selected={p.max_players == 2} value={2}>2 players max</option>
                              <option selected={p.max_players == 3} value={3}>3 players max</option>
                              <option selected={p.max_players == 4} value={4}>4 players max</option>
                              <option selected={p.max_players == 5} value={5}>5 players max</option>
                              <option selected={p.max_players == 6} value={6}>6 players max</option>
                              <option selected={p.max_players == 7} value={7}>7 players max</option>
                              <option selected={p.max_players == 8} value={8}>8 players max</option>
                            </select>
                            <span className="d-none btn btn-sm btn-light shadow-sm rounded-pill py-0 px-2 border"><small>4 players max <i class="fas fa-angle-down"></i></small></span> {index > 0 && <button className="btn btn-sm btn-danger rounded-pill py-0 px-2 shadow-sm" key={`remove_group_${index}`} onClick={(e) => this.removeGroup(index, e)}><small>Remove</small></button>}
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
                {following.length !== playing_ids.length && <input type="text" name="player" value={search_input} className="mt-2 form-control rounded-pill" placeholder="Filter Friends" id="playerSearcherInput" onChange={this.handleSearch} />}
                {loading && <Loader forInput={true} />}
                {filtered_following.length > 0 && <button className="btn btn-sm btn-link rounded-circle float-right" style={{ marginTop: "-34px", marginRight: "3px" }} onClick={() => {
                  this.setState({ filtered_following: [], search_input: '' })
                }}><i className="fas fa-times"></i></button>}

                {
                  following.length > 0 &&
                  <div className="mt-4 overflow-auto" style={{ whiteSpace: "nowrap" }}>
                    {
                      following_to_use.filter((p) => playing_ids.indexOf(p.id) === -1).map((p) => {
                        return <div key={'playerId' + p.id} className="rounded-circle overflow-hidden position-relative shadow-sm mr-2 mb-2 d-inline-block" style={{ width: 100, height: 100 }} onClick={(e) => this.addPlayer(p, e)}>
                          <img className="img-fluid" src={p.profile_picture_url} />
                          <p className="position-absolute text-center w-100 align-center mb-0 bg-dark d-flex justify-content-center align-items-center text-white" style={{ height: 30, bottom: 0 }}>
                            <small>{p.name}</small>
                          </p>
                        </div>
                      })
                    }
                  </div>
                }
                {/* <RoundSettingShare round_setting={round_setting} /> */}
              </>
            }
          </div>
      }
    </AppContext.Consumer>;


  }
}