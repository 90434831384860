import React from "react"
import Modal from 'react-bootstrap/Modal'
import Notes from './Notes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {AppContext} from '../core/AppContext'

export default class NotesButton extends React.Component {

  state = {
    showModal:false,
    loading:true,
    notes:[]
  }

  componentDidMount(){
    this.loadNotes();
  }

  loadNotes = () => {
    let {notable_type,notable_id} = this.props;
    fetch(`/notes.json?notable_type=${notable_type}&notable_id=${notable_id}`).then(response => response.json()).then(data => this.setState({notes:data,loading:false}));
  }

  render() {
    
    let {title,notable_type,notable_id} = this.props;
    let {showModal,notes,loading} = this.state;

    return <AppContext.Consumer>
    {
      (context) => [<button className="btn btn-link position-absolute p-0"  onClick={() => this.setState({showModal:true}, () => context.setNoScrollListeners(true))}><FontAwesomeIcon spin={loading} icon={[notes.length > 0 ? "fas" :"far", "sticky-note"]} /></button>,<Modal show={showModal} onHide={() => this.setState({showModal:false}, () => context.setNoScrollListeners(false))}>
    <Modal.Header closeButton>
      <p className="lead mb-0">
        <i className="fas fa-sticky-note mr-2"></i> Notes for <strong className="ml-1">{title}</strong>
      </p>
    </Modal.Header>
    <Modal.Body className="bg-light">
      <Notes notes={notes} notable_type={notable_type} notable_id={notable_id} />
    </Modal.Body>
    </Modal>]}</AppContext.Consumer>;
  }
}
