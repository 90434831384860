import React from "react"
import Loader from "../helpers/Loader"

export default class Users extends React.Component {

  state = {
    users:[],
    loading:true
  }
  
  componentDidMount(){
    this.loadUsers();
  }

  loadUsers(){
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => this.setState({users:data,loading:false}));
  }

  render() {
    let {users,loading} = this.state;

    return <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
            <div className="card shadow flexbox-container rounded-0">
              <div className="card-header text-center">
                Users
              </div>
              <div className="card-body pt-0 px-0 px-lg-3">
                {loading && <Loader />}
                {
                (!loading && users.length > 0) &&
                  <div className="list-group">    
                  {
                    users.map((user) => <a href={`/users/${user.id}/become`} key={`/users/${user.id}/become`} className="list-group-item list-group-item-action rounded-0" ><strong>{user.name}</strong></a>)
                  }
                  </div>
                }
              </div>
            </div>
          </div>;
  }
}
