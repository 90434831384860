import React, { Component } from 'react'
import {getCsrf} from "../helpers/CsrfTags"
import Loader from '../helpers/Loader'

const AppContext = React.createContext();

const hasParentClass = ( e, classname ) => {
  if(e === document) return false;
  if( e.className.indexOf(classname) !== -1 ) return true;
  return e.parentNode && hasParentClass( e.parentNode, classname );
}

const sideMenuShown = () => {
  const menu = document.getElementById('st-container')
  return menu.className.includes('st-menu-open')
}

const updateSideMenuClass = (showMenu) => {
  const menu = document.getElementById('st-container')
  menu.className = `st-container ${showMenu && 'st-effect-9 st-menu-open'}`
}
class AppProvider extends Component { 
  
  state = {
  	current_user:false,
    loading:true,
    showSideMenu:false,
    showGrouping:false,
    showHoleSpecs:true,
    currentRound:false,
    currentGrouping:false,
    set:(updates,callback) => {
      this.setState(updates,callback)
    },
    setNoScrollListeners:(boolean) => {
      this.noScrollListeners = boolean;
    },
    noScrollListeners:() => this.noScrollListeners,
    toggleSidebar: (e) => sideMenuShown() && hasParentClass(e.target, 'st-menu') && updateSideMenuClass(false),
    openSidebar: (e) => updateSideMenuClass(true),
    closeSidebar: (e) => updateSideMenuClass(false),
    updateAppState : (app_state) => {
        let {current_user} = this.state;
        current_user.app_state = app_state;
        fetch(`/users`, {
          method: 'put',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': getCsrf()
          },
          body: JSON.stringify({user:current_user})
        }).then(response =>  response.text()).then(data => {
          console.log('app state saved');
        });
    }
    
  }

  loadUser(){
    fetch(`/users/me.json`).then(response => 
        response.json()
      ).then(data => {
      this.setState({current_user:data,loading:false})
    });
  }

  componentDidMount(){
    this.noScrollListeners = false;
    this.loadUser()
  }

  render() {

    let {loading} = this.state;

    return <AppContext.Provider value={this.state} >
        {
          loading && <Loader textColor={'text-white'} />
        }
        {!loading && this.props.children}
      </AppContext.Provider>;
  }

}

export {
  AppProvider,
  AppContext
}