import React from "react";
import Loader from "../helpers/Loader"
import { NavLink } from 'react-router-dom'
import {AppContext} from '../core/AppContext'

export default class Leaderboards extends React.Component {

  state = {
    leaderboards:[],
    loading:true
  }
  
  componentDidMount(){
    this.loadLeaderboards();
  }

  loadLeaderboards(){
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => this.setState({leaderboards:data,loading:false}));
  }

  render() {

    let {leaderboards,loading} = this.state;

    return <AppContext.Consumer>
            {
              (context) => <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
                    <div className="card shadow flexbox-container rounded-0">
                      <div className="card-header text-center">
                        <button class="d-none btn btn-sm btn-light shadow-sm top-left position-absolute mr-5" >Refresh</button>  
                        Leaderboards
                        {context.current_user.role.name == 'admin' && <NavLink to="/leaderboards/new" className="btn btn-sm btn-secondary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i></NavLink>}
                      </div>
                      <div className="card-body pt-0 px-0 px-lg-3">
                        {loading && <Loader />}
                        {
                        (!loading && leaderboards.length > 0) &&
                          <div className="list-group">    
                          {
                            leaderboards.map((leaderboard) => <NavLink to={`/leaderboards/${leaderboard.id}`} className="list-group-item list-group-item-action rounded-0" ><strong>{leaderboard.leaderboardable.name}</strong></NavLink>
                            )
                          }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
              }
              </AppContext.Consumer>;
  }
}
