import React, {memo, useEffect, useState} from "react"
import Modal from 'react-bootstrap/Modal'
import Leaderboard from "./Leaderboard"
import LeaderboardAddButton from "./LeaderboardAddButton"

function LeaderboardButton({round_id,tournament_id}){

  const [loading, setLoading] = useState(true)
  const [leaderboard, setLeaderboard] = useState(false)
  const [showModal,setShowModal] = useState(false)

  const loadLeaderboard = async () => {

    let response = await fetch(tournament_id ? `/tournaments/${tournament_id}/leaderboard.json` : `/rounds/${round_id}/leaderboard.json`)
    if (response.status !== 404) {
      let json = await response.json()
      setLeaderboard(json)
    }
    
    setLoading(false)

  }

  useEffect(() => {
    loadLeaderboard()
  },[])

  if (loading) return <span/>
  if (!leaderboard) return <LeaderboardAddButton round_id={round_id} />

  return <>
        <button className="btn btn-sm btn-success btn-block rounded-0 text-white font-weight-bold" onClick={() => setShowModal(true)}>
        View Leaderboard
      </button><Modal dialogClassName="m-0 m-sm-auto" show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="bg-light p-0">
        {showModal && <Leaderboard leaderboard_url={tournament_id ? `/tournaments/${tournament_id}/leaderboard` : `/rounds/${round_id}/leaderboard`} />}
      </Modal.Body>
      </Modal>
    </>;

}

export default memo(LeaderboardButton)
