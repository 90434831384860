import React, { useState } from "react";
import { NavLink, useParams } from 'react-router-dom'
import { getParam } from "../helpers/QueryParameters"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Rules() {

  const [view, setView] = useState(getParam('s') || 'food')
  const { tournament_id } = useParams()

  return <div className="col-md-6 col-xl-4 px-0 align-self-start">
    <div className="card shadow-sm flexbox-container">
      <div className="card-body pb-5">
        <ul className="nav nav-pills nav-fill">
          <li className="nav-item">
            <NavLink to={`/tournaments/${tournament_id}/rules?s=food`} className={`nav-link ${view === 'food' && 'active'}`} activeClassName="" >Food</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/tournaments/${tournament_id}/rules?s=golf`} className={`nav-link ${view === 'golf' && 'active'}`} activeClassName="" >Golf</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/tournaments/${tournament_id}/rules?s=house`} className={`nav-link ${view === 'house' && 'active'}`} activeClassName="" >House</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/tournaments/${tournament_id}/rules?s=app`} className={`nav-link ${view === 'app' && 'active'}`} activeClassName="" >App</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/tournaments/${tournament_id}/rules?s=odds`} className={`nav-link ${view === 'odds' && 'active'}`} activeClassName="" >Odds</NavLink>
          </li>
        </ul>

        <hr />
        {
          view == 'food' &&
          <span>
            <h5 className="font-weight-bolder">Food Schedule (estimated)</h5>
            <p className="mb-3">** Tom and Co</p>
            <h6><strong>Wed</strong></h6>
            <ul className="pl-3">
              <li><strong>Dinner</strong> CostCo Pizzas/Lasagnas - Salad - Garlic Bread </li>
            </ul>
            <h6><strong>Thurs</strong></h6>
            <ul className="pl-3">
              <li><strong>Breakfast</strong> Eggs, Bacon, Sausage, Toast, Fruit, Coffee</li>
              <li><strong>Snack</strong> Uncrustables for the course <em>(and snacks)</em></li>
              <li><strong>Lunch</strong>** Hot dogs <em>(sides mac & cheese + baked beans avail)</em></li>
              <li><strong>Dinner</strong> Catering Briny Swine (BBQ) </li>
            </ul>
            <h6><strong>Fri</strong></h6>
            <ul className="pl-3">
              <li><strong>Breakfast</strong> Eggs, Bacon, Sausage, Toast, Fruit, Coffee</li>
              <li><strong>Snack</strong> Uncrustables for the course <em>(and snacks)</em></li>
              <li><strong>Lunch</strong>** Hot dogs <em>(sides mac & cheese + baked beans avail)</em></li>
              <li><strong>Dinner</strong>Going out <a href="http://pressleysatthemarina.com/">Pressley's</a> and/or <a href="https://www.brinyswine.com/">Briny Swine</a></li>
            </ul>
            <h6><strong>Sat</strong></h6>
            <ul className="pl-3">
              <li><strong>Breakfast</strong> Eggs, Bacon, Sausage, Toast, Fruit, Coffee</li>
              <li><strong>Snack</strong> Uncrustables for the course <em>(and snacks)</em></li>
              <li><strong>Lunch</strong> Hot dogs + Brats  <em>(sauerkraut, chips, fruit avail)</em></li>
              <li><strong>Dinnner</strong> Catering Low Country Boil from Flowers </li>
            </ul>
            <h5 className="mb-1 font-weight-bolder">We've got food in the house - Snacks N' Such :</h5>
            <ul className="pl-3">
              <li>Sandwich stufff, pickles, lettuce, tomato, mustard mayo, peanut butter jelly</li>
              <li>Chips and Salsa and Stuff for Nachos</li>
              <li>Hot pockets (freezer - check outside too)</li>
              <li>Chicken Melts (freezer - check outside too)</li>
              <li>Chips, Goldfish, Pork Rinds, Pistachios</li>
              <li>Grapes, Apples, Oranges</li>
              <li>Left overs</li>
            </ul>
          </span>
        }
        {
          view == 'golf' &&
          <span>
            <h5 className="mb-3 font-weight-bolder">Course</h5>
            <p>The Plantation Course at Edisto</p>
            <div className="mb-3">
              <a type="button" href="tel:8438691111" class="btn btn-light rounded-pill mr-2 mb-2 shadow-sm">Call <FontAwesomeIcon icon={["fas", "mobile-alt"]} /></a>
              <a type="button" href="https://www.google.com/maps/dir//The+Plantation+Course+at+Edisto,+19+Fairway+Dr,+Edisto+Island,+SC+29438/@32.4865816,-80.3362003,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88fc3b08d770d483:0xf018547bc24054e4!2m2!1d-80.3362003!2d32.4865816" class="btn btn-light rounded-pill mr-2 mb-2 shadow-sm">Drive <FontAwesomeIcon icon={["fas", "directions"]} /></a>
              <a type="button" href="https://theplantationcourseatedisto.com/" target="_blank" class="btn btn-light mb-2 rounded-pill shadow-sm">View <FontAwesomeIcon icon={["fas", "globe"]} /></a>
            </div>
            <h5 className="mb-3 font-weight-bolder">Tee Times</h5>
            <p><strong>Thurs 8/22 - Sat 8/24</strong> Starting at 8:32am Thurs + Fri | 9:28am Sat</p>
            <h5 className="mb-3 font-weight-bolder">Payout</h5>
            <p>Big Bet</p>
            <ol className="pl-3">
              <li><strong>$1200</strong></li>
              <li><strong>$500</strong></li>
              <li><strong>$200</strong></li>
              <li><strong>$100</strong></li>
            </ol>
            <p>Side Stuff</p>
            <ol className="pl-3">
              <li><strong>Two $50 Closest to the pins</strong> Thurs/Fri</li>
              <li><strong>Three $50 Closest to the pins</strong> Sat</li>
              <li><strong>Extreeeeesssss</strong></li>
            </ol>

            <h5 className="mb-3 font-weight-bolder">Tournament rules</h5>
            <p>The local rules we'll be adopting are a breakfast ball <strong>2 off the first tee</strong> <br /> <em>Edisto doesn't have a real driving range</em></p>
            {/* <p>It's going to be a wet weekend so <strong>pick, clean and place everywhere.</strong></p> */}
            <p>#4 <strong>The Fence line on the left side is played as red stakes.</strong></p>
            <p>#5 <strong>The entire right side is played as red stakes.</strong></p>
            <p>Outside of this we will be playing standard tournament rules</p>
            <ol className="pl-3">
              <li><strong>No gimmes</strong> <em>putt everything out</em></li>
              <li><strong>10 stroke max</strong> <em>on every hole</em></li>
              <li><strong><button type="button" className="btn btn-sm btn-lg mr-2 btn-light rounded-circle shadow-sm mb-2"><i className="fas fa-grip-lines-vertical fa-lg text-danger"></i></button>Red stakes</strong> <em>lateral hazard</em></li>
              <li><strong><button type="button" className="btn btn-sm btn-lg mr-2 btn-dark rounded-circle shadow-sm"><i className="fas fa-grip-lines-vertical fa-lg text-white shadow-sm"></i></button>White stakes</strong> <em>one stroke penalty from your last shot or in the fairway at the point of entry with 2 stroke penalty</em></li>
              <li><strong>Accurate Scoring ONLY</strong> <em>2 stroke penalty if found guilty</em> (Looking at you Champ) </li>
              <li><strong>Play with integrity</strong> <em>Don't be a dick</em></li>
              <li><strong>Hold each other accountable</strong> <em>No collusion</em></li>
            </ol>

            <p className="text-info text-center"><strong>When in doubt <em>review hazard and OB rules below</em></strong></p>

            <div className="embed-responsive embed-responsive-16by9 mb-4">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/HEwNzgC9ge4" allowfullscreen />
            </div>

            <div className="embed-responsive embed-responsive-16by9 mb-5">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/2KYoSvWx3MI" allowfullscreen />
            </div>
          </span>
        }
        {
          view == 'house' &&
          <span>
            <p className="text-info text-center font-weight-bold">Everyone needs to <strong>bring towels</strong> and <strong>sheets for your room (or a blanket and a pillow)</strong>.</p>
            <h5 className="mb-3 font-weight-bolder">Address(s)</h5>
            <p>3510 Palmetto Blvd Edisto Island</p>
            <p>3511 Palmetto Blvd Edisto Island</p>
            <h5 className="mb-3 font-weight-bolder">Cleaning Duties</h5>
            <p>Pick up all trash and make sure kitchen looks decent</p>

            <h5 className="mb-3 font-weight-bolder">Sandwich Duty</h5>
            <p>
              Help Tom with Breakfast if need be.
              Help everyone get an uncrustable leave some hotdogs rolling before the round.
              After the round be ready to help with dinner setup.
            </p>

            <ol className="pl-3">
              <li><strong>Thurs Morning</strong> <em>Alan, Craig, Looper, Jay, Taylor, Tom.</em>.</li>
              <li><strong>Fri Morning</strong> <em>Vince, Champ, Adawg, Miller, Robbie, Hunter.</em></li>
              <li><strong>Sat Morning</strong> <em>Hamp, Zack, Thomas, Drew, Fowler, Willie.</em></li>
            </ol>

            <h5 className="mb-3 font-weight-bolder">Sleeping Arrangements (TBD)</h5>
            <p>
              This year we should have a bed for every head!
            </p>
            <ul className="pl-3">
              <li><strong>Tom</strong> Blue Room <em>(Upstairs Back Left)</em></li>
              <li><strong>Austin</strong> Green Room <em>(Upstairs Back Right - king sheets)</em></li>
              <li><strong>Craig, Drew</strong> Pink Room <em>(Upstairs Front Right - queen and a twin sheets)</em></li>
              <li><strong>Alan, Zack</strong> Yellow Room <em>(Upstairs Front Left - queen and a twin sheets)</em></li>
              <li><strong>Hamp, Champ</strong> Twin Bed Room <em>(Downstairs Front Right - two twins)</em></li>
              <li><strong>Vince </strong> Queen Bed Room <em>(Downstairs Back Right - one queen)</em></li>
              <li><strong>Thomas, Taylor, Miller, Fowler, Looper, Robbie and Hunter </strong> <em>3511 Palmetto Blvd Edisto Island</em></li>
              <li><strong>Jordan, Jay, Willie Free, Witt</strong> <em>Other houses</em></li>
            </ul>

            <p className="text-info text-center"><strong>All this is up for changing <em>yall have at it</em> i'll be in the Blue Room</strong></p>

          </span>
        }
        {
          view == 'app' &&
          <span>

            <h5 className="mb-3 font-weight-bolder">To remember</h5>

            <ol className="pl-3">
              <li><strong>Submit your round after each day</strong> <em>Once submitted rounds can no longer be edited</em></li>
              <li><strong>The leaderboard will update automagically when pulled up</strong> <em>No need to refresh 1000 times</em></li>
              <li><strong>Accurate scores only</strong></li>
            </ol>

            <h5 className="mb-3 font-weight-bolder">Watchis'</h5>
            <div className="embed-responsive embed-responsive-16by9 mb-4">
              <video className="embed-responsive-item" allowFullScreen controls>
                <source src="/app.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

          </span>
        }
        {view === 'odds' &&
          <>
            <p>Miller Lewis <strong>+120</strong></p>
            <p>Hunter Caudle <strong>+160</strong></p>
            <p>Austin Looper <strong>+170</strong></p>
            <p>Taylor Ellenburg <strong>+180</strong></p>
            <p>Hampton Gibbs <strong>+190</strong></p>
            <p>Alan Howle <strong>+200</strong></p>
            <p>Drew Thomas <strong>+210</strong></p>
            <p>Champ Rowland <strong>+210</strong></p>
            <p>Craig Foster <strong>+220</strong></p>
            <p>Robbie Gillespie <strong>+220</strong></p>
            <p>Tom Evans <strong>+220</strong></p>
            <p>Vince Hamilton <strong>+220</strong></p>
            <p>Thomas Wilson <strong>+240</strong></p>
            <p>Jacob Fowler <strong>+240</strong></p>
            <p>Austin Williams <strong>+240</strong></p>
            <p>Jay Johnson <strong>+260</strong></p>
            <p>Zack Cisson <strong>+280</strong></p>
            <p>Jordan Johnson <strong>+300</strong></p>
            <p>Willie Freeman <strong>+330</strong></p>
            <p>Witt Johnson <strong>+400</strong></p>

            <h5 className="mb-3 font-weight-bolder">1st round</h5>
            <p>Looper vs Alan</p>
            <p>Looper <strong>-160</strong></p>
            <p className="mb-3">Alan <strong>+140</strong></p>
            <p>Fowler vs Witt</p>
            <p>Fowler <strong>-200</strong></p>
            <p className="mb-3">Witt <strong>+180</strong></p>
            <p>Miller vs Hunter</p>
            <p>Miller <strong>-140</strong></p>
            <p className="mb-3">Hunter <strong>+125</strong></p>
            <p>Champ vs Hamp</p>
            <p>Hamp <strong>-120</strong></p>
            <p className="mb-3">Champ <strong>+110</strong></p>
            <p>Robbie vs Taylor</p>
            <p>Taylor <strong>-130</strong></p>
            <p className="mb-3">Robbie <strong>+115</strong></p>
            <p>Jordan vs TBone</p>
            <p>TBone <strong>-140</strong></p>
            <p className="mb-3">Jordan <strong>+125</strong></p>
            <p>Drew vs Vince</p>
            <p>Drew <strong>-130</strong></p>
            <p className="mb-3">Vince <strong>+115</strong></p>
            <p>Tom vs Adawg</p>
            <p>Tom <strong>-160</strong></p>
            <p className="mb-3">Adawg <strong>+140</strong></p>
            <p>Willie vs Jay</p>
            <p>Jay <strong>-120</strong></p>
            <p className="mb-3">Willie <strong>+110</strong></p>
            <p>Craig vs Zack</p>
            <p>Craig <strong>-110</strong></p>
            <p className="mb-3">Zack <strong>-110</strong></p>
          </>
        }
      </div>
    </div>
  </div>;
}
