import React from "react"
import { NavLink } from 'react-router-dom'
import Loader from '../helpers/Loader'
import {getCsrf} from "../helpers/CsrfTags"
import Modal from 'react-bootstrap/Modal'
import Grouping from "./Grouping"
import { AppContext } from "../core/AppContext"

export default class RoundSetting extends React.Component {

  state = {
    loading:true,
    round_setting:false,
    showModal:false,
    confirmationType:false,
    grouping_rounds:[],
    creatingGroups:false,
    saving:false
  }

  componentDidMount(){
    this.loadRoundSetting();
  }

  loadRoundSetting = () =>{
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => this.setState({round_setting:data,loading:false}));
  }

  updateRoundOnGrouping = (e,round) => {

    let {grouping_rounds} = this.state;
    let checked = e.target.checked;
    
    if (checked) grouping_rounds.push({round_id:round.id,player:round.player})
    else grouping_rounds = grouping_rounds.filter((r) => r.round_id != round.id );

    this.setState({grouping_rounds:grouping_rounds});

  }

  saveGroupings = () => {
    
    let {round_setting,grouping_rounds} = this.state;
    
    this.setState({saving:true});

    fetch(`${location.pathname}/groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({grouping:{round_setting_id:round_setting.id,grouping_rounds_attributes:grouping_rounds,showModal:false}})
    }).then(response =>  response.json()).then(data => this.setState({saving:false,creatingGroups:false,grouping_rounds:[]},this.loadRoundSetting));
  }

  randomizeGroupings = () => {
    this.setState({saving:true})
    fetch(`${location.pathname}/randomize_groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.json()).then(data => this.setState({round_setting:data,saving:false,showModal:false}));
  }

  standingsGroupings = () => {
    this.setState({saving:true})
    fetch(`${location.pathname}/standings_groupings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.json()).then(data => this.setState({round_setting:data,saving:false}));
  }

  render() {
    let {
      loading,
      round_setting,
      saving,
      creatingGroups,
      grouping_rounds,
      showModal,
      confirmationType
    } = this.state;

    if (loading) return <Loader />;

    let grouping_round_ids = round_setting.groupings.map((g) => g.grouping_rounds_attributes.map((r) => r.round.id ).flat() ).flat();
    let available_rounds = round_setting.rounds.filter((r) => grouping_round_ids.indexOf(r.id) == -1 );

    return <AppContext.Consumer>{
      (context) => <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
    <div className="card shadow flexbox-container rounded-0">
      <div className="card-header text-center">
          {round_setting.name || `Round Setting ${round_setting.id}`}
          {context.current_user.role.name == 'admin' && <NavLink to={`/round_settings/${round_setting.id}/games/new`} className="btn btn-sm btn-secondary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i> Gamble</NavLink>}
      </div>
      <div className="card-body pt-0 pb-5 px-0 px-lg-3">
        <div className="p-3">
          <label><strong>Groupings</strong></label> <br/>
          {
            round_setting.groupings.map((grouping) => <Grouping key={`grouping_${grouping.id}_${grouping.grouping_rounds_attributes.length}_rounds_${available_rounds.map(({id}) => id ).join('_')}`} editable={context.current_user.role.name == 'admin'} grouping={grouping} availableRounds={available_rounds} afterUpdate={this.loadRoundSetting} />)
          }
          {context.current_user.role.name == 'admin' && <>
          <button className="btn btn-dark btn-block rounded-pill shadow-sm my-2" disabled={saving || creatingGroups} onClick={() => this.setState({showModal:true,confirmationType:'Randomize'})}>Random Groupings</button>
          <button className="btn btn-info btn-block rounded-pill shadow-sm my-2" disabled={saving || creatingGroups} onClick={() => this.setState({showModal:true,confirmationType:'Standingize'})}>Group by Standings</button>
          {(round_setting.groupings.map((g) => g.grouping_rounds_attributes.length ).reduce(function(a, b){ return a + b }, 0) < round_setting.rounds.length) && <button className={`btn btn-${creatingGroups ? 'danger' : 'secondary'} btn-block rounded-pill shadow-sm my-2`} disabled={saving} onClick={() => this.setState({creatingGroups:!creatingGroups})}>{creatingGroups ? 'Cancel New Grouping':'New Grouping'}</button>}
          
          <hr/>
          
          {creatingGroups && <button className="btn btn-sm btn-success pull-right rounded-pill shadow" disabled={saving || grouping_rounds.length == 0} onClick={this.saveGroupings} >Create Group</button>}
          
          <label><strong>Available Players</strong></label> <br/>
          {
            available_rounds.length > 0 &&
            <ul className="list-group">
              {
                available_rounds.map((round) => <li className="list-group-item">
                {
                creatingGroups && <div className="custom-control custom-switch pull-left mr-2">
                    <input type="checkbox" className="custom-control-input" disabled={saving} id={`groupSwitchRound${round.id}`} onClick={(e) => this.updateRoundOnGrouping(e,round)} />
                    <label className="custom-control-label" for={`groupSwitchRound${round.id}`}></label>
                  </div>
                }
                <strong>{round.player.name}</strong>
                </li> )
              }
            </ul>
          }
          {
            available_rounds.length == 0 && <p className="text-info text-center">All players have been assigned a grouping</p>
          }
          
          <hr/>

          <label><strong>Gambles</strong></label> <br/>

            {
              round_setting.games.length > 0 && <ul className="list-group">
                        {
                          round_setting.games.map((game) => {
                            return <NavLink to={`/round_settings/${round_setting.id}/games/${game.id}`} className="list-group-item" >{game.name}</NavLink>
                          })
                        }
                        </ul>
              }
          {
              !round_setting.games.length && <p className="text-info text-center">No gambles created for this round</p>
          }
          </>}
        </div>
      </div>
    </div>
    <Modal show={showModal} onHide={() => this.setState({showModal:false})}>
    <Modal.Header closeButton>
      <strong>Are you sure?</strong>
    </Modal.Header>
    <Modal.Body className="bg-light">
        <p class="text-center">This will remove any existing groupings</p>
        <button className="btn btn-dark btn-block rounded-pill shadow-sm my-2" disabled={saving || creatingGroups} onClick={confirmationType == "Randomize" ? this.randomizeGroupings : this.standingsGroupings}>{confirmationType}</button>
    </Modal.Body>
  </Modal>
  </div>}</AppContext.Consumer>;

  }
}
