import React, {useEffect, useContext, useState} from "react";
import Loader from '../helpers/Loader'
import { NavLink } from 'react-router-dom'
import ScoreCardScore from './ScoreCardScore'
import ScoreCardScoreButton from './ScoreCardScoreButton'
import { AppContext } from "../core/AppContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LeaderboardRowRoundScore = ({round_ids,onClose}) => {

    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [rounds, setRounds] = useState([])
    const [bestScores, setBestScores] = useState([])

    const loadRoundAsync = async () => {
      const response = await fetch(`/rounds.json?ids=${round_ids}`)
      const json = await response.json();
      
      let best_scores = []

      json.length > 1 && json.forEach((round) => {
        round.scores.map((score) => {
          let existingIndex = best_scores.findIndex((bs) => bs.hole.hole === score.hole.hole )
          if (existingIndex > -1) {
            if(score.net_score < best_scores[existingIndex].net_score) best_scores[existingIndex] = score
          }else{
            best_scores.push(score)
          }
        })
      })

      //setBestScores(best_scores)
      setRounds(json)
      setLoading(false)

    }

    useEffect(() => {
      loadRoundAsync()
    },[])


    let round_name = rounds.length && rounds[0].round_setting ? rounds[0].round_setting.name : 'Loading...';
    let front_nine_scores = rounds.length && rounds[0].scores ? rounds[0].scores.slice(0,9) : [];
    let back_nine_scores = rounds.length && rounds[0].scores ? rounds[0].scores.slice(9,18) : [];
    let hcp_diff = 0;
    let orientation = window.orientation == 0 ? 'portrait' : 'landscape'

    return <tr className="position-absolute shadow-lg bg-white mw-100" style={{overflowX:"auto",zIndex:100}}>
      {!loading && <button className="btn btn-link position-absolute text-white bg-dark rounded-circle" onClick={onClose} style={{top:"5px",left:"5px"}}><FontAwesomeIcon icon={["fas", "times"]} /></button>} 
      <td className="p-4" colSpan="6">
    {loading && <Loader forButton={true} />}
    
    {rounds.map((round) => { 
      
      let round_name = round.round_setting ? round.round_setting.name : [];
      let front_nine_scores = round.scores ? round.scores.slice(0,9) : [];
      let back_nine_scores = round.scores ? round.scores.slice(9,18) : [];
      let player_name = round.player.name
      let [first_name,last_name] = player_name.split(' ')
      let hcp_diff = 0;

      return <>
      <p className="mb-1 mt-3"><strong>{round_name}</strong></p><table className={`table table-bordered table-scorecard text-center my-0 shadow-sm`} >
      <thead className="thead-light">
        <tr>
          <th scope="col">
            <strong>Hole</strong>
          </th>
          {front_nine_scores.map((score) => <th scope={`col`}>{score.hole.hole}</th>)}
          <th scope="col text-center"><strong>OUT</strong></th>
          {back_nine_scores.map((score) => <th scope="col">{score.hole.hole}</th>)}
          <th scope="col text-center"><strong>IN</strong></th>
          <th scope="col text-center"><strong>TOT</strong></th>
          <th scope="col text-center"><strong>HCP</strong></th>
          <th scope="col text-center"><strong>NET</strong></th>
        </tr>
      </thead>
      <tbody> <tr>
        <td className={`bg-white`}>
          <strong className="ml-1">{first_name && `${first_name[0]}.`}{last_name && `${last_name[0]}.`}</strong>
        </td>
        {
          front_nine_scores.map((score) => {

            let backgroundImage = false;
            let adjusted_hcp = round.handicap - hcp_diff;

              if (score.tee_box.handicap <= adjusted_hcp){
                if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = `url(/img/two_strokes_bottom.png)`;
                else backgroundImage = `url(/img/one_stroke_bottom.png)`;
              }

            return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`}   style={{backgroundImage:backgroundImage}}>
              {
              context && context.current_user && round.player.id === context.current_user?.id &&
              <>
                {orientation == 'landscape' ? <ScoreCardScoreButton score={score} round={round} showHandicap={true} player={''} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} expands={true} /> : <NavLink to={`/rounds/${round.id}/scores/${score.id}`} >
                <ScoreCardScore score={score} />
              </NavLink>}
              </>
              }
              {(!context || !context.current_user || round.player.id !== context.current_user?.id) && <ScoreCardScore score={score} editable={false} />}
            </td>
          })
        }
        <td className="text-center">
          <strong>
            {front_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>
        {
          back_nine_scores.map((score) => {

            let backgroundImage = false;
            let adjusted_hcp = round.handicap - hcp_diff;

              if (score.tee_box.handicap <= adjusted_hcp){
                if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = `url(/img/two_strokes_bottom.png)`;
                else backgroundImage = `url(/img/one_stroke_bottom.png)`;
              }

            return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`} style={{backgroundImage:backgroundImage}}>
              {
              context && context.current_user && round.player.id === context.current_user?.id &&
              <>
                {orientation == 'landscape' ? <ScoreCardScoreButton score={score} round={round} showHandicap={true} player={''} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} expands={true} /> : <NavLink to={`/rounds/${round.id}/scores/${score.id}`} >
                <ScoreCardScore score={score} />
              </NavLink>}
              </>
              }
              {(!context || !context.current_user || round.player.id !== context.current_user?.id) && <ScoreCardScore score={score} editable={false} />}
            </td>
          })
        }
        <td className="text-center">
          <strong>
            {back_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>

        <td className="text-center">
          <strong>
            {round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>

        <td className="text-center"><strong>{round.handicap}</strong></td>
        <td className="text-center">
          <strong>
            {round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0) - round.handicap}
          </strong>
        </td>
      </tr>
    </tbody>
      </table></>})}
      {bestScores.length > 0 && <tr>
        <td className={`bg-info`}>
          <strong className="ml-1">Net</strong>
        </td>
        {
          bestScores.slice(0,9).map((score) => {

            return <td >
              <ScoreCardScore score={score} use_net={true} editable={false} />
            </td>
          })
        }
        <td className="text-center">
          <strong>
            {bestScores.slice(0,9).map((s) => s.net_score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>
        {
          bestScores.slice(9,18).map((score) => {

            return <td>
              <ScoreCardScore score={score} use_net={true} editable={false} />
            </td>
          })
        }
        <td className="text-center">
          <strong>
            {bestScores.slice(9,18).map((s) => s.net_score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>

        <td className="text-center">
          <strong>
            {bestScores.map((s) => s.net_score ).reduce(function(a, b){ return a + b }, 0)}
          </strong>
        </td>

        <td className="text-center"><strong>
          {/* {round.handicap} */}
        </strong></td>
        <td className="text-center">
          <strong>
            {/* {round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0) - round.handicap} */}
          </strong>
        </td>
      </tr>}
      </td>
    </tr>;

}

export default LeaderboardRowRoundScore;
