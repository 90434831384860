import React, {useState, useEffect, useRef} from "react"
import {AppContext} from '../../core/AppContext'
import { NavLink, BrowserRouter, Route, Switch, useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Footer(){

  const [elementOffset, setElementOffset] = useState(window.orientation == 0 ? 0 : -72)
  const [orientation,setOrientation] = useState(window.orientation == 0 ? 'portrait' : 'landscape')
  const prev = useRef()

  const {tournament_id} = useParams()

  useEffect(() => {
    
    const handleScroll = (e) => {

      if (orientation == 'landscape') return;
  
      const window = e.currentTarget;

      let change = Math.abs(prev.current - window.scrollY)
      
      let newOffset = elementOffset;

      if (change > 10 && window.scrollY > 0){
  
        if (prev.current < window.scrollY) {
            
          if (newOffset !== -72){
            newOffset = -72;
          }
        } else if (prev.current > window.scrollY) {
            
          if (newOffset != 0){
            newOffset = 0;
          }
        }
  
        prev.current = window.scrollY;
  
        setElementOffset(newOffset)
      }
        
    }
    
    const handleOrientation = (e) => {
      let newOrientation = window.orientation == 0 ? 'portrait' : 'landscape';
      setOrientation(newOrientation)
    }

    prev.current = window.scrollY;
    window.addEventListener('scroll', handleScroll)
    window.addEventListener("orientationchange",handleOrientation, false);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('orientationchange', handleOrientation);
    }
  },[])

    return <AppContext.Consumer>
            {
              (context) => 
                    <ul className="nav nav-bottom nav-fill nav-justified fixed-bottom bg-primary shadow" style={{bottom:`${elementOffset}px`}}>
                        { elementOffset < 0 && <div className="footer-toggler w-100 text-center text-muted" style={{marginTop:"-65px"}} onClick={() => setElementOffset(0)}>
                            <FontAwesomeIcon icon={["fas","caret-up"]} size="4x" />
                          </div>
                        }
                        <NavLink to="/users/edit" className="nav-item px-0 d-none" activeClassName="bg-secondary" >
                          <span href="/users/edit" className="nav-link px-0 text-white">
                            <h5 className="mb-0 pt-2 px-2"><i className="fas fa-user"></i></h5>
                            <p className="mb-0"><small>Profile</small></p>
                          </span>
                        </NavLink>
                        <NavLink to={`/tournaments/${tournament_id}/rounds`} className="nav-item px-0" activeClassName="bg-secondary" >
                          <span href="/rounds" className="nav-link px-0 text-white">
                          <h5 className="mb-0 pt-2 px-2"><i className="fas fa-golf-ball"></i></h5>
                            <p className="mb-0"><small>Rounds</small></p>
                          </span>
                        </NavLink>
                        {
                          context.current_user.active_tournament && <NavLink to={`/tournaments/${tournament_id}/leaderboard`} className="nav-item px-0" activeClassName="bg-secondary" >
                            <span href="/leaderboard" className="nav-link px-0 text-white">
                              <h5 className="mb-0 pt-2 px-2"><i className="fas fa-list-ol"></i></h5>
                              <p className="mb-0"><small>LeaderBoard</small></p>
                            </span>
                          </NavLink>
                        }
                        {
                          context.current_user.role.name == "admin" &&
                          [<NavLink to="/tournaments" key="tournaments_nav_link" exact className="nav-item px-0" activeClassName="bg-secondary" >
                            <span href="/tournaments" className="nav-link px-0 text-white">
                              <h5 className="mb-0 pt-2 px-2"><i className="fas fa-trophy"></i></h5>
                              <p className="mb-0"><small>Tourneys</small></p>
                            </span>
                          </NavLink>,<NavLink to="/users" key="users_nav_link" exact className="nav-item px-0" activeClassName="bg-secondary" >
                            <span href="/users" className="nav-link px-0 text-white">
                              <h5 className="mb-0 pt-2 px-2"><i className="fas fa-users"></i></h5>
                              <p className="mb-0"><small>Users</small></p>
                            </span>
                          </NavLink>]
                        }
                        <NavLink to={`/tournaments/${tournament_id}/rules?s=golf`} className="nav-item px-0" activeClassName="bg-secondary" >
                          <span href="/rules" className="nav-link px-0 text-white">
                            <h5 className="mb-0 pt-2 px-2"><i className="fas fa-info-circle"></i></h5>
                            <p className="mb-0"><small>Info</small></p>
                          </span>
                        </NavLink>
                  </ul>
            }
    </AppContext.Consumer>;

}
