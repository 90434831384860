import React from "react"
import {AppContext} from '../../core/AppContext'
import {getHandicapsLocked,getCsrf} from "../../helpers/CsrfTags"
import YardageBook from "../YardageBook"
import Leaderboard from "../Leaderboard"
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
export default class Header extends React.Component {

  state = {
    handicaps_locked:getHandicapsLocked(),
    show_yardage_book:false,
    show_leaderboard:false
  }

  toggleHandicapsLocked = (e) => {
    
    let {handicaps_locked} = this.state;
    this.setState({saving:true})
    fetch(`/admin/settings`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({setting:{handicaps_locked:!handicaps_locked ? "1" : "0"}})
    }).then(response =>  response.text()).then(data => {
      this.setState({handicaps_locked:!handicaps_locked})
    });
    
    e.preventDefault();
    e.stopPropagation();
    return false;

  }

  render() {

    let {handicaps_locked,show_yardage_book,show_leaderboard} = this.state;

    return <AppContext.Consumer>
    {
      (context) => 
      <>
        <nav className="navbar bg-white shadow-sm px-2" style={{zIndex:1}}>
            {
            context.current_user.role.name == "admin" && <form className="form-inline position-absolute ml-5 d-none">
                <button className={`btn btn-sm d-none ${!handicaps_locked ? 'bg-secondary text-white' : 'bg-white border border-secondary  text-secondary'}`} type="button" onClick={this.toggleHandicapsLocked}>{handicaps_locked ? 'Un-Lock':'Lock'}</button>
                <div class="custom-control custom-switch">
                  <input type="checkbox" key="handicaps_locked_switch" className="custom-control-input" checked={handicaps_locked} onChange={this.toggleHandicapsLocked} id="handicapsLocked" />
                  <label className="custom-control-label" htmlFor="handicapsLocked"></label>
                </div>
              </form>
            }
            <form className="form-inline position-absolute d-none">
              <button className={`btn btn-sm btn-dark shadow`} type="button" onClick={() => this.setState({show_yardage_book:true})}><i className="fas fa-book"></i></button>
            </form>
            <form className="form-inline position-absolute">
              <button className={`btn btn-lg text-dark px-2`} type="button" onClick={context.openSidebar}><FontAwesomeIcon icon={["fas","bars"]} size="lg" /></button>
            </form>
            <a className="navbar-brand mx-auto" href="#"><h5 className="mb-0"><span className="text-secondary">We</span>Golf</h5><img src="/img/logo-text-only.png" className="img-fluid d-none" style={{"maxHeight":"27px"}} /></a>
            {
              context.current_user.active_tournament && 
              <form className="form-inline position-absolute top-right-nav d-none">
                <button className={`btn btn-sm btn-dark shadow`} type="button" onClick={() => this.setState({show_leaderboard:!show_leaderboard})}><i className="fas fa-list-ol"></i></button>
              </form>
            }
          </nav>
          <Modal scrollable size="xl" show={show_yardage_book} onHide={() => this.setState({show_yardage_book:false})}>
        <Modal.Header className="bg-dark text-white border-0">
          <i className="fas fa-book mt-1 mr-2"></i> Yardage Book <button className="btn bg-primary btn-sm text-white rounded-pill shadow-sm pull-right" onClick={() => this.setState({show_yardage_book:false})} >Close</button>
        </Modal.Header>
        <Modal.Body className="p-0">
          {show_yardage_book && <YardageBook url={'/users/me/yardage_book'} />}
        </Modal.Body>
       <Modal.Footer className="bg-dark text-white">
        <button className="btn btn-sm bg-primary text-white rounded-pill shadow-sm" onClick={() => this.setState({show_yardage_book:false})} >Close</button>
      </Modal.Footer>
      </Modal>
          {
            context.current_user.active_tournament && <Modal scrollable size="xl" show={show_leaderboard} onHide={() => this.setState({show_leaderboard:false})}>
                  <Modal.Header className="bg-dark text-white border-0">
                    <i className="fas fa-list-ol mt-1 mr-2"></i> Leaderboard <button className="btn btn-sm pull-right bg-primary text-white rounded-pill shadow-sm" onClick={() => this.setState({show_leaderboard:!show_leaderboard})} >Close</button>
                  </Modal.Header>
                  <Modal.Body className="p-0">
                    <Leaderboard leaderboard_url={`/tournaments/${context.current_user.active_tournament.id}/leaderboard`} />
                  </Modal.Body>
                  <Modal.Footer className="bg-dark">
                    <button className="btn bg-primary text-white rounded-pill shadow-sm btn-sm" onClick={() => this.setState({show_leaderboard:!show_leaderboard})} >Close</button>
                  </Modal.Footer>
                </Modal>
          }
     </>}</AppContext.Consumer>;
  }
}
