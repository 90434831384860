import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom/cjs/react-router-dom';
import { createRange, getCsrf } from '../helpers/CsrfTags';
import Loader from '../helpers/Loader';
import { AppContext } from '../core/AppContext';
import moment from 'moment';

const ordinalizeNumber = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;
  const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
  return `${number}${suffix}`;
};


export default function Highlights() {
  const isCreating = location.pathname.includes('new');
  if (isCreating) return <HighlightNew />

  const [loading, setLoading] = useState(true);
  const [highlights, setHighlights] = useState([]);
  const [filter, setFilter] = useState({
    player_id: null,
    hole_id: null
  })
  
  useEffect(() => {
    const loadHighlights = () => {
      fetch(`${location.pathname}.json`).then(response => response.json()).then(data => {
        setHighlights(data);
        setLoading(false);
      });
    }

    loadHighlights();
  },[])

  const highlightUniquePlayers = highlights.map(({player}) => player).filter((player, index, self) => self.findIndex(({id}) => id === player.id) === index).sort((a,b) => a.name.localeCompare(b.name));
  const highlightUniqueHoles = highlights.map(({hole}) => hole).filter((hole, index, self) => self.findIndex(({id}) => id === hole.id) === index).sort((a,b) => a.hole - b.hole);
  const filteredHighlights = highlights.filter(({player:{id:player_id}, hole:{id:hole_id}}) => {
    if (filter.player_id && filter.player_id != player_id) return false;
    if (filter.hole_id && filter.hole_id != hole_id) return false;
    return true;
  })

  return <div className="col-md-6 col-xl-4 px-0 align-self-start">
  <div className="card shadow-sm flexbox-container">
    <div className="card-header text-center">
       Highlights
      <NavLink to={`${location.pathname}/new`} className="btn btn-sm btn-secondary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i></NavLink>
    </div>
    <div className="card-body">
      {loading ? <Loader containerClass={"d-flex justify-content-center py-0"} /> : <>
      <div className="d-flex sticky-top mb-2">
      <select class="custom-select" onChange={({target:{value}}) => {
        setFilter({
          ...filter,
          player_id: value,
        })
      }}>
        <option>All Players</option>
        {highlightUniquePlayers.map((player) => <option key={`highlight_player_${player.id}`} value={player.id}>{player.name}</option>)}
      </select>      
      
      <select class="custom-select" onChange={({target:{value}}) => {
        setFilter({
          ...filter,
          hole_id: value,
        })
      }}>
        <option>All Holes</option>
        {highlightUniqueHoles.map((hole) => <option key={`highlight_hole_${hole.id}`} value={hole.id}>{hole.hole}</option>)}
      </select>
      </div>
      {highlights.length == 0 && <div className="alert alert-info text-center mt-2 py-4" role="alert">
        <h5 className="mb-4">No Highlights Added Yet</h5>
        <NavLink exact className="btn btn-lg btn-info shadow-sm border text-white rounded-pill btn-block" to={`${location.pathname}/new`} >
          Add Highlight
        </NavLink>
        </div>
      }
      {filteredHighlights.map((highlight) => {
        return <div className="form-group rounded-lg bg-primary text-light mb-2 shadow-md">
          <div className="d-flex bg-dark text-light justify-content-between align-items-center px-2 py-2">
          <small className="d-flex align-items-center">
            <img className="rounded-circle mr-1" src={highlight.creator.profile_picture_url} width="20" height="20" />
            {highlight.creator.name}
          </small>
          <small>
            added at <i>{moment(highlight.created_at).fromNow()}</i>
          </small>
      </div>
      
          <video className="w-100 h-50 bg-dark" autoplay preload="auto" controls poster={highlight.preview_url}>
          <source src={highlight.video_url}/>
        </video>
      <div className="d-flex justify-content-between align-items-center px-2 pt-1 pb-2">
          <small>
            <i className="fas fa-user"></i> {highlight.player.name}
          </small>
          <small className="d-flex align-items-center"> 
            <div className="mr-2"><i className="fas fa-flag"></i>  {highlight.hole.hole} </div>
            <div className="rounded-pill border bg-secondary px-2 py-1">{ordinalizeNumber(highlight.shot)} shot</div>
          </small>
        </div>
      </div>
      })}
      </>}
    </div>
  </div>
  </div>
}

const HighlightNew = () => {

  const {
    hole_id, 
    grouping_id,
    round_id,
    round_setting_id,
  } = useParams();

  const {current_user} = useContext(AppContext)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [error, setError] = useState(false)
  const [roundSetting, setRoundSetting] = useState();
  const [scores, setScores] = useState([]);
  const [holes, setHoles] = useState([]);
  const videoRef = useRef(null);
  const [highlight, setHighlight] = useState({
    hole_id,
    shot: 1,
  });
  const shotRange = createRange(1, 10)
  const [selectedFile, setSelectedFile] = useState(null);
  
  useEffect(() => {
    const loadRoundSetting = () => {
      const url = () => {
        if (round_setting_id) return `/round_settings/${round_setting_id}.json`;
        if (grouping_id) return `/groupings/${grouping_id}.json`;
        if (round_id) return `/rounds/${round_id}.json`;
      }
      fetch(url()).then(response => response.json()).then(data => {
        setRoundSetting(data);
      });
    }

    loadRoundSetting();
  },[])  
  
  useEffect(() => {
    if (round_id || round_setting_id) return;

    const loadScores = () => {
      fetch(`${location.pathname.replace('highlights/new','scores')}.json`).then(response => response.json()).then(data => {
        setScores(data);
      });
    }

    loadScores();
  },[])  

  useEffect(() => {
    const loadHoles = () => {
      const url = () => {
        if (round_setting_id) return `/round_settings/${round_setting_id}/holes.json`;
        if (grouping_id) return `/groupings/${grouping_id}/holes.json`;
        if (round_id) return `/rounds/${round_id}/holes.json`;
      }
      fetch(url()).then(response => response.json()).then(data => {
        setHoles(data);
      });
    }

    loadHoles();
  },[])

  const fileSelectedHandler = event => {
    setSelectedFile(event.target.files[0]);
  }

  const fileUploadHandler = () => {
    setSaving(true)
    const formData = new FormData();
    formData.append('highlight[video]', selectedFile, selectedFile.name);
    formData.append('highlight[hole_id]', highlight.hole_id);
    formData.append('highlight[shot]', highlight.shot);
    formData.append('highlight[player_id]', highlight.player_id);
    formData.append('highlight[round_id]', highlight.round_id);
    fetch(`/highlights.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrf(),
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      setSaved(true);
    })
    .catch((error) => {
      setError(error)
    });
  }

  useEffect(() => {
    if (highlight.player_id || !scores.length) return;
    const firstNonCurrentUserScore = scores.find(({round:{player:{id: player_id}}}) => player_id !== current_user.id );
    setHighlight({
      ...highlight,
      round_id: firstNonCurrentUserScore.round.id,
      player_id: firstNonCurrentUserScore.round.player.id,
    });
  },[current_user, scores.map(({id}) => id).join(',')])


  const nonGroupingRounds = roundSetting?.rounds?.filter?.(({grouping: {id}}) => id != grouping_id);

  if (saved) return <div className="col-md-6 col-xl-4 px-0 align-self-start">
  <div className="card shadow-sm flexbox-container">
    <div className="card-header text-center">
     Noice!
    </div>
    <div className="card-body pt-2">    
      <div className="alert alert-success text-center mt-2" role="alert">
          <h4><i className="fas fa-check"></i> <br/>Highlight Saved!</h4>
      </div>
      <button exact className="btn btn-lg btn-secondary shadow-sm border rounded-pill btn-block" onClick={() => {
        setSelectedFile(null);
        setHighlight({
          hole_id,
          shot: 1,
        });
        setSaving(false);
        setSaved(false);
      }} >
            Add Another Highlight
      </button>
      {round_id && <NavLink exact className="btn btn-lg btn-primary shadow-sm border rounded-pill btn-block" to={`/rounds/${round_id}`} >
            Back to Round
      </NavLink>}
      
      {round_setting_id && <button exact className="btn btn-lg btn-primary shadow-sm border rounded-pill btn-block" onClick={() => window.history.back()} >
            Back to Round
      </button>}
      {hole_id && <NavLink exact className="btn btn-lg btn-primary shadow-sm border rounded-pill btn-block" to={`/groupings/${grouping_id}/holes/${hole_id}/scores`} >
            Back to Hole
      </NavLink>}
    </div>
  </div>
</div>

  return <div className="col-md-6 col-xl-4 px-0 align-self-start">
  <div className="card shadow-sm flexbox-container">
    <div className="card-header text-center">
      Add Highlight
    </div>
    <div className="card-body">  
    {!holes.length ? <Loader containerClass={"d-flex justify-content-center py-0"} /> : <> 
      {selectedFile && (
        <video className="w-100" controls>
          <source src={URL.createObjectURL(selectedFile)}/>
        </video>
      )}
      <div className="form-group">
        <div className="mt-3 d-flex bg-light flex-column border rounded-lg py-3" id="form-highlight-video" onClick={() => videoRef.current?.click?.() }>
          <i className="text-dark fas fa-video fa-2x mx-auto mb-1" />
          <p className="text-dark text-center mb-0"><strong>Tap to {selectedFile ? 'change' : 'upload'} video</strong></p>
          <input className="d-none" accept="video/*" type="file" ref={videoRef} onChange={fileSelectedHandler} />
        </div>
      </div>
      <div className={`form-group`}>
        <label className={`font-weight-bolder`} >Player</label> <span id="score_badge" className="badge"></span> <br/>
        <div className="d-flex overflow-auto pb-2">  
        
        {scores?.map?.((score) => <button type="button" key={`new_highlight_player_${score.id}`} id={`player_${score.round.player.id}`} className={`btn btn-lg mr-2 text-nowrap ${score.round.player.id == highlight.player_id ? 'btn-dark' : 'btn-outline-dark'} rounded-pill`} onClick={() => setHighlight({
          ...highlight,
          player_id: score.round.player.id,
          round_id: score.round.id,
        })} >{score.round.player.name}</button>)}
        { nonGroupingRounds?.map?.((round) => <button type="button" key={`new_highlight_player_${round.id}`} id={`player_${round.player.id}`} className={`btn btn-lg mr-2 text-nowrap ${round.player.id == highlight.player_id ? 'btn-dark' : 'btn-outline-dark'} rounded-pill`} onClick={() => setHighlight({
          ...highlight,
          player_id: round.player.id,
          round_id: round.id,
        })} >{round.player.name}</button>)}
        </div>
      </div>
      <div className={`form-group`}>
        <label className={`font-weight-bolder`} >Hole</label> <span id="score_badge" className="badge"></span> <br/>
        <HoleScroller chosenHole={highlight.hole_id} holes={holes} onSelect={(hole_id) => setHighlight({
          ...highlight,
          hole_id,
        })} />
      </div>
      <div className={`form-group`}>
        <label className={`font-weight-bolder`} >Shot</label> <span id="score_badge" className="badge"></span> <br/>
        <ShotScroller chosenShot={highlight.shot} shotRange={shotRange} onSelect={(shot) => setHighlight({
          ...highlight,
          shot,
        })} />
      </div>
      <div className={`form-group mt-5 border-top`}>
        <button className="btn btn-primary btn-lg btn-block rounded-pill" disabled={!selectedFile || !highlight.round_id} onClick={fileUploadHandler}>
          Save Highlight
          {saving && <Loader forButton />}
          </button>
      </div>  </> }
    </div>
</div>
  </div>
}

const HoleScroller = ({chosenHole, holes, onSelect}) => {
  const scrollerRef = useRef(null);

  useEffect(() => {
    let holeElement = document.getElementById(`hole_${chosenHole}`);
    if (chosenHole && scrollerRef.current) scrollerRef.current.scrollLeft = holeElement.offsetLeft - (window.innerWidth/2) + (holeElement.offsetWidth/2) 
  },[])

  return <div className="d-flex overflow-auto pb-2" ref={scrollerRef}>  
  {holes.map((hole) => <button type="button" key={`new_highlight_hole_${hole.id}`} id={`hole_${hole.id}`} className={`btn btn-lg mr-2 ${hole.id == chosenHole ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => onSelect(hole.id)} >{hole.hole}</button>)}
  </div>
}

const ShotScroller = ({chosenShot, shotRange, onSelect}) => {
  const scrollerRef = useRef(null);

  useEffect(() => {
    let shotElement = document.getElementById(`shot_${chosenShot}`);
    if (chosenShot && scrollerRef.current) scrollerRef.current.scrollLeft = shotElement.offsetLeft - (window.innerWidth/2) + (shotElement.offsetWidth/2) 
  },[])

  return <div className="d-flex overflow-auto pb-2" ref={scrollerRef}>  
  {shotRange.map((shot) => <button type="button" key={`new_highlight_shot_${shot}`} id={`shot_${shot}`} className={`btn btn-lg mr-2 ${shot == chosenShot ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => onSelect(shot)} >{shot}</button>)}
  </div>
}