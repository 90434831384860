function fallbackCopyTextToClipboard(text,callback) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position="fixed";  //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    callback(text)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    callback(null,err)
  }

  document.body.removeChild(textArea);
};

export default function copyToClipboard(text,callback) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text,callback);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
    callback(text)
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
    callback(null,err)
  });
};

function copiedToClipboard(callback) {
  navigator.clipboard.readText()
  .then(text => {
    callback({text:text})
  })
  .catch(err => {
    callback({error:err})
  });
};
