import React, { useContext } from "react"
import { NavLink } from 'react-router-dom'
import { AppContext } from "../core/AppContext"
import ScoreGroupHole from "./ScoreGroupHole"

const ScoresGroupHole = ({scores}) =>{

    const {current_user} = useContext(AppContext);

    let score = scores[0];
    scores = scores.sort((a, b) => {
      if(a.round.player.id === current_user.id)
        return -1
      else 
        return 1
    })

    return <form className="pb-5">
            {scores.map((score) => <ScoreGroupHole score={score} />)}

            <div className="form-group pt-3 pb-5 px-4 d-none">
              {
                  score.hole.hole < 18 ?
                  <NavLink exact className="btn btn-lg btn-primary shadow-sm rounded-pill btn-block" to={score.next_group_score_path} >
                    Next Hole
                  </NavLink> : <NavLink exact className="btn btn-lg btn-secondary shadow-sm rounded-pill btn-block" to={`/rounds/${score.round.id}`} >
                    Review Round
                  </NavLink>
              }

              { 
                score.hole.hole != 18 && <NavLink exact className="btn btn-lg btn-secondary shadow-sm rounded-pill btn-block" to={`/rounds/${score.round.id}`} >
                  View Round
                </NavLink>
              }

            </div>
          </form>;

}

export default ScoresGroupHole;
