import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import ScoreCardScore from './ScoreCardScore'
import {getCsrf,createRange} from "../helpers/CsrfTags"
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ScoreCardScoreButton = ({score : passedScore,round, showHandicap, title, player, expands, showGrouping}) => {
  const [showModal,setShowModal] = useState(false);
  const [saving,setSaving] = useState(false);
  const [error,setError] = useState(false);
  const [score,setScore] = useState(passedScore);

  const updateScore = (field,value) => {
    score[field] = value;
    setSaving(true)
    fetch(`/rounds/${round.id}/scores/${score.id}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({score:score})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      setSaving(false)
      setScore(data)
      setShowModal(false)
    })
    .catch((response) => {
      setSaving(false)
      response.json().then(error => {
        let field = Object.keys(error)[0];
        setError(`${field} ${error[field]}`)
      })
    });
  }

  let scoreRange = createRange(score.tee_box.par - 2,score.tee_box.par + 9);

  return <>
    <a href="#" onClick={() => setShowModal(true)}><ScoreCardScore score={score} round={round} showHandicap={showHandicap} /></a>
    <Modal centered size="sm" show={showModal} animation={false} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        {
          expands && 
            <NavLink to={showGrouping ?  `/groupings/${score.round.grouping.id}/holes/${score.hole.id}/scores` : `/rounds/${round.id}/scores/${score.id}`} className="pull-right mr-4" >
              <FontAwesomeIcon icon={["fas", "expand-alt"]} />
            </NavLink>
        }
        <h5 className="mb-0">
          <strong>{player}</strong> - {title}
        </h5>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div className="form-group">
          <label>Score</label> <span id="score_badge" className="badge"></span> <br/>
          <div className="d-flex overflow-auto py-2">
            {scoreRange.map((n) => <button type="button" key={`score_button_${score.id}_score_${n}`} className={`btn btn-lg mr-2 ${n == score.score ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => updateScore('score',n)} >{n}</button>)}
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>

}
export default ScoreCardScoreButton