import React from "react";
import { NavLink } from 'react-router-dom'

export default class Notifications extends React.Component {

  state = {
    loading:true,
    hide:false,
    todays_rounds:[],
    error:false
  };
  
  componentDidMount(){
    let {context} = this.props;
    if (context.current_user) this.loadRounds();
  }

  loadRounds(){
    fetch(`/rounds.json`).then(response => response.json()).then(data => this.setState({todays_rounds:data,loading:false}));
  }

  render() {
    
    let {todays_rounds,loading,hide} = this.state;
    let {context} = this.props;

    if (hide || !context.current_user || loading || todays_rounds.length == 0) return <span/>;

    let round = todays_rounds[0];
    let todays_round_url = `/rounds/${round.id}`;

    if (location.pathname.indexOf(todays_round_url) > -1 || round.submitted) return <span/>;

    let started = round.final_score > 0;
    let first_empty_score_link = round.first_empty_score_link;
    
    return <div className={`alert alert-${started ? 'warning' : 'info'} mb-0 text-center rounded-0`} role="alert">
      You have a round to complete today <br/> <NavLink to={first_empty_score_link || `/rounds/${round.id}`} onClick={() => this.setState({hide:true})} className={`btn btn-${started ? 'warning' : 'info'} btn-sm mt-2`} >{started ? "Continue" : "Start"} Round</NavLink>
    </div>;

  }
}
