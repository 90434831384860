import React, { useRef, useEffect, useLayoutEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import Loader from '../helpers/Loader'
import ScoreHole from './ScoreHole'
import NotesButton from './NotesButton'
import Round from './Round'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScoresGroupHole from "./ScoresGroupHole"
import MapBox from "./MapBox"
import LeaderboardButton from "./LeaderboardButton"

export default class Score extends React.Component {

  state = {
    score:false,
    scores:false,
    showMap:this.props.context.current_user.app_state.showMap,
    loading:true,
    changingScore:0,
    current_user:this.props.context.current_user,
    url:location.pathname,
    isGrouping:location.pathname.indexOf('grouping') > -1,
    elementOffset:-10,
    orientation:'portrait'
  }

  componentDidMount(){
    this.prev = window.scrollY;
    window.addEventListener('scroll', this.handleScroll);
    this.loadScore();
    window.addEventListener("orientationchange",this.handleOrientation, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('orientationchange', this.handleOrientation);
  }

  handleOrientation = (e) => {
    this.setState({orientation:window.orientation == 0 ? 'portrait' : 'landscape'})
  }

  handleScroll = (e) =>{

    if (this.props.context.noScrollListeners()) return;

    let {elementOffset} = this.state;

    const window = e.currentTarget;
    let change = Math.abs(this.prev - window.scrollY)
    
    if (change > 30){
      if (this.prev < window.scrollY && window.scrollY > 0) {
          
        if (elementOffset != -50){
          elementOffset = -50;
        }
      } else if (this.prev > window.scrollY) {
          
        if (elementOffset != -10){
          elementOffset = -10;
        }
      }

      this.prev = window.scrollY;
    }

    this.setState({elementOffset:elementOffset})
      
  }
  
  loadScore(){
    
    let {isGrouping,score,scores,url} = this.state;

    fetch(`${url}.json`).then(response => response.json()).then(data => {
      
      if (isGrouping) scores = data;
      else score = data
      window.history.replaceState(null, "New Page Title", url)
      window.scrollTo(0,0)
      this.setState({score:score,scores:scores,loading:false,changingScore:0})

    });

  }

  render() {
    
    let {changingScore,score,scores,loading,elementOffset,orientation,isGrouping,current_user,showMap, url} = this.state;
    let {context} = this.props;
    if (scores) score = scores.find((score) => score.round.player.id === current_user.id )

    if (loading) return <Loader containerClass="d-flex justify-content-center" />;
    if (orientation == 'landscape') return <Round url={`/rounds/${score.round.id}`} currentScore={score} />
    
    return <div className="col-md-6 col-xl-4 px-0 align-self-start">
              <div className="card shadow-sm flexbox-container">
              <LeaderboardButton key={`leaderboard_for_round_${score.round.id}`} round_id={score.round.id} />
              <div className="card-header text-center py-2"> 
                <p className="text-center d-flex mb-0">
                  <NotesButton key={`notes_${score.hole.id}`} notable_id={score.hole.id} notable_type={'Hole'} title={`Hole ${score.hole.hole}`} />   
                  {score.tee_box.handicap <= score.round.handicap && <span className="align-top text-info mt-3 position-absolute"><small>*</small></span>}
                  {(score.round.handicap > 18 && score.tee_box.handicap <= (score.round.handicap - 18)) && <span className="align-top text-info ml-2 mt-3 position-absolute"><small>*</small></span>}
                  <span className="flex-fill">
                    <span>Hole </span><span className="font-weight-bolder">{score.hole &&  <NavLink to={`/rounds/${score.round.id}`} >{score.hole.hole}</NavLink> }</span>
                  </span>
                  <span className="flex-fill border-left">
                    <span className="font-weight-bolder">{score.tee_box.yards}</span> <small>yds</small>
                  </span>
                  <span className="flex-fill border-left">
                    Par <span className="font-weight-bolder">{score.tee_box.par}</span>                 
                  </span>
                </p>
              </div>
                <div className="card-body pt-2">
                {!showMap && <>
                {
                  <div className="d-flex align-items-center flex-row justify-content-between mb-1">
                  <NavLink exact className="btn btn-sm btn-light border rounded-pill" to={`/groupings/${score.round.grouping.id}/holes/${score.hole.id}/highlights/new`} >
                    <i className="mx-1 text-primary fas fa-plus"></i> Add Highlight 
                  </NavLink>
                  {(score.round.grouping && score.round.grouping) && <ul className="nav justify-content-end">
                    <li className="nav-item">
                      <NavLink className={`nav-link ${!isGrouping && 'text-secondary border-bottom border-secondary'}`} aria-current="page" to={`/rounds/${score.round.id}/scores/${score.id}`}><i className="fas fa-user"></i></NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={`nav-link ${isGrouping && 'text-secondary border-bottom border-secondary'}`} to={`/groupings/${score.round.grouping.id}/holes/${score.hole.id}/scores`}><i className="fas fa-users"></i></NavLink>
                    </li>
                  </ul>}
                  </div>
                }
                  {isGrouping ? <ScoresGroupHole key={`score_${score.id}_scores`} scores={scores} /> : <ScoreHole key={`score_${score.id}`} score={score} />}

                  <div className="form-group pt-3 pb-5 px-4">
                    {
                        score.hole.hole < 18 ?
                        <a className="btn btn-lg btn-primary shadow-sm rounded-pill btn-block" onClick={(e) => {
                          this.setState({url:isGrouping ? score.next_group_score_path : score.next_score_path},this.loadScore)
                          e.preventDefault();
                          return false;
                        }}>
                          Next Hole
                        </a> : <NavLink exact className="btn btn-lg btn-secondary shadow-sm rounded-pill btn-block" to={`/rounds/${score.round.id}`} >
                          Review Round
                        </NavLink>
                    }

                    { 
                      score.hole.hole != 18 && <NavLink exact className="btn btn-lg btn-secondary shadow-sm rounded-pill btn-block" to={`/rounds/${score.round.id}`} >
                        View Round
                      </NavLink>
                    }

                    <NavLink exact className="btn btn-lg btn-light shadow-sm border rounded-pill btn-block" to={`/groupings/${score.round.grouping.id}/holes/${score.hole.id}/highlights`} >
                      View Highlights <i className="ml-1 fas fa-play"></i> 
                    </NavLink>

                  </div>

                </>}

                {
                showMap && <MapBox score={score} bounds={[[score.hole.green_lng,score.hole.green_lat],
                  [score.tee_box.lng,score.tee_box.lat]]}
                  greenLngLat={[score.hole.green_lng,score.hole.green_lat,]}
                  teeBoxLngLat={[score.tee_box.lng,score.tee_box.lat]}
                  centerLngLat={score.hole.drive_lng ? [score.hole.drive_lng,score.hole.drive_lat] : []}
                />
                }

                {
                  score.hole.hole > 1 &&
                  <a exact href="#" className="btn btn-lg btn-primary position-fixed rounded-pill shadow pr-2 py-5 side-bar" style={{"left": `${showMap ? -10 : elementOffset}px`,"top": "calc(50% - 79px)","height":"158px","width":"50px",zIndex:10001}} to={isGrouping ? score.previous_group_score_path : score.previous_score_path} onClick={(e) => {
                    this.setState({url:isGrouping ? score.previous_group_score_path : score.previous_score_path,changingScore:-1},this.loadScore)
                    e.preventDefault();
                    return false;
                  }} disabled={score.hole.hole == 1} >
                    <FontAwesomeIcon icon={[ "fas" , "chevron-left"]} className="animated pulse" /> <br/>
                    
                    {changingScore !== -1 ? `${score.hole.hole - 1}` : <Loader containerClass="justify-content-center" forButton={true}/>}
                  </a>
                }

                  {
                    score.hole.hole < 18 ?
                    <a exact href="#" className="btn btn-lg btn-primary position-fixed rounded-pill shadow pl-2 py-5 side-bar"  style={{"right": `${showMap ? -10 : elementOffset}px`,"top": "calc(50% - 79px)","height":"158px","width":"50px",zIndex:10001}} to={isGrouping ? score.next_group_score_path : score.next_score_path}  onClick={(e) => {
                      this.setState({url:isGrouping ? score.next_group_score_path : score.next_score_path,changingScore:1},this.loadScore)
                      e.preventDefault();
                      return false;
                    }} >
                      <FontAwesomeIcon icon={[ "fas" , "chevron-right"]} /> <br/>
                      {changingScore !== 1 ? `${score.hole.hole + 1}` : <Loader containerClass="justify-content-center" forButton={true}/>}
                    </a> : <NavLink exact className="btn btn-lg btn-secondary position-fixed rounded-pill shadow pl-2 py-5 d-flex"  style={{"right": `${showMap ? -10 : elementOffset}px`,"top": "calc(50% - 79px)","height":"158px","width":"50px",zIndex:10001}} to={`/rounds/${score.round.id}`} >
                      <FontAwesomeIcon icon={[ "fas" , "check-circle"]} className="align-self-center" />
                    </NavLink>
                  }
                <button onClick={() => this.setState({showMap:!showMap},() => context.updateAppState({...context.app_state,showMap:this.state.showMap})) } className={`btn btn-lg btn-${!showMap ? 'secondary' : 'light'} rounded-circle position-fixed shadow`} style={{height:70,width:70,bottom:25,right:25,zIndex:10001}}>
                  {!showMap ? <FontAwesomeIcon icon={[ "far" , "map"]} /> : <FontAwesomeIcon icon={[ "fas" , "th-list"]} />}
                </button>
              </div>
            </div>
          </div>;
  }
}
