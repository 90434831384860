import React, { useState, useEffect } from 'react';
import Loader from '../helpers/Loader';
import moment from 'moment';

const ordinalizeNumber = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;
  const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
  return `${number}${suffix}`;
};


export default function Highlight() {

  const [loading, setLoading] = useState(true);
  const [highlight, setHighlight] = useState();

  useEffect(() => {
    const loadHighlight = () => {
      fetch(`${location.pathname}.json`).then(response => response.json()).then(data => {
        setHighlight(data);
        setLoading(false);
      });
    }

    loadHighlight();
  },[])


  return <div className="col-md-6 col-xl-4 px-0 align-self-start">
  <div className="card shadow-sm flexbox-container">
    <div className="card-header text-center">
       Highlight
    </div>
    <div className="card-body">
      {loading ? <Loader containerClass={"d-flex justify-content-center py-0"} /> : <>
        <div className="form-group rounded-lg bg-primary text-light mb-2 shadow-md">
          <div className="d-flex bg-dark text-light justify-content-between align-items-center px-2 py-2">
          <small className="d-flex align-items-center">
            <img className="rounded-circle mr-1" src={highlight.creator.profile_picture_url} width="20" height="20" />
            {highlight.creator.name}
          </small>
          <small>
            added at <i>{moment(highlight.created_at).fromNow()}</i>
          </small>
      </div>
      
          <video className="w-100 bg-dark" autoplay preload="auto" controls poster={highlight.preview_url}>
          <source src={highlight.video_url}/>
        </video>
      <div className="d-flex justify-content-between align-items-center px-2 pt-1 pb-2">
          <small>
            <i className="fas fa-user"></i> {highlight.player.name}
          </small>
          <small className="d-flex align-items-center"> 
            <div className="mr-2"><i className="fas fa-flag"></i>  {highlight.hole.hole} </div>
            <div className="rounded-pill border bg-secondary px-2 py-1">{ordinalizeNumber(highlight.shot)} shot</div>
          </small>
        </div>
      </div>
      
      </>}
    </div>
  </div>
  </div>
}
