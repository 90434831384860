import React from "react"
import Loader from '../helpers/Loader'
import Note from './Note'

export default class Notes extends React.Component {

  state = {
    notes:this.props.notes,
    loading:false
  }
  
  loadNotes = () => {
    let {notable_type,notable_id} = this.props;
    fetch(`/notes.json?notable_type=${notable_type}&notable_id=${notable_id}`).then(response => response.json()).then(data => this.setState({notes:data,loading:false}));
  }

  render() {
    let {loading,notes} = this.state;
    let {notable_type,notable_id} = this.props;

    if (loading) return <Loader />

    return <ul className="list-group">
        {notes.map((note) => <Note key={`note_${note.id}`} note={note} afterDelete={this.loadNotes} /> )}
        <Note key={`new_note_${new Date()}`} notable_id={notable_id} notable_type={notable_type} afterSave={this.loadNotes} />
      </ul>;
  }
}
