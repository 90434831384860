import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import Loader from "../helpers/Loader"
import {getCsrf, createRange} from "../helpers/CsrfTags"
import { AppContext } from "../core/AppContext";

const ScoreGroupHole = ({
  score:passedScore
}) => {
  
  const [saving,setSaving] = useState(false)
  const [saved,setSaved] = useState(false)
  const [error,setError] = useState(false)
  const [score,setScore] = useState(passedScore)
  const {current_user} = useContext(AppContext)
  const scrollerRef = useRef(false)

  let scoreRange = createRange(score.tee_box.par - 2,score.tee_box.par + 9)

  const updateScore = (field,value) => {
    
    score[field] = value;
    
    setSaving(true)

    fetch(`/scores/${score.id}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({score:score})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      setSaving(false)
      setError(false)
      setScore({...data})
    })
    .catch((response) => {
      response.json().then(error => {
        let field = Object.keys(error)[0];
        setSaving(false)
        setError(`${field} ${error[field]}`)
      })
    });

  }

  useLayoutEffect(() => {
    let scoreElement = document.getElementById(`score_${score.id}_score_${score.score}`);
    if (score.score && scrollerRef.current) scrollerRef.current.scrollLeft = scoreElement.offsetLeft - (window.innerWidth/2) + (scoreElement.offsetWidth/2) 
  },[])

  let editingAllowed = (score.round.player.allow_users_to_add_scores && !score.round.submitted)

  return <>
            {saving && <Loader containerClass="float-right" />}
            {
              error && <div className="alert alert-danger text-center" role="alert">
                        <strong>Uh Oh! Unable to save</strong> <br/> {error}
                      </div> 
            }
            <div className={`form-group`}>
              <label className={`font-weight-bolder ${current_user.id == score.round.player.id && 'text-secondary'} ${!editingAllowed && 'text-gray'}`} >{current_user.id == score.round.player.id ? 'You' : score.round.player.name}</label> <span id="score_badge" className="badge"></span> <br/>
              <div className="d-flex overflow-auto pb-2" ref={scrollerRef}>
              <button type="button" key={`score_${score.id}_score_nil`} className={`btn btn-lg mr-2 ${null == score.score ? 'btn-dark' : 'btn-outline-dark'} rounded-circle ${!editingAllowed && 'disabled'}`} onClick={() => updateScore('score',null)} >x</button>
                {scoreRange.map((n) => <button type="button" key={`score_${score.id}_score_${n}`} id={`score_${score.id}_score_${n}`} className={`btn btn-lg mr-2 ${n == score.score ? 'btn-dark' : 'btn-outline-dark'} rounded-circle ${!editingAllowed && 'disabled'}`} onClick={() => updateScore('score',n)} >{n}</button>)}
              </div>
            </div>
          </>
}

export default ScoreGroupHole;
