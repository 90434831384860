import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {getCsrf} from "../helpers/CsrfTags"
export default class Grouping extends React.Component {

  state = {
    grouping:this.props.grouping,
    available_rounds:this.props.availableRounds,
    editable:this.props.editable !== undefined ? this.props.editable : true,
    updating:false,
    saving:false,
    deleted:false
  }


  updateNewRoundOnGrouping = (e,round) => {

    let {grouping} = this.state;
    let checked = e.target.checked;
    
    if (checked) grouping.grouping_rounds_attributes.push({new:true,round_id:round.id,round:round,player:round.player})
    else grouping.grouping_rounds_attributes = grouping.grouping_rounds_attributes.filter((r) => r.round_id != round.id );

    this.setState({grouping:grouping});

  }

  updateRoundOnGrouping = (e,index) => {

    let {grouping} = this.state;
    let checked = e.target.checked;
    
    if (!checked){
      grouping.grouping_rounds_attributes[index]['_destroy'] = true;
    }
    else{
      delete grouping.grouping_rounds_attributes[index]['_destroy'];
    }

    this.setState({grouping:grouping});

  }

  saveGrouping = () => {
    
    let {grouping} = this.state;
    
    this.setState({saving:true});

    fetch(`${location.pathname}/groupings/${grouping.id}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({grouping:grouping})
    }).then(response =>  response.json()).then(data => this.setState({deleted:true,saving:false},() => {
      if(this.props.afterUpdate) this.props.afterUpdate(data)
    }));
  }

  deleteGrouping = () => {
    
    let {grouping} = this.state;
    
    this.setState({saving:true});

    fetch(`${location.pathname}/groupings/${grouping.id}.json`, {
      method: 'delete',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.text()).then(data => this.setState({deleted:true,saving:false},() => {
      if(this.props.afterUpdate) this.props.afterUpdate(data)
    }));
  }

  render() {
    
    let {grouping,updating,editing,saving,deleted,available_rounds,editable} = this.state;

    if(deleted) return <span/>;

    return <div className="card mb-3">
              {editable && <div className="card-header">
                <button className="btn btn-sm bg-white shadow-sm pull-right" onClick={() => this.setState({editing:!editing})} >{!editing ? <FontAwesomeIcon icon={[ "fas" , "pencil-alt"]} />:<FontAwesomeIcon icon={[ "fas" , "window-close"]} />}</button>
                {editing && <button className="btn btn-sm btn-success shadow-sm pull-right mr-2" onClick={this.saveGrouping} disabled={saving} >Save Changes</button>}
                {editing && <button className="btn btn-sm btn-danger shadow-sm pull-right mr-2" onClick={this.deleteGrouping} disabled={saving} >Delete Group</button>}
              </div>}
              <ul className="list-group">
                {
                grouping.grouping_rounds_attributes.filter((r) => !r.new ).map((grouping_round,index) => {
                  let round = grouping_round.round;
                  return <li className="list-group-item">
                    <div className="row">
                      {
                      editing && <div className="col"><div className="custom-control custom-switch pull-left mr-2">
                          <input type="checkbox" className="custom-control-input" disabled={updating} defaultChecked={true} id={`groupSwitchRound${round.id}`} onClick={(e) => this.updateRoundOnGrouping(e,index)} />
                          <label className="custom-control-label" for={`groupSwitchRound${round.id}`}></label>
                        </div></div>
                      }
                        <div className="col"><strong>{round.player.name}</strong></div>
                        <div className="col text-right"><strong>{round.final_score}</strong></div> 
                    </div>
                    </li>
                  })
                }
                {
                  editing && available_rounds.map((round,index) => {
                  return <li className="list-group-item">{
                    editing && <div className="custom-control custom-switch pull-left mr-2">
                        <input type="checkbox" className="custom-control-input" disabled={updating} id={`groupSwitchRound${round.id}`} onClick={(e) => this.updateNewRoundOnGrouping(e,round)} />
                        <label className="custom-control-label" for={`groupSwitchRound${round.id}`}></label>
                      </div>
                    }
                    <strong>{round.player.name}</strong></li> 
                  })
                }
              </ul>
          </div>;

  }
}
