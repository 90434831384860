import React from "react";

export default class ScoreCardScore extends React.Component {

  render() {

    let {score,editable = true,use_net} = this.props;
    let used_score = use_net ? score.net_score : score.score;

    if (!score.score) return <span className='p-3 text-dark'><strong>{editable ? '+' : '-'}</strong></span>;
    if (used_score == (score.tee_box.par - 2)) return <span className='p-3 score eagle text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par - 1)) return <span className='p-3 score birdie text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par + 1)) return <span className='p-3 score bogey text-dark'>{used_score}</span>;
    //if (score.score == (score.tee_box.par + 1)) return <span className='px-2 py-1 rounded-0 border border-primary'>{score.score}</span>;
    if (used_score == (score.tee_box.par + 2)) return <span className='p-3 score double-bogey text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par + 3)) return <span className='p-3 score triple-bogey text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par + 4)) return <span className='p-3 score quadruple-bogey text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par + 5)) return <span className='p-3 score plus-five text-dark'>{used_score}</span>;
    if (used_score == (score.tee_box.par + 6)) return <span className='p-3 score plus-six text-dark'>{used_score}</span>;

		return <span className='p-2 text-dark'>{used_score}</span>

  }
}
