import React, {useEffect, useState} from "react"
import { NavLink, useParams } from 'react-router-dom'
import {getCsrf} from "../helpers/CsrfTags"
import ScoreCard from '../components/ScoreCard'
import Loader from '../helpers/Loader'
import RoundSettingShare from './RoundSettingShare'
import {AppContext} from '../core/AppContext'
import LeaderboardButton from "./LeaderboardButton"

export default function Round({currentScore,url}){

  const [loading,setLoading] = useState(true)
  const [round,setRound] = useState()
  const [games,setGames] = useState([])
  const [groupings,setGroupings] = useState([])
  const {tournament_id, id} = useParams();

  
  const loadRound = () => {
    let usedUrl = url || location.pathname;
    fetch(`${usedUrl}.json`).then(response => response.json()).then(data => {
      setRound(data)
      setLoading(false)
    });
  }

  const submitRound = () => {
    round['submitted'] = true;
    fetch(`${url || location.pathname}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({round:round})
    }).then(response =>  response.json()).then(data => setRound({...round}));
  }


  useEffect(() => {
    loadRound()
  },[])

    let started = round ? round.scores.filter((s) => s.score !== null ).length > 0 : false;
    let complete = round ? round.scores.filter((s) => s.score == null ).length == 0 : false;
    let first_empty_score_link = (round && !complete) ? `/rounds/${round.id}/scores/${round.scores.filter((s) => s.score == null )[0].id}` : false

    return <AppContext.Consumer>
    {
      (context) =>  <div className="col-md-12 px-0 align-self-start">
      {
        loading && <Loader />
      }
      {
        games.length > 0 && <div className="alert alert-primary rounded-0 mb-0 text-center" role="alert">
        Gambles Attached to Round
        {games.map((game) => <NavLink to={`/round_settings/${round.round_setting.id}/games/${game.id}`} className="btn btn-primary btn-block mt-2 rounded-pill shadow-sm" >{game.name}</NavLink> )}
      </div>
      }
      {
        !loading && 
        <div className="card shadow-sm flexbox-container mb-20">
            {/* <RoundSettingShare round_setting={round.round_setting} text={"Add friends to this round"} inline={true} className={"bg-light border border-muted py-1 px-2 text-center"} btnClass={"btn btn-sm btn-secondary ml-2 shadow-sm rounded-pill py-0 mb-1"} btnText="Share Round" /> */}
            <LeaderboardButton key={`leaderboard_for_round_${round.id}`} round_id={round.id} />
            <div className="card-header text-center">
            {round.tournament && <span className="text-secondary ml-2">{round.tournament.name}</span>} {round.round_setting.name} 
              </div>
            <div className="card-body overflow-auto">
              <ScoreCard tournament_id={tournament_id} round={round} currentScore={currentScore} context={context} />

              <div className="position-absolute"><small className="text-info">* Stroke on hole</small></div>
              {
                (started && !complete) &&
                <div className="alert alert-warning text-center mt-5" role="alert">
                  You have {round.scores.filter((s) => s.score == null ).length} holes that need scores <br/><NavLink to={first_empty_score_link || `/rounds/${round.id}`} className="btn mt-2 rounded-pill btn-warning shadow-sm" >Add Scores</NavLink>
                </div>
              }

              { 
                !started &&
                <div className="d-flex flex-column mt-5 position-absolute w-75" style={{"left":"12.5%"}}>
                  <NavLink to={tournament_id ? `/tournaments/${tournament_id}`+first_empty_score_link || `/tournaments/${tournament_id}/rounds/${round.id}` : first_empty_score_link || `/rounds/${round.id}`} exact className="btn btn-lg btn-primary rounded-pill shadow-sm btn-block" >Start Round</NavLink>
                  <NavLink exact className="btn btn-lg btn-light shadow-sm border rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights`} >
                      View Highlights 
                    </NavLink><NavLink exact className="btn btn-lg btn-light shadow-sm border text-primary rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights/new`} >
                      + Add Highlight
                    </NavLink>
                </div>
              }

              { (started && !complete) &&
                <div className="d-flex flex-column mt-2 position-absolute w-75" style={{"left":"12.5%"}}>
                  <NavLink to={tournament_id ? `/tournaments/${tournament_id}`+first_empty_score_link || `/tournaments/${tournament_id}/rounds/${round.id}` : first_empty_score_link || `/rounds/${round.id}`} exact className="btn btn-lg btn-secondary rounded-pill shadow-sm btn-block" >Continue Round</NavLink>
                  <NavLink exact className="btn btn-lg btn-light shadow-sm border rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights`} >
                      View Highlights 
                    </NavLink><NavLink exact className="btn btn-lg btn-light shadow-sm border text-primary rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights/new`} >
                      + Add Highlight
                    </NavLink>
                </div>
              }

              {
                (complete || round.submitted) &&
                  <div className="d-flex flex-column position-absolute mt-1 w-75" style={{"left":"12.5%"}}>
                    <h1 className="text-primary mx-auto rounded-circle bg-light text-center p-4 border border-primary shadow">{round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0) }</h1>
                    {(complete && !round.submitted) && <div className="alert alert-secondary text-center mt-2" role="alert">
                        You have filled in all your scores <br/>
                        <button className="btn btn-secondary my-2 shadow-sm rounded-pill btn-block" onClick={submitRound} >Submit Round</button>
                        To Complete
                    </div>}
                    {(complete && round.submitted) && <div className="alert alert-success text-center mt-2" role="alert">
                        <h4>Your round has been Submitted</h4></div>}
                        <NavLink exact className="btn btn-lg btn-light shadow-sm border rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights`} >
                      View Highlights
                    </NavLink><NavLink exact className="btn btn-lg btn-light shadow-sm border text-primary rounded-pill btn-block" to={`/round_settings/${round.round_setting.id}/highlights/new`} >
                      + Add Highlight
                    </NavLink>
                  </div>
              }
          </div>
        </div>
      }
    </div>}
    </AppContext.Consumer>;

}