const getCsrf = () => {
  return document.querySelector('meta[name="csrf-token"]').content
}

const getHandicapsLocked = () => {
  return document.querySelector('meta[name="handicaps-locked"]').content == "true"
}

const getAllowingSignups = () => {
  return document.querySelector('meta[name="allowing-signups"]').content == "true"
}

const setCsrf = (value) => {
  document.querySelector('meta[name="csrf-token"]').setAttribute("content", value);
}

const setObjectValueFromDotNotation = (obj, is, value) => {

  if (typeof is == 'string'){
      return setObjectValueFromDotNotation(obj,is.split('.'), value);
  }
  else if (is.length==1 && value !== undefined){
      return obj[is[0]] = value;
  }
  else if (is.length==0){
    return obj;
  }
  else{
      return setObjectValueFromDotNotation(obj[is[0]],is.slice(1), value);
  }
}


const createRange = (lowEnd,highEnd) => {
    
  var list = [];
  
  for (var i = lowEnd; i <= highEnd; i++) {
    list.push(i);
  }

  return list;

}

export {
  getCsrf,
  setCsrf,
  getHandicapsLocked,
  getAllowingSignups,
  setObjectValueFromDotNotation,
  createRange
};