import React from "react";
import Loader from "../helpers/Loader"
import { NavLink } from 'react-router-dom'
import {getCsrf,createRange} from "../helpers/CsrfTags"
export default class ScoreHole extends React.Component {
  
  state = {
    score:this.props.score,
    saving:false,
    saved:false,
    error:false,
  }

  par(){
    let {score} = this.state;
    return score.tee_box.par;
  }
  
  fairwayStatuses(){
    return [
      {icon:"fas fa-chevron-left",status:'left'},
      {icon:"fas fa-chevron-right",status:'right'},
      {icon:"fas fa-bullseye",status:'hit'},
      {icon:"fas fa-chevron-up",status:'long'},
      {icon:"fas fa-chevron-down",status:'short'}
    ]
  }

  updateScore(field,value){
    let {score} = this.state;
    score[field] = value;
    this.setState({saving:true})
    fetch(`${location.pathname}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({score:score})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      this.setState({score:data,saving:false,error:false})
    })
    .catch((response) => {
      response.json().then(error => {
        let field = Object.keys(error)[0];
        this.setState({saving:false,error:`${field} ${error[field]}`})
      })
    });
  }

  render() {

    let {score,saving,error} = this.state;
    let twoUnder = this.par() - 2;
    let nineOver = this.par() + 9;

    let scoreRange = createRange(twoUnder,nineOver)
    let puttRange = createRange(0,7)

    return <form className="pb-0">
            {saving && <Loader containerClass="float-right" />}
            {
              error && <div className="alert alert-danger text-center" role="alert">
                        <strong>Uh Oh! Unable to save</strong> <br/> {error}
                      </div> 
            }
            <div className="form-group">
              <label>Score</label> <span id="score_badge" className="badge"></span> <br/>
              <div className="d-flex overflow-auto py-2">
              <button type="button" key={`score_${score.id}_score_nil`} className={`btn btn-lg mr-2 ${null == score.score ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => this.updateScore('score',null)} >x</button>
                {scoreRange.map((n) => <button type="button" key={`score_${score.id}_score_${n}`} className={`btn btn-lg mr-2 ${n == score.score ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => this.updateScore('score',n)} >{n}</button>)}
              </div>
            </div>
            <div className="form-group">
              <label>Putts</label> <br/>
              <div className="d-flex overflow-auto py-2">
              {puttRange.map((n) => <button type="button" key={`score_${score.id}_putts_${n}`}  className={`btn btn-lg mr-2 ${n == score.putts ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => this.updateScore('putts',n)} >{n}</button>)}
              </div>
            </div>
            <div className="form-group">
              <label>Fairway status</label>  {score.fairway_status && <span className={`badge ${score.fairway_status == 'hit' ? 'badge-success':'badge-danger'}`} >{score.fairway_status}</span> }<br/>
              <div className="d-flex overflow-auto py-2">
                {this.fairwayStatuses().map((f) => <button type="button" key={`score_${score.id}_fairway_status_${f.status}`}  className={`btn btn-lg mr-2 ${f.status == score.fairway_status ? 'btn-dark' : 'btn-outline-dark'} rounded-circle`} onClick={() => this.updateScore('fairway_status',f.status)} ><i className={f.icon} /></button> )}
              </div>
            </div>
            <div className="form-group">
              <label>Hazards</label> 
        
              <span id="score_bunkers_badge" onClick={() => this.updateScore('bunkers',0)} className={`badge badge-warning ml-1 ${(!score.bunkers || score.bunkers == 0) && 'd-none'}`}>Bunkers <span className="count">{score.bunkers}</span>
                <i className="ml-1 fas fa-times-circle" ></i>
              </span>
        
              <span id="score_water_hazards_badge" onClick={() => this.updateScore('water_hazards',0)} className={`badge badge-info ml-1 ${(!score.water_hazards || score.water_hazards == 0) && 'd-none'}`}>Water <span className="count">{score.water_hazards}</span>
                <i className="ml-1 fas fa-times-circle" ></i>
              </span>
        
              <span id="score_drops_badge" onClick={() => this.updateScore('drops',0)} className={`badge badge-danger ml-1 ${(!score.drops || score.drops == 0) && 'd-none'}`}>Drops <span className="count">{score.drops}</span>
                <i className="ml-1 fas fa-times-circle"></i>
              </span>
        
              <span id="score_out_of_bounds_badge" onClick={() => this.updateScore('out_of_bounds',0)} className={`badge badge-dark ml-1 ${(!score.out_of_bounds || score.out_of_bounds == 0) && 'd-none'}`}>OB <span className="count">{score.out_of_bounds}</span>
                <i className="ml-1 fas fa-times-circle"></i>
              </span>
        
              <br/>
        
              <div className="d-flex overflow-auto py-2">
                <button type="button" className="btn btn-lg mr-2 bg-white text-warning border border-warning rounded-circle shadow-sm" onClick={() => this.updateScore('bunkers',(score.bunkers || 0) + 1)} >
                  <i className="fas fa-umbrella-beach fa-sm"></i>
                </button>
                <button type="button" className="btn btn-lg mr-2 bg-white text-info border border-info rounded-circle shadow-sm" onClick={() => this.updateScore('water_hazards',(score.bunkers || 0) + 1)}>
                  <i className="fas fa-tint"></i>
                </button>
                <button type="button" className="btn btn-lg mr-2 bg-white border border-danger rounded-circle shadow-sm" onClick={() => this.updateScore('drops',(score.drops || 0) + 1)}>
                  <i className="fas fa-grip-lines-vertical fa-lg text-danger"></i>
                </button>
                <button type="button" className="btn btn-lg mr-2 btn-dark rounded-circle shadow-sm" onClick={() => this.updateScore('out_of_bounds',(score.out_of_bounds || 0) + 1)}>
                  <i className="fas fa-grip-lines-vertical fa-lg text-white shadow-sm"></i>
                </button>
              </div>
            </div>
          </form>;
  }
}
