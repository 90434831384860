import React, { useRef } from "react"
import { BrowserRouter, Route, Switch, Redirect, NavLink } from "react-router-dom"
import {AppContext,AppProvider} from '../core/AppContext.js';
import Header from "./navs/Header"
import Profile from "./Profile"
import Users from "./Users"
import Rules from "./Rules"
import Game from "./Game"
import Rounds from "./Rounds"
import Round from "./Round"
import RoundNew from "./RoundNew"
import RoundSetting from "./RoundSetting"
import RoundSettingJoin from "./RoundSettingJoin"
import Leaderboard from "./Leaderboard"
import Tournaments from "./Tournaments"
import Leaderboards from "./Leaderboards"
import Tournament from "./Tournament"
import YardageBook from "./YardageBook"
import Score from "./Score"
import ScoreMap from "./ScoreMap"
import Footer from "./navs/Footer"
import Notifications from "./Notifications"
import Highlights from "./Highlights";
import Highlight from "./Highlight";

export default function App (){
      
    const hasParentClass = ( e, classname ) => {
      if(e === document) return false;
      if( e.className.indexOf(classname) !== -1 ) return true;
      return e.parentNode && hasParentClass( e.parentNode, classname );
    }
  
      return <AppProvider>
            <AppContext.Consumer>
              {
                (context) => 
                <BrowserRouter> 
                    <div id="st-container" className={`st-container`}  onClick={context.toggleSidebar}>
                        <nav className="st-menu st-effect-9 bg-primary" id="menu-9">
                          <h5 className="text-white p-4 mb-2 shadow-lg">WeGolf</h5>
                          <div className="list-group list-group-flush text-white">
                            <NavLink to="/users/edit" onClick={context.closeSidebar} key="users_nav_link" exact className="list-group-item bg-primary text-white" activeClassName="bg-secondary" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-user"></i></div>
                                <div className="col">Profile</div>
                              </div>
                            </NavLink>
                            <NavLink to="/rounds" onClick={context.closeSidebar} key="rounds_nav_link" exact className="list-group-item bg-primary text-white" activeClassName="bg-secondary" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-golf-ball"></i> </div>
                                <div className="col">Rounds</div>
                              </div>
                            </NavLink>
                            <NavLink to="/leaderboards" onClick={context.closeSidebar} key="leaderboards_nav_link" exact className="list-group-item bg-primary text-white" activeClassName="bg-secondary" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-list-ol"></i> </div>
                                <div className="col">Leaderboards</div>
                              </div>
                            </NavLink>
                            <NavLink to="/tournaments" onClick={context.closeSidebar} key="tournaments_nav_link" exact className="list-group-item bg-primary text-white" activeClassName="bg-secondary" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-trophy"></i> </div>
                                <div className="col">Tournaments</div>
                              </div>
                            </NavLink>
                            {context.current_user.role.name == 'admin' && <NavLink to="/users" onClick={context.closeSidebar} key="tournaments_nav_link" exact className="list-group-item bg-primary text-white" activeClassName="bg-secondary" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-users"></i> </div>
                                <div className="col">Users</div>
                              </div>
                            </NavLink>}
                      
                            <a href="/users/sign_out" key="logout_nav_link" className="list-group-item bg-primary text-white" >
                              <div className="row">
                                <div className="col-1"><i className="fas fa-power-off"></i> </div>
                                <div className="col">Logout</div>
                              </div>
                            </a>
                          </div>
                        </nav>
                      <div className="st-pusher">
                        <div class="st-content">
                          <div className="st-content-inner">
                            <Notifications key={location.pathname} context={context} />
                            <Header />
                            <div className="container-fluid bg-light">
                              <div className="row flexbox-container justify-content-center align-items-center">
                                <Switch>
                                  <Route path="/users/edit" component={props => <Profile {...props} context={context} />} />
                                  { 
                                    !context.current_user.handicap && 
                                    <Redirect to="/users/edit?handicap_message=true" />
                                  }
                                  <Route path={["/rounds", "/rounds/past", "/rounds/past/complete", "/rounds/future"]}  exact component={props => <Rounds key={location.pathname} {...props} />} />
                                  <Route path="/courses/:id" component={props => <ScoreMap key={location.pathname} {...props} />} />
                                  <Route path="/rounds/:round_id/scores/:id" component={props => <Score key={location.pathname} {...props} context={context} />} />
                                  <Route path="/rounds/:round_id/highlights" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/rounds/:round_id/highlights/new" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/rounds/new" component={props => <RoundNew key={location.pathname} {...props} context={context} />} />
                                  <Route path="/groupings/:grouping_id/holes/:hole_id/scores" component={props => <Score key={location.pathname} {...props} context={context} />} />
                                  <Route path="/groupings/:grouping_id/holes/:hole_id/highlights" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/groupings/:grouping_id/holes/:hole_id/highlights/new" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/highlights/:highlight_id" component={props => <Highlight key={location.pathname} {...props} context={context} />} />
                                  <Route path="/rounds/:id" component={props => <Round key={location.pathname} {...props} context={context} />} />
                                  <Route path="/round_settings/:round_setting_id/games/:id/edit" component={props => <Game key={location.pathname} {...props} editing={true} />} />
                                  <Route path={["/round_settings/:round_setting_id/games/:id","/round_settings/:round_setting_id/games/:id/leaderboard"]} component={props => <Game key={location.pathname} {...props} />} />
                                  <Route path="/round_settings/:round_setting_id/highlights" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/round_settings/:round_setting_id/highlights/new" component={props => <Highlights key={location.pathname} {...props} context={context} />} />
                                  <Route path="/round_settings/:id/join" component={props => <RoundSettingJoin key={location.pathname} {...props} />} />
                                  <Route path="/round_settings/:id" component={props => <RoundSetting key={location.pathname} {...props} />} />
                                  <Route path="/tournaments/:tournament_id/rounds/:round_id/scores/:id" component={props => <Score key={location.pathname} {...props} context={context} />} />
                                  <Route path="/tournaments/:tournament_id/rounds/new" component={props => <RoundNew key={location.pathname} {...props} context={context} />} />
                                  <Route path={["/tournaments/:tournament_id/rounds", "/tournaments/:tournament_id/rounds/past", "/tournaments/:tournament_id/rounds/past/complete", "/tournaments/:tournament_id/rounds/future"]}  exact component={props => <Rounds key={location.pathname} {...props} />} />
                                  <Route path="/tournaments/:tournament_id/rounds/:id" component={props => <Round key={location.pathname} {...props} context={context} />} />
                                  <Route path="/tournaments/:id/leaderboard" component={props => <Leaderboard key={location.pathname} {...props} context={context} />} />
                                  <Route path="/tournaments/:tournament_id/rules" component={props => <Rules key={`${location.pathname}${location.search}`} {...props} />} />
                                  <Route path="/tournaments/:tournament_id/rounds" component={props => <Rounds key={location.pathname} {...props} context={context} />} />
                                  <Route path="/tournaments/:id/edit" component={props => <Tournament {...props} editing={true} />} />
                                  <Route path="/tournaments/new" component={props => <Tournament {...props} isNew={true} />} />
                                  <Route path="/tournaments/:id" component={props => <Tournament {...props} />} />
                                  <Route path="/tournaments" component={props => <Tournaments {...props} />} />
                                  <Route path="/leaderboards/:id" component={props => <Leaderboard {...props} />} />
                                  <Route path="/leaderboards" component={props => <Leaderboards {...props} />} />
                                  <Route path="/rules" component={props => <Rules key={`${location.pathname}${location.search}`} {...props} />} />
                                  <Route path="/users/me/yardage_book" component={props => <YardageBook {...props} />} />
                                  <Route path="/users" component={props => <Users {...props} />} />
                                  <Redirect to="/users/edit" />
                                </Switch>
                              </div>
                            </div>
                            <Switch>
                              <Route path={["/tournaments/:tournament_id","/tournaments/:tournament_id/rounds","/tournaments/:tournament_id/rounds/:id","/tournaments/:tournament_id/rounds/:round_id/scores/:id","/tournaments/:tournament_id/leaderboard","/tournaments/:tournament_id/rules"]} >
                                <Footer context={context} />
                              </Route>
                            </Switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </BrowserRouter>
              }
              </AppContext.Consumer>
            </AppProvider>;
    
}