import React from "react";
import Loader from "../helpers/Loader"
import { NavLink } from 'react-router-dom'
import {AppContext} from '../core/AppContext'

export default class Tournaments extends React.Component {

  state = {
    tournaments:[],
    loading:true
  }
  
  componentDidMount(){
    this.loadTournaments();
  }

  loadTournaments(){
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => this.setState({tournaments:data,loading:false}));
  }

  render() {

    let {tournaments,loading} = this.state;

    return <AppContext.Consumer>
            {
              (context) => <div className="col-md-6 col-xl-11 mt-lg-3 px-0 align-self-start">
                    <div className="card shadow flexbox-container rounded-0">
                      <div className="card-header text-center">
                        <button class="d-none btn btn-sm btn-light shadow-sm top-left position-absolute mr-5" >Refresh</button>  
                        Tournaments
                        {context.current_user.role.name == 'admin' && <NavLink to="/tournaments/new" className="btn btn-sm btn-secondary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i></NavLink>}
                      </div>
                      <div className="card-body pt-0 px-0 px-lg-3">
                        {loading && <Loader />}
                        {
                        (!loading && tournaments.length > 0) &&
                          <div className="list-group">    
                          {
                            tournaments.map((tournament) => <NavLink to={`/tournaments/${tournament.id}${context.current_user.role.name != 'admin' ? '/rounds' : ''}`} className="list-group-item list-group-item-action rounded-0" ><strong>{tournament.name}</strong> <NavLink to={`/tournaments/${tournament.id}/leaderboard`} ><i className="fas fa-list-ol pull-right"></i></NavLink></NavLink>)
                          }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
              }
              </AppContext.Consumer>;
  }
}
