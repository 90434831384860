import React from "react"
import {AppContext} from '../core/AppContext'
import { NavLink } from 'react-router-dom'
import {getCsrf,getHandicapsLocked,getAllowingSignups} from "../helpers/CsrfTags"
import Loader from '../helpers/Loader'

export default class Profile extends React.Component {

  state = {
    user:{},
    allowing_signups:getAllowingSignups(),
    handicaps_locked:getHandicapsLocked(),
    loading:true,
    saving:false,
    saved:false,
    error:false
  }
  componentDidMount(){
    this.loadUser();
  }

  loadUser(){
    fetch(`/users/me.json`).then(response => response.json()).then(data => {
      let {saving} = this.state;
      this.setState({user:data,loading:false,saved:saving,saving:false})
    });
  }
  
  toggleHandicapsLocked = (e) => {
    
    let {handicaps_locked} = this.state;
    this.setState({saving:true})
    fetch(`/admin/settings`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({setting:{handicaps_locked:!handicaps_locked ? "1" : "0"}})
    }).then(response =>  response.text()).then(data => {
      this.setState({handicaps_locked:!handicaps_locked,saving:false})
    });
    
    e.preventDefault();
    e.stopPropagation();
    return false;

  }

  toggleAllowingSignups = (e) => {
    
    let {allowing_signups} = this.state;
    this.setState({saving:true})
    fetch(`/admin/settings`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({setting:{allowing_signups:!allowing_signups ? "1" : "0"}})
    }).then(response =>  response.text()).then(data => {
      this.setState({allowing_signups:!allowing_signups,saving:false})
    });
    
    e.preventDefault();
    e.stopPropagation();
    return false;

  }

  submitUser = (e) => {

    let {user} = this.state;
    this.setState({saving:true})
    fetch(`/users.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({user:user})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.text()
    }).then(data => {
      this.loadUser()
    }).catch((response) => {
      response.json().then(error => {
        console.log("error",error)
        let field = Object.keys(error.errors)[0];
        this.setState({saving:false,error:`${error.errors[field][0]}`})
      })
    });;

    e?.preventDefault();
    e?.stopPropagation();
    return false;
  }

  updateUser = (e,callback) =>{
    let target = e.target;
    let {name,value} = target;
    let {user} = this.state;
    user[name] = value;
    this.setState({user:user},callback)
  }

  updateProfilePicture = (e) => {

    let file = e.target.files[0];
    let {user} = this.state;

    if (file){

      let reader = new FileReader()

      reader.onload = (oe) => {
        user['profile_picture_url'] = oe.target.result;

        this.setState({user:user});

        const formData = new FormData()
        formData.append('user[profile_picture]', file)

        fetch('/users', {
          method: 'PUT',
          headers: {
            'X-CSRF-Token': getCsrf()
          },
          body: formData
        })
        .then(response =>  response.text())
        .then(data => {
          this.loadUser()
        })
        .catch(error => {
          console.error(error)
        })

      };

      reader.readAsDataURL(file)

    }

  }

  render() {

    let {user,loading,saving,saved,error,allowing_signups,handicaps_locked} = this.state;

    if (loading) return <Loader />;

    return  <div className="col-md-6 col-xl-4 px-0 align-self-start">
              <div className="card rounded-0 shadow-sm flexbox-container">
                <div className="card-body pb-5">
                  {
                    this.props.location.search.indexOf("handicap_message") > -1 && 
                    <div className="alert alert-warning text-center" role="alert">
                      Please add your <strong>handicap</strong> below to get started!
                    </div>
                  }
                  {
                    error && <div className="alert alert-danger text-center" role="alert">
                              <strong>Uh Oh! Unable to save</strong> <br/> {error}
                            </div> 
                  }
                    <div className="form-group d-flex profile-picture" style={{"backgroundImage":`url(${user.profile_picture_url})`}} id="form-profile-picture">
                      <button className="align-self-end mx-auto btn-primary p-3 shadow-sm mb-3 rounded-pill" onClick={() => this.refs.pictureFile.click() }><strong>Tap to update profile pic</strong></button>
                      <input className="d-none" type="file" ref="pictureFile" onChange={this.updateProfilePicture} />
                    </div>

            
                    <div className="form-group">
                      <label>Name</label><br />
                      <input placeholder="John Daly" className="form-control" type="text" name="name" id="user_name" defaultValue={user.name} onChange={this.updateUser} />
                    </div>
            
                    <div className="form-group">
                      <label>Phone number</label><br />
                      <input placeholder="8646080586" className="form-control" type="text" name="phone_number" id="user_phone_number" defaultValue={user.phone_number} onChange={this.updateUser} />
                    </div>
            
                    <div className="form-group">
                      <label>Handicap {handicaps_locked && <span className="badge badge-primary">Locked</span>}</label><br />
                      <input placeholder="12.3" disabled={handicaps_locked} className="form-control" type="number" name="handicap" id="user_handicap" defaultValue={user.handicap} onChange={this.updateUser} />
                    </div>
            
                    <div className="form-group">
                      <label>Tag line</label><br />
                      <input placeholder="Grip it n rip it" className="form-control" type="text" name="tag_line" id="user_tag_line" defaultValue={user.tag_line} onChange={this.updateUser} />
                    </div>
                  
                    <div className="form-group">
                      <label>Group settings</label><br />
                      <div class="custom-control custom-switch">
                        <input type="checkbox" key={'group_settings_switch'} className="custom-control-input" defaultChecked={user.allow_users_to_add_scores} id="allowUsersToEditScores" onChange={(e) => this.updateUser({target:{name:"allow_users_to_add_scores",value:!user.allow_users_to_add_scores}})} />
                        <label className="custom-control-label" htmlFor="allowUsersToEditScores">Allow members to edit scores</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Notifications</label><br />
                      <div class="custom-control custom-switch">
                        <input type="checkbox" key={'group_settings_switch'} className="custom-control-input" defaultChecked={user.notifications_settings?.leaderboard_updates} id="notificationsSettingsLeaderboardUpdates" onChange={(e) => this.updateUser({target:{name:"notifications_settings",value:{...user.notifications_settings,leaderboard_updates:e.target.checked}}},this.submitUser)} />
                        <label className="custom-control-label" htmlFor="notificationsSettingsLeaderboardUpdates">Send leaderboard updates</label>
                      </div>
                    </div>
                    {
                        this.props.context.current_user.role.name == 'admin' &&
                        <>
                        <div className="form-group">
                          <label>Allow signups</label><br />
                          <div class="custom-control custom-switch">
                            <input type="checkbox" key={'allow_signups_switch'}  className="custom-control-input" checked={allowing_signups} id="allowSignups" onChange={this.toggleAllowingSignups} />
                            <label className="custom-control-label" htmlFor="allowSignups">Allow new signups</label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Lock HCP updates</label><br />
                          <div class="custom-control custom-switch">
                            <input type="checkbox" key={'allow_hcps_switch'}  className="custom-control-input" checked={handicaps_locked} id="allowHCP" onChange={this.toggleHandicapsLocked} />
                            <label className="custom-control-label" htmlFor="allowHCP">Lock HCPs</label>
                          </div>
                        </div>
                        </>
                      }
                    <div className="form-group pt-3 pb-1">
                      {!saved && <button className="btn btn-secondary btn-lg btn-block rounded-pill shadow-sm" disabled={saving} onClick={this.submitUser} >{saving && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>} Update</button> }
                      {saved && <button className="btn btn-success btn-lg btn-block rounded-pill shadow-sm" disabled={saving} onClick={this.submitUser} >{saving && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>} Saved <i className="fas fa-check"></i></button> }
                    </div>
                    
                    <hr/>

                    <div className="form-group pt-3">
                      <NavLink exact className="btn btn-dark btn-lg btn-block rounded-pill shadow-sm" to={`/users/me/yardage_book`} >
                        <i className="fas fa-book mr-2"></i> Yardage Book
                      </NavLink>
                    </div>

                    <div className="form-group pb-5">
                      <a className="btn btn-light btn-lg btn-block rounded-pill shadow-sm" rel="nofollow" data-method="delete" href="/users/sign_out" ><i className="fas fa-power-off mr-2"></i> Logout</a>
                    </div>

                </div>
              </div>
</div>;
  }
}
