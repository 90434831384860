import React from "react"
import {AppContext} from '../core/AppContext'
import copyToClipboard from '../helpers/CopiedToClipboard'

export default class RoundSettingShare extends React.Component {

  state = {
    copied_text:'',
    error:false
  }
  
  render() {

    let {copied_text,error} = this.state;
    let {round_setting,text,inline,className,btnText,btnClass} = this.props;
    let btn_text = btnText || "Copy Share Link";

    return <AppContext.Consumer>
    {
      (context) => <div className={className || "bg-light border border-muted p-3 text-center rounded-bottom"}>
               {!inline && <p className="mb-0"><em>{text || <span>Share these tee times and <strong>WeGolf</strong> ...</span>}</em></p>}
               <p className={!inline ? "mb-2" : "mb-0"}><button onClick={() => copyToClipboard(`https://wegolf.com/u/${round_setting.share_token}?t=${context.current_user.invite_token}`,(text,error) => this.setState({copied_text:text}))} className="btn btn-link p-0 m-0 text-info">https://wegolf.com/u/{round_setting.share_token}?t={context.current_user.invite_token}</button> {inline && <button onClick={() => copyToClipboard(`https://wegolf.com/u/${round_setting.share_token}?t=${context.current_user.invite_token}`,(text,error) => this.setState({copied_text:text}))} className={btnClass || "btn btn-sm btn-dark ml-2 shadow-sm rounded-pill"}>{copied_text == `https://wegolf.com/u/${round_setting.share_token}?t=${context.current_user.invite_token}` ? "Copied!" : btn_text}</button>}</p>
              {!inline && <button onClick={() => copyToClipboard(`https://wegolf.com/u/${round_setting.share_token}?t=${context.current_user.invite_token}`,(text,error) => this.setState({copied_text:text}))} className={btnClass || "btn btn-sm btn-dark ml-2 shadow-sm rounded-pill"}>{copied_text == `https://wegolf.com/u/${round_setting.share_token}?t=${context.current_user.invite_token}` ? "Copied!" : btn_text}</button>}
            </div>
      }
    </AppContext.Consumer>;


  }
}