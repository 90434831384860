import React from "react"
import { NavLink, Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {getCsrf} from "../helpers/CsrfTags"
import CourseSearchInput from '../components/CourseSearchInput'
import PlayingWithInput from '../components/PlayingWithInput'
import Loader from '../helpers/Loader'
import contrast from '../helpers/Contrast'
import {AppContext} from '../core/AppContext'

export default class RoundNew extends React.Component {

  state = {
    loading:false,
    saving:false,
    course:false,
    destroyed:false,
    round_setting:{},
    round:{},
    games:[],
    error:false,
    groupings:[],
  }

  componentDidMount(){
    const { match: { params } } = this.props;
    if (params.tournament_id) this.setState({round_setting:{tournament_id:params.tournament_id}})
  }

  setCourse = (course) => {
    let {round_setting} = this.state;
    round_setting['course_id'] = course.id;
    this.setState({round_setting:round_setting,course:course},() => this.createRound())
  }

  removeCourse = () => {
    this.setState({round_setting:{},course:false})
  }

  updatePlaying = (playing) => {
    let {round_setting} = this.state;
    round_setting.rounds_attributes = playing.map((p) => p.players.map((player) => { return {player_id:player.id,should_add_scores:true} } ) ).flat()
    round_setting.group_settings = playing;
    this.setState({round_setting:round_setting})
  }

  setTeeBox = (tee_box) => {
    let {round_setting} = this.state;
    round_setting['tee_box_id'] = tee_box.id;
    this.setState({round_setting:round_setting})
  }

  setHoles = (hole) => {
    let {round_setting} = this.state;
    round_setting['holes'] = hole;
    this.setState({round_setting:round_setting})
  }

  calculateHoles = () => {
    let {course} = this.state;
    if (course.holes.length > 17) return [{name:"All 18",number:18},{name:"Front 9",number:9},{name:"Back 9",number:-9}]
    return [{name:"9 Holes",number:9}]
  }

  createRound = () => {
    
    let {round_setting} = this.state;
    let {context} = this.props;

    this.setState({saving:true})

    fetch(`/round_settings.json`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({round_setting:round_setting})
    }).then(response =>  response.json()).then(data => {
      
      data['rounds_attributes'] = [{player_id:context.current_user.id,should_add_scores:true}];
      data['group_settings'] = [{players:[{id:context.current_user.id}]}] 

      this.setState({round_setting:data,saving:false})
    });
  }

  destroyRoundSetting = () => {

    let {round_setting} = this.state;
    if (!round_setting.id) return this.setState({destroyed:true})


    this.setState({saving:true})

    fetch(`/round_settings/${round_setting.id}.json`, {
      method: 'delete',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      }
    }).then(response =>  response.text()).then(data => {
      this.setState({destroyed:true})
    });

  }

  updateRoundSetting = (e) => {
    let {round_setting} = this.state;
    let target = e.target;
    let {name,value} = target;
    round_setting[name] = value;
    this.setState({round_setting});
  }

  updateRound = () => {
    
    let {round_setting} = this.state;
    let {context} = this.props;

    this.setState({saving:true})

    fetch(`/round_settings/${round_setting.id}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({round_setting:round_setting})
    }).then(response =>  response.json()).then(data => {
      console.log('round_setting update',data)
      let my_round = data.rounds.find((r) => r.player.id == context.current_user.id)
      this.setState({round:my_round,saving:false})
    });

  }

  render() {

    let {loading,course,round,round_setting,saving,destroyed} = this.state;
    
    if (destroyed) return <Redirect to={`/rounds`} />
    if (round.id) return <Redirect to={`/rounds/${round.id}`} />

    return <AppContext.Consumer>
    {
      (context) =>  <div className="col-md-6 col-xl-4 px-0 align-self-start">
      {
        loading && <Loader />
      }
      {
        !loading && 
          <div className="card shadow-sm flexbox-container pb-5">
            <div className="card-header text-center">
              Create New Round
              <button onClick={this.destroyRoundSetting} className="btn btn-sm btn-dark shadow-sm top-right position-absolute ml-5" ><i className="fas fa-times"></i></button>
            </div>
            <div className="card-body">
              <CourseSearchInput onChosen={this.setCourse} onRemoved={this.removeCourse} />
              
              {
              course && 
                <>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label>Tees</label>
                    <small id="courseHelp" className="form-text text-muted">Which tees?</small>
                  </div>
                  <div className="overflow-auto py-1">
                  {course.tee_boxes.map((tee_box) => <button type="button" key={`tee_box_${tee_box.id}`}  className={`btn btn-sm mr-2 mb-2 rounded ${(round_setting.tee_box_id && round_setting.tee_box_id === tee_box.id) ? 'border border-dark shadow-sm' : 'shadow-sm'} ${(round_setting.tee_box_id && round_setting.tee_box_id !== tee_box.id) && 'opacity-low'}`} style={{backgroundColor:tee_box.tee_color,color:contrast(tee_box.tee_color,150)}} onClick={() => this.setTeeBox(tee_box)} >
                    <p className="mb-0 text-capitalize font-weight-bold">{tee_box.tee_color_name}</p>
                    <p className="mb-0"><small>{tee_box.yards} yds</small></p>
                    <p className="mb-0"><small><small><em>{tee_box.rating}/{tee_box.slope}</em></small></small></p>
                  </button>)}
                  </div>
                </div>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                    <label>Holes</label>
                    <small id="courseHelp" className="form-text text-muted">How many holes?</small>
                  </div>
                  <div className="d-flex overflow-auto py-1">
                  {this.calculateHoles().map((hole) => <button type="button" key={`holes_${course.id}_${hole.name}`}  className={`btn mr-2 rounded-pill shadow-sm ${hole.number == round_setting.holes ? 'btn-dark' : 'btn-outline-dark'}`} onClick={() => this.setHoles(hole.number)} >{hole.name}</button>)}
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <div>
                      <label>Starts</label> <br/>
                      <DatePicker showTimeSelect={true} selected={round_setting.start_time ? new Date(round_setting.start_time) : null} className="form-control" onChange={date => this.updateRoundSetting({target:{name:"start_time",value:date}})} />
                    </div>
                    <div>
                      <label>Ends</label> <br/>
                      <DatePicker showTimeSelect={true} selected={round_setting.end_time ? new Date(round_setting.end_time) : null} className="form-control" onChange={date => this.updateRoundSetting({target:{name:"end_time",value:date}})} />
                    </div>
                  </div>
                </div>
                <PlayingWithInput context={context} round_setting={round_setting} onUpdate={this.updatePlaying} />
                </>
              }
              <button onClick={this.updateRound} className="btn btn-secondary rounded-pill shadow-sm btn-lg btn-block my-4" disabled={!course || saving}>Start Round {saving && <Loader forButton={true} />}</button>
            </div>
          </div>
      }
    </div>}
    </AppContext.Consumer>;


  }
}