import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {getCsrf} from "../helpers/CsrfTags"
import * as turf from '@turf/turf';
import useBearing from '../helpers/useBearing';
import { AppContext } from '../core/AppContext';
import NotesButton from './NotesButton';
import { NavLink } from 'react-bootstrap';
import useUserLocation from '../hooks/useUserLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MapBox = ({score,bounds,greenLngLat,teeBoxLngLat,centerLngLat}) => {
  
  const mapRef = useRef(false)
  const mapBoxRef = useRef(false)
  const userLocationRef = useRef(false)
  const teeRef = useRef(false)
  const centerRef = useRef(false)
  const greenRef = useRef(false)
  const [centerSaved,setCenterSaved] = useState(false)
  const {current_user} = useContext(AppContext);
  const {userLocation, error:userLocationError} = useUserLocation()
  
  const [center, setCenter] = useState({userSet:false,lngLat:centerLngLat})
  const [mapLoaded,setMapLoaded] = useState(false)
  const {getBearing,middlePoint} = useBearing();

  useEffect(() => {
    setCenter({userSet:false,lngLat:centerLngLat})
  },[centerLngLat])

  let linestring = {
    'type': 'Feature',
    'geometry': {
    'type': 'LineString',
    'coordinates': []
    }
  };

  let geojson = {
    'type': 'FeatureCollection',
    'features': []
    };


  const distanceInYards = (fromLngLat,toLngLat) => {
    var from = turf.point(fromLngLat);
    var to = turf.point(toLngLat);
    var options = {units: 'kilometers'};

    var distance = turf.distance(from, to, options);
    return Math.round((distance * 1000) * 1.0936);
  }

  const userLocationAvailable = () => !!userLocation?.latitude
  const userCloseEnoughToTee = () => distanceInYards(userLocationLngLat(),teeBoxLngLat) < 500
  const userCloseEnoughToGreen = () => distanceInYards(userLocationLngLat(),teeBoxLngLat) < 200
  const userCloseEnough = () => userLocationAvailable() && (userCloseEnoughToTee() || userCloseEnoughToGreen())

  const userLocationLngLat = () => [userLocation.longitude,userLocation.latitude]

  const currentStartCoords = () => userCloseEnough() ? userLocationLngLat() : teeBoxLngLat
  const currentBearingCoords = () => centerLngLat.length ? centerLngLat : greenLngLat

  const currentCenterCoords = () => {
    if (!center.userSet && (userLocationAvailable() && userCloseEnoughToGreen()) || score.tee_box.par === 3) {
      return greenLngLat
    }else{
      return centerLngLat.length ? centerLngLat : middlePoint(teeBoxLngLat,greenLngLat)
    }
  }

  const distanceFromStart = () => {
    if (userCloseEnough()) return distanceInYards(userLocationLngLat(),center.lngLat)
    return distanceFromTee()
  }

  const distanceFromTee = () => {
    return distanceInYards(teeBoxLngLat,center.lngLat);
  }

  const distanceToGreen = () => {
    return distanceInYards(center.lngLat,greenLngLat);
  }

  const updateGuideLines = (e) => {

    let isClick = e.point;
    var lngLat = e.lngLat || centerRef.current.getLngLat();
    var coords = e.point || e.target._pos;
    var newCenterLngLat = [lngLat.lng,lngLat.lat];

    setCenter({userSet:isClick,lngLat:newCenterLngLat});

    let startLatLng = currentStartCoords()
    linestring.geometry.coordinates = [startLatLng,newCenterLngLat,greenLngLat]
    
    let point = {
      'type': 'Feature',
      'geometry': {
      'type': 'Point',
      'coordinates': newCenterLngLat
      }
    }

    geojson.features = [point,linestring];
    mapBoxRef.current.getSource('geojson').setData(geojson);

    if (isClick) centerRef.current.setLngLat(lngLat)

  }

  const createTargetElement = () => {
    let targetElement = document.createElement('div');
    targetElement.className = '';
    targetElement.innerHTML = '<h1><i class="fas fa-bullseye" style="color:rgba(255,255,255,0.4)"></i></h1>'
    targetElement.style.width = 20 + 'px';
    targetElement.style.height = 20 + 'px';
    targetElement.style.display = 'block';
    return targetElement
  }

  const saveCenterForHole = () => {

    var lngLat = centerRef.current.getLngLat();

    fetch(`/holes/${score.hole.id}.json`, {
      method: 'put',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCsrf()
      },
      body: JSON.stringify({hole:{drive_lat:lngLat.lat,drive_lng:lngLat.lng}})
    }).then(response =>  {
      if (!response.ok) { throw response }
      return response.json()
    }).then(data => {
      setCenterSaved(true)
    })
    .catch((response) => {
      response.json().then(error => {
        console.log('error',error)
      })
    });
  }

  useLayoutEffect(() => {

      if (!mapRef.current) return;
        
      mapBoxRef.current = new mapboxgl.Map({
        container: mapRef.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        bounds:bounds,
        pitch: 30,
        bearing:getBearing(currentStartCoords(),currentBearingCoords()),
        padding:{top: 50, bottom:120, left: 20, right: 20}
      });

      const setMapLayers = () => {

        mapBoxRef.current.on('click', updateGuideLines)
    
        mapBoxRef.current.addSource('geojson', {
          'type': 'geojson',
          'data': geojson
        });
    
        mapBoxRef.current.addLayer({
          id: 'measure-lines',
          type: 'line',
          source: 'geojson',
          layout: {
          'line-cap': 'round',
          'line-join': 'round'
          },
          paint: {
          'line-color': '#fff',
          'line-width': 1.5
          },
          filter: ['in', '$type', 'LineString']
        });
        
        mapBoxRef.current.addLayer({
          id: 'measure-points',
          type: 'circle',
          source: 'geojson',
          paint: {
          'circle-radius': 5,
          'circle-color': '#000'
          },
          filter: ['in', '$type', 'Point']
        });
          
        var teeBoxElement = document.createElement('div');
        teeBoxElement.className = '';
        teeBoxElement.innerHTML = '<h4><i class="fas fa-golf-ball text-white"></i></h4>'
        teeBoxElement.style.width = 20 + 'px';
        teeBoxElement.style.height = 20 + 'px';
        teeBoxElement.style.display = 'block';
    
        teeRef.current = new mapboxgl.Marker({element:teeBoxElement,anchor:'bottom'})
          .setLngLat(teeBoxLngLat)
          .addTo(mapBoxRef.current);
    
        var flagElement = document.createElement('div');
        flagElement.className = '';
        flagElement.innerHTML = '<h4><i class="fas fa-flag text-light"></i></h4>'
        flagElement.style.width = 20 + 'px';
        flagElement.style.height = 20 + 'px';
        flagElement.style.display = 'block';
        
        let targetElement = createTargetElement()
    
        centerRef.current = new mapboxgl.Marker({element:targetElement,draggable:true,offset:[-10, -14]})
          .setLngLat(currentCenterCoords())
          .addTo(mapBoxRef.current);
        
        centerRef.current.on('drag', (e) => updateGuideLines(e));
    
        greenRef.current = new mapboxgl.Marker({element:flagElement,anchor:'bottom',offset:[7,-5],clickTolerance:50})
          .setLngLat(greenLngLat)
          .addTo(mapBoxRef.current);
        greenRef.current.getElement().addEventListener('click', (e) => {
          updateGuideLines({point:greenRef.current._pos,lngLat:greenRef.current._lngLat})
          e.stopPropagation();
          e.preventDefault();
          return false;
        })
    
        setMapLoaded(true)
        
      }

      const updateMapLayers = () => {
        if (centerRef && centerRef.current){
          var lngLat = centerRef.current.getLngLat();
          var centerLngLat = [lngLat.lng,lngLat.lat];
          let newCenter = {point:centerRef.current._pos,lngLat:centerLngLat}
          setCenter(newCenter);
        }
      }

      mapBoxRef.current.on('load', setMapLayers);
      mapBoxRef.current.on('drag', updateMapLayers);
      mapBoxRef.current.on('zoom', updateMapLayers);

      () => {
        mapBoxRef.current.off('load', setMapLayers)
        mapBoxRef.current.off('drag', updateMapLayers)
        mapBoxRef.current.off('zoom', updateMapLayers)
      }
  },[])

  const setBoundsToUserLocation = () => {
    if (!userLocation?.latitude) return;
    mapBoxRef.current.fitBounds([userLocationLngLat(),greenLngLat],{pitch: 30,padding:{top: 50, bottom:100, left: 20, right: 20},bearing:getBearing(userLocationLngLat(),currentBearingCoords())});  
  }

  useLayoutEffect(() => {

    if (!mapLoaded) return;

      setCenterSaved(false)

      let needsEstimatedCenter = centerLngLat.length == 0
      mapBoxRef.current.fitBounds(bounds,{pitch: 30,padding:{top: 50, bottom:100, left: 20, right: 20},bearing:getBearing(currentStartCoords(),currentBearingCoords())});  
      greenRef.current.setLngLat(greenLngLat)
      teeRef.current.setLngLat(teeBoxLngLat)

      const updateLayersFromCenter = () => {
        let centerCoords = currentCenterCoords();
        let startLatLng = currentStartCoords();
        linestring.geometry.coordinates = [startLatLng, centerCoords, greenLngLat]
          
        let point = {
          'type': 'Feature',
          'geometry': {
          'type': 'Point',
          'coordinates': centerCoords
          }
        }
    
        geojson.features = [point,linestring];
        mapBoxRef.current.getSource('geojson').setData(geojson);
        centerRef.current.remove();
    
        let targetElement = createTargetElement()
    
        centerRef.current = new mapboxgl.Marker({element:targetElement,draggable:true,offset:[-10, -14]})
        .setLngLat(centerCoords)
        .addTo(mapBoxRef.current);
      
        centerRef.current.on('drag', (e) => updateGuideLines(e));
        mapBoxRef.current.off('click', updateGuideLines)
        mapBoxRef.current.on('click', updateGuideLines)
    
        setTimeout(() => setCenter({...center,lngLat:centerCoords}),500);
        
      }

      updateLayersFromCenter()


  },[mapLoaded, bounds])

  useLayoutEffect(() => {
    if (!mapLoaded || !userLocation?.latitude) return;
    
    const updateUserLocationMarker = () => {
    
      let distanceChange = 51;
      
      if (!userLocationRef.current){
        let userLocationElement = document.createElement('div');
        userLocationElement.className = '';
        userLocationElement.innerHTML = '<h6><i class="fas fa-circle text-info rounded-circle border border-light"></i></h6>'
        userLocationElement.style.width = 20 + 'px';
        userLocationElement.style.height = 20 + 'px';
        userLocationElement.style.display = 'block';
  
        userLocationRef.current = new mapboxgl.Marker({element:userLocationElement,offset:[2, 0]})
        .setLngLat([userLocation.longitude,userLocation.latitude])
        .addTo(mapBoxRef.current);
      }else {
        let usersLastLngLat = userLocationRef.current.getLngLat()
        
        distanceChange = distanceInYards([usersLastLngLat.lng,usersLastLngLat.lat],[userLocation.longitude,userLocation.latitude])
        userLocationRef.current.setLngLat([userLocation.longitude,userLocation.latitude])
        
        if (distanceChange > 50) setCenter({...center,userSet:false})
  
      }
  
    }

    updateUserLocationMarker();
  },[mapBoxRef.current, centerRef.current, mapLoaded, userLocation])

  return <div style={{width:window.innerWidth,height:window.innerHeight,position:'fixed',top:0,left:0,zIndex:10000}} >
    <p className="text-center d-flex mb-0 position-absolute w-100 text-white align-items-center" style={{zIndex:1,height:35,background:"rgba(0,0,0,0.2)",backdropFilter:"blur(3px)"}}>
      {score.tee_box.handicap <= score.round.handicap && <span className="align-top text-info mt-3 position-absolute"><small>*</small></span>}
      {(score.round.handicap > 18 && score.tee_box.handicap <= (score.round.handicap - 18)) && <span className="align-top text-info ml-2 mt-3 position-absolute"><small>*</small></span>}
      <span className="flex-fill">
        <span>Hole </span><span className="font-weight-bolder">{score.hole.hole}</span>
      </span>
      <span className="flex-fill border-left border-dark">
        <span className="font-weight-bolder">{score.tee_box.yards}</span> <small>yds</small>
      </span>
      <span className="flex-fill border-left border-dark">
        Par <span className="font-weight-bolder">{score.tee_box.par}</span>                 
      </span>

    </p>
    <div style={{width:"100%",height:"100%"}} ref={mapRef} />
    {
    center.lngLat.length &&
      <>
      <button className="btn btn-sm btn-light rounded-circle shadow position-fixed text-info" onClick={setBoundsToUserLocation} style={{top:50,left:10}}>
        <FontAwesomeIcon icon={["fas","location-arrow"]} /> 
      </button>
      {
        current_user.role.name === 'admin' && <button className={`btn btn-sm btn-${centerSaved ? 'success' :'light' } rounded-circle shadow position-fixed`} onClick={saveCenterForHole} style={{top:50,right:10}}>
          <FontAwesomeIcon icon={["fas",centerSaved ? "check" : "bullseye"]} />
        </button>
      }
      {
        centerRef.current && <div className="noselect border border-dark text-white position-fixed rounded-circle text-center d-flex justify-content-center align-items-center shadow" style={{width:70,height:70,top:centerRef.current._pos.y + 20,left:centerRef.current._pos.x - 90,background:'rgba(0,0,0,0.5)',backdropFilter:"blur(8px)"}}>
          {distanceFromStart()} yds
        </div>
      }
      {
      (centerRef.current && score.tee_box.par !== 3 && distanceToGreen() > 0) && <div className="noselect border border-dark text-white position-fixed rounded-circle text-center d-flex justify-content-center align-items-center shadow" style={{width:70,height:70,top:centerRef.current._pos.y - 90,left:centerRef.current._pos.x + 20,background:'rgba(0,0,0,0.5)',backdropFilter:"blur(8px)"}}>
        {distanceToGreen()} yds
        </div>
      }
      </>
    }
  </div>
}

export default MapBox