import React, {useEffect, useState} from "react"
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function LeaderboardAddButton({round_id}){

  const [loading, setLoading] = useState(false)
  const [leaderboard, setLeaderboard] = useState({side_bets:[]})
  const [showModal,setShowModal] = useState(false)

  const loadLeaderboard = async () => {

    let response = await fetch(`/rounds/${round_id}/leaderboard.json`)
    console.log('leaderboard response',response)
    if (response.status !== 404) {
      let json = await response.json()
      setLeaderboard(json)
    }
    
    setLoading(false)

  }

  const updateLeaderboard = (key,value) => {
    leaderboard[key] = value;
    if (key === 'handicap_adjusted' && leaderboard.handicap_par_threes === undefined) leaderboard['handicap_par_threes'] = true
    setLeaderboard({...leaderboard})
  }

  if (loading) return <span/>

  return <>
  <button className="btn btn-sm btn-secondary btn-block rounded-0 text-white font-weight-bold" onClick={() => setShowModal(true)}>
  Add Leaderboard
  </button><Modal dialogClassName="m-0 m-sm-auto" show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    New Leaderboard
  </Modal.Header>
  <Modal.Body className="bg-light">
    <div className="form-group mb-3">
      <div className="d-flex justify-content-between">
        <label>Type</label>
        <small id="courseHelp" className="form-text text-muted">We playing teams or what?</small>
      </div>
        <div className="d-flex py-1">
          <button type="button"  className={`btn btn-sm flex-column d-flex justify-content-center align-items-center ${leaderboard.scoring && leaderboard.scoring !== 'team' && 'opacity-low'} ${leaderboard.scoring === 'team' ? 'bg-primary border-primary text-white shadow-sm' : 'bg-white border-dark' } rounded-circle mr-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('scoring','team')}>
              <FontAwesomeIcon icon={[ "fas" , "user-friends"]} />
              <span>Team</span>
          </button>
          <button type="button"  className={`btn btn-sm flex-column d-flex justify-content-center align-items-center ${leaderboard.scoring && leaderboard.scoring !== 'individual' && 'opacity-low'} ${leaderboard.scoring === 'individual' ? 'bg-primary border-primary text-white shadow-sm' : 'bg-white border-dark' } rounded-circle`} 
          style={{width:80,height:80}}
          onClick={() => updateLeaderboard('scoring','individual')}>
            <FontAwesomeIcon icon={[ "fas" , "user"]} />
            <span>Individual</span>
          </button>
        </div>
    </div>

    {leaderboard.scoring === 'team' && <div className="form-group mb-3">
      <div className="d-flex justify-content-between">
        <label>Set Teams</label>
        <small id="courseHelp" className="form-text text-muted">10 players unassigned</small>
      </div>
      <button className="btn btn-sm btn-default bg-white rounded-pill shadow-sm">
        + New Team
      </button>
    </div>}
    <div className="form-group mb-3">
      <div className="d-flex justify-content-between">
        <label>Format</label>
        <small id="courseHelp" className="form-text text-muted">How we scoring?</small>
      </div>
        <div className="overflow-auto py-1">
          <button type="button"  className={`btn btn-sm ${leaderboard.type && leaderboard.type !== "Leaderboard::StrokePlay" && 'opacity-low'} ${leaderboard.type === "Leaderboard::StrokePlay" ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('type',"Leaderboard::StrokePlay")}>
              Stroke Play
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.type && leaderboard.type !== "Leaderboard::MatchPlay" && 'opacity-low'} ${leaderboard.type === "Leaderboard::MatchPlay" ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2`} 
          style={{width:80,height:80}}
          onClick={() => updateLeaderboard('type',"Leaderboard::MatchPlay")}>
              Match Play
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.type && leaderboard.type !== "Leaderboard::Skins" && 'opacity-low'} ${leaderboard.type === "Leaderboard::Skins" ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('type',"Leaderboard::Skins")}>
              Skins
          </button>
        </div>
        <div className="form-group mt-2 ml-1 py-3 d-flex justify-content-between">
          <div class="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" defaultChecked={leaderboard.handicap_adjusted} id="handicapAdjusted" onChange={(e) => updateLeaderboard('handicap_adjusted',!leaderboard.handicap_adjusted)} />
            <label className="custom-control-label" for="handicapAdjusted">HCP Adjusted</label>
          </div>
        {leaderboard.handicap_adjusted &&
          <div class="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" defaultChecked={leaderboard.handicap_par_threes} id="handicappedParThrees" onChange={(e) => updateLeaderboard('handicap_par_threes',!leaderboard.handicap_par_threes)} />
            <label className="custom-control-label" for="handicappedParThrees">HCP Par 3s</label>
          </div>}
        </div>
    </div>
    {leaderboard.type === "Leaderboard::MatchPlay" && <div className="form-group mb-3">
      <div className="d-flex justify-content-between">
        <label>Side Bets?</label>
        <small id="courseHelp" className="form-text text-muted">Playing trash?</small>
      </div>
        <div className="overflow-auto py-1">
          <button type="button"  className={`btn btn-sm ${leaderboard.side_bets.indexOf("greenies") > -1 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('side_bets',leaderboard.side_bets.indexOf("greenies") === -1 ? [...leaderboard.side_bets,"greenies"] : leaderboard.side_bets.filter((sb) => sb !== "greenies"))}>
              Greenies
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.side_bets.indexOf("birdies") > -1 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('side_bets',leaderboard.side_bets.indexOf("birdies") === -1 ? [...leaderboard.side_bets,"birdies"] : leaderboard.side_bets.filter((sb) => sb !== "birdies"))}>
              Birdies
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.side_bets.indexOf("poleys") > -1 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
          style={{width:80,height:80}}
          onClick={() => updateLeaderboard('side_bets',leaderboard.side_bets.indexOf("poleys") === -1 ? [...leaderboard.side_bets,"poleys"] : leaderboard.side_bets.filter((sb) => sb !== "poleys"))}>
              Poleys
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.side_bets.indexOf("sandies") > -1 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('side_bets',leaderboard.side_bets.indexOf("sandies") === -1 ? [...leaderboard.side_bets,"sandies"] : leaderboard.side_bets.filter((sb) => sb !== "sandies"))}>
              Sandies
          </button>
        </div>
    </div>}
    {leaderboard.type !== undefined && <div className="form-group mb-3">
      <div className="d-flex justify-content-between">
        <label>Payouts</label>
        <small id="courseHelp" className="form-text text-muted">What we playing fur?</small>
      </div>
        <div className="overflow-auto py-1">
        <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== null && 'opacity-low'} ${leaderboard.bet === null ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('bet',null)}>
              None
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== 2 && 'opacity-low'} ${leaderboard.bet === 2 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('bet',2)}>
              $2
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== 5 && 'opacity-low'} ${leaderboard.bet === 5 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('bet',5)}>
              $5
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== 10 && 'opacity-low'} ${leaderboard.bet === 10 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
          style={{width:80,height:80}}
          onClick={() => updateLeaderboard('bet',10)}>
              $10
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== 20 && 'opacity-low'} ${leaderboard.bet === 20 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('bet',20)}>
              $20
          </button>
          <button type="button"  className={`btn btn-sm ${leaderboard.bet && leaderboard.bet !== 30 && 'opacity-low'} ${leaderboard.bet === 30 ? 'bg-primary border-primary text-white' : 'bg-white border-dark' } rounded-circle mr-2 mb-2`} 
            style={{width:80,height:80}}
            onClick={() => updateLeaderboard('bet',30)}>
              Custom Amount
          </button>
        </div>
    </div>}
    <div className="form-group">
      <a className="btn btn-lg btn-primary rounded-pill shadow btn-block" onClick={() => setShowModal(false)} >
        Create Leaderboard
      </a>
    </div>
  </Modal.Body>
  </Modal>
  </>;
}
