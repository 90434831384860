import React from "react"
import { NavLink } from 'react-router-dom'
import ScoreCardScore from './ScoreCardScore'
import ScoreCardScoreButton from './ScoreCardScoreButton'
import {AppContext} from '../core/AppContext'
import Loader from '../helpers/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class ScoreCard extends React.Component {

  state = {
    round:this.props.round,
    tournament_id:this.props.tournament_id,
    loading:false,
    grouping:false,
    showGrouping:this.props.context.current_user.app_state.showGrouping,
    showHoleSpecs:this.props.context.current_user.app_state.showHoleSpecs,
    groupHandicaps:this.props.context.current_user.app_state.groupHandicaps,
    elementOffset:0,
    orientation:window.orientation == 0 ? 'portrait' : 'landscape'
  }

  componentDidMount(){
    if (this.state.showGrouping) this.loadGroupings();
    window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener("orientationchange",this.handleOrientation.bind(this), false);
  }

  componentWillUnmount(){
    let {showGrouping,showHoleSpecs,groupHandicaps} = this.state;
    let {context} = this.props;
    let current_user = context.current_user;

    if (showGrouping != current_user.app_state.showGrouping || showHoleSpecs != current_user.app_state.showHoleSpecs || groupHandicaps != current_user.app_state.groupHandicaps)   

    window.removeEventListener('scroll', this.handleScroll.bind(this));
    window.removeEventListener('orientationchange', this.handleOrientation.bind(this));

  }

  handleOrientation(e){
    this.setState({orientation:window.orientation == 0 ? 'portrait' : 'landscape'})
  }

  handleScroll(e){

    let {elementOffset} = this.state;

    const window = e.currentTarget;
    elementOffset = window.scrollY;

    this.setState({elementOffset:elementOffset})
      
  }

  loadGroupings = () => {
    let {round} = this.props;
    this.setState({loading:true})
    fetch(`/round_settings/${round.round_setting.id}/groupings.json`).then(response => response.json()).then(data => {
      this.setState({grouping:data[0],loading:false})
    });
  }

  render() {

    let {round,grouping,showGrouping,groupHandicaps,loading,elementOffset,orientation,showHoleSpecs,tournament_id} = this.state;
    let front_nine_scores = round.scores.slice(0,9);
    let back_nine_scores = round.scores.slice(9,18);
    let hcp_diff = 0;

    if (showGrouping && grouping && groupHandicaps) hcp_diff = Math.min(...grouping.rounds.map((r) => r.handicap))

    return <AppContext.Consumer>
    {
      (context) => [<table className={`table table-bordered table-sm table-scorecard text-center ${showGrouping ? 'grouping' : 'shadow-sm'}`} >
              <thead className="thead-light">
                <tr>
                  <th scope="col" className={showGrouping && 'position-fixed'} style={{marginTop:`-${elementOffset}px`}}>
                    <button className="btn text-secondary p-0 mr-1" onClick={() => this.setState({showHoleSpecs:!showHoleSpecs},() =>  context.updateAppState({showGrouping:showGrouping,showHoleSpecs:!showHoleSpecs,groupHandicaps:groupHandicaps}))} style={{marginLeft:"-24px",marginTop:"-6px"}}><FontAwesomeIcon icon={["fas", showHoleSpecs ? "minus-circle" : "plus-circle"]} /></button>  
                    <strong>Hole</strong>
                  </th>
                  {front_nine_scores.map((score) => <th className={this.props.currentScore && this.props.currentScore.id == score.id ? 'table-success' : ''} scope={`col`} key={`score_${score.id}_hole_${score.hole.id}_hole`}>{score.hole.hole}</th>)}
                  <th scope="col text-center"><strong>OUT</strong></th>
                  {back_nine_scores.map((score) => <th scope="col" key={`score_${score.id}_hole_${score.hole.id}_hole`}>{score.hole.hole}</th>)}
                  <th scope="col text-center"><strong>IN</strong></th>
                  <th scope="col text-center"><strong>TOT</strong></th>
                  <th scope="col text-center"><strong>HCP</strong></th>
                  <th scope="col text-center"><strong>NET</strong></th>
                </tr>
              </thead>
              <tbody>
                {showHoleSpecs && [<tr className="bg-light text-dark font-weight-bolder">
                  <td className={`bg-light ${showGrouping && 'position-fixed'}`} style={{marginTop:`-${elementOffset}px`}}><strong>HCP</strong></td>
                    {front_nine_scores.map((score) => <td className="px-0" key={`score_${score.id}_hole_handicap`}>{score.tee_box.handicap}</td>)}
                  <td className="text-center"><strong>
                    
                  </strong></td>
                    {back_nine_scores.map((score) => <td className="px-0" key={`score_${score.id}_hole_handicap`}>{score.tee_box.handicap}</td>)}
                  <td className="text-center">
                  </td>
                  <td className="text-center">
                  </td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                </tr>]}
                <tr className="bg-light">
                  <td className={`bg-light ${showGrouping && 'position-fixed'}`} style={{marginTop:`-${elementOffset}px`}}><strong>Par</strong></td>
                    {front_nine_scores.map((score) => <td key={`score_${score.id}_par`}>{score.tee_box.par}</td>)}
                  <td className="text-center"><strong>
                    {round.round_setting.tee_box.front_nine_par || front_nine_scores.map((score) => score.tee_box.par ).reduce(function(a, b){ return a + b }, 0)}
                  </strong></td>
                    {back_nine_scores.map((score) => <td key={`score_${score.id}_par`}>{score.tee_box.par}</td>)}
                  <td className="text-center">
                    <strong>
                      {round.round_setting.tee_box.back_nine_par || back_nine_scores.map((score) => score.tee_box.par ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>
                  <td className="text-center">
                    <strong>
                      {round.round_setting.tee_box.part || round.scores.map((score) => score.tee_box.par ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                </tr>
                {
                (showGrouping && grouping) &&
                grouping.rounds.filter((r) => r.id != round.id ).map((round,index) => {
                  
                  let front_nine_scores = round.scores.slice(0,9);
                  let back_nine_scores = round.scores.slice(9,18);
                  let names = round.player.name.split(' ');
                  let initials = ((names[0] || '') +" "+ (names[1] ? `${names[1][0]}.` : ''));

                  return <tr className={`${!round.player.allow_users_to_add_scores && 'no-edits'}`} key={`player_${round.player.id}_round_${round.id}`}>
                    <td className="bg-white position-fixed text-truncate px-1" style={{marginTop:`-${elementOffset}px`}}>
                      <small><strong>{initials}</strong></small>
                    </td>
                    {
                      front_nine_scores.map((score) => {
                        
                        let backgroundImage = false;
                        let adjusted_hcp = round.handicap - hcp_diff;

                        if (score.tee_box.handicap <= adjusted_hcp){
                          if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = `url(/img/two_strokes_bottom.png)`;
                          else backgroundImage = `url(/img/one_stroke_bottom.png)`;
                        }

                        return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`} key={`score_${score.id}_score`} style={{backgroundImage:backgroundImage}}>
                          <ScoreCardScoreButton score={score} round={round} showHandicap={true} player={round.player.name} expands={true} showGrouping={true} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} />
                        </td>
                      })
                    }
                    <td className="text-center">
                      <strong>
                        {front_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                      </strong>
                    </td>
                    {
                      back_nine_scores.map((score) => {

                        let backgroundImage = false;
                        let adjusted_hcp = round.handicap - hcp_diff;

                        if (score.tee_box.handicap <= adjusted_hcp){
                          if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = "url(/img/two_strokes_bottom.png)";
                          else backgroundImage = "url(/img/one_stroke_bottom.png)";
                        }

                        return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`} key={`score_${score.id}_score`} style={{backgroundImage:backgroundImage}}>
                          <ScoreCardScoreButton score={score} round={round} showHandicap={true} showGrouping={true} player={round.player.name} expands={true} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} />
                        </td>
                      })
                    }
                    <td className="text-center">
                      <strong>
                        {back_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                      </strong>
                    </td>

                    <td className="text-center">
                      <strong>
                        {round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                      </strong>
                    </td>

                    <td className="text-center"><strong>{round.handicap}</strong></td>
                    <td className="text-center">
                      <strong>
                        {round.scores.map((s) => {
                          if (!round.handicap || round.handicap < s.tee_box.handicap) return s.score
                          else if (round.handicap > 18) return s.tee_box.handicap <= round.handicap - 18 ? s.score - 2 : s.score - 1
                          else return s.score - 1
                         } ).reduce(function(a, b){ return a + b }, 0)}
                      </strong>
                    </td>
                  </tr>})
                }
                <tr>
                  <td className={`bg-white ${showGrouping && 'position-fixed'}`} style={{marginTop:`-${elementOffset}px`}}>
                    {round.has_grouping && <button className="btn text-secondary p-0 position-absolute" onClick={() => this.setState({showGrouping:!showGrouping},() => {if (!showGrouping) this.loadGroupings();
                     context.updateAppState({showGrouping:!showGrouping,showHoleSpecs:showHoleSpecs,groupHandicaps:groupHandicaps}) })} style={{marginLeft:showGrouping ? "-28px" : "-15px"}}><FontAwesomeIcon spin={loading} icon={["fas", showGrouping ? "minus-circle" : "plus-circle"]} />
                    </button>}
                    <strong className="ml-1">{showGrouping ? "You" : "Scores"}</strong>
                  </td>
                  {
                    front_nine_scores.map((score) => {

                      let backgroundImage = false;
                      let adjusted_hcp = round.handicap - hcp_diff;

                        if (score.tee_box.handicap <= adjusted_hcp){
                          if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = `url(/img/two_strokes_bottom.png)`;
                          else backgroundImage = `url(/img/one_stroke_bottom.png)`;
                        }

                      return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`} key={`score_${score.id}_score`} style={{backgroundImage:backgroundImage}}>
                        {(showGrouping || orientation == 'landscape') ? <ScoreCardScoreButton score={score} round={round} showHandicap={true} showGrouping={showGrouping}  player={'Your'} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} expands={true} /> : <NavLink to={`${tournament_id ? '/tournaments/'+tournament_id : ''}/rounds/${round.id}/scores/${score.id}`} >
                          <ScoreCardScore score={score} />
                        </NavLink>}
                      </td>
                    })
                  }
                  <td className="text-center">
                    <strong>
                      {front_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>
                  {
                    back_nine_scores.map((score) => {

                      let backgroundImage = false;
                      let adjusted_hcp = round.handicap - hcp_diff;

                        if (score.tee_box.handicap <= adjusted_hcp){
                          if (adjusted_hcp > 18 && score.tee_box.handicap <= (adjusted_hcp - 18)) backgroundImage = `url(/img/two_strokes_bottom.png)`;
                          else backgroundImage = `url(/img/one_stroke_bottom.png)`;
                        }

                      return <td className={`${score.tee_box.handicap <= adjusted_hcp && 'bg-black-50'}`} key={`score_${score.id}_score`} style={{backgroundImage:backgroundImage}}>
                        {(showGrouping || orientation == 'landscape') ? <ScoreCardScoreButton score={score} round={round} showGrouping={showGrouping} showHandicap={true} player={''} title={<span>Hole {score.hole.hole} <em className="ml-1">Par {score.tee_box.par}</em></span>} expands={true} /> : <NavLink to={`${tournament_id ? '/tournaments/'+tournament_id : ''}/rounds/${round.id}/scores/${score.id}`} >
                          <ScoreCardScore score={score} />
                        </NavLink>}
                      </td>
                    })
                  }
                  <td className="text-center">
                    <strong>
                      {back_nine_scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>

                  <td className="text-center">
                    <strong>
                      {round.scores.map((s) => s.score ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>

                  <td className="text-center"><strong>{round.handicap}</strong></td>
                  <td className="text-center">
                    <strong>
                    {round.scores.map((s) => {
                          if (!round.handicap || round.handicap < s.tee_box.handicap) return s.score
                          else if (round.handicap > 18) return s.tee_box.handicap <= round.handicap - 18 ? s.score - 2 : s.score - 1
                          else return s.score - 1
                         } ).reduce(function(a, b){ return a + b }, 0)}
                    </strong>
                  </td>
                </tr>
                
              </tbody>
                </table>, (showGrouping && grouping && grouping.rounds && grouping.rounds.length > 0) ? <button className={`btn btn-${groupHandicaps ? 'outline-' :''}secondary rounded-pill mb-3`} onClick={() => this.setState({groupHandicaps:!groupHandicaps},() =>  context.updateAppState({showGrouping:showGrouping,showHoleSpecs:showHoleSpecs,groupHandicaps:!groupHandicaps}))}><FontAwesomeIcon icon={["fas", groupHandicaps ? "minus-circle" : "plus-circle"]} /> {groupHandicaps ? "Show Total Strokes" : "Show Group Strokes"}</button> : <span />
                  ]}</AppContext.Consumer>;

  }
}