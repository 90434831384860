import React from "react"
const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default class ScoreMap extends React.Component {

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 6
  };

  state = {
    course:false,
    loading:true,
    orientation:'portrait'
  }

  componentDidMount(){
    this.loadCourse();
  }

  componentWillUnmount() {
  }

  handleOrientation(e){
    this.setState({orientation:window.orientation == 0 ? 'portrait' : 'landscape'})
  }
  
  loadCourse(){
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => this.setState({course:data,loading:false}));
  }

  render() {
    let {course} = this.state;
    console.log('course',course);

    return <div style={{ height: '100vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBBHu_WbTiy4IKinPG93TrQQb-7V9TXquc" }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
  }
}
