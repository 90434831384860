import { useEffect, useState } from 'react';
const useUserLocation = () => {
  const [userLocation,setUserLocation] = useState(false)
  const [error,setError] = useState(false)


  const startedWatchingLocation = (position) => {
    var userCoordinates = position.coords;
    setUserLocation(userCoordinates)
  }

  const errorWatchingLocation = (error) => {
    console.log("error on watch location",error)
    setError(error)
  }

  const getCurrentPositionAsync = () => new Promise((resolve,reject) => navigator.geolocation.getCurrentPosition(resolve,reject))
  const watchPositionAsync = (options = {enableHighAccuracy: true, timeout: 5000, maximumAge: 0}) => new Promise((resolve,reject) => navigator.geolocation.watchPosition(resolve,reject,options))

  const startWatchingLocation = async () => {
    // let position = await getCurrentPositionAsync()
    // setUserLocation(position.coords)
    navigator.geolocation.watchPosition(startedWatchingLocation,errorWatchingLocation,{enableHighAccuracy: true, timeout: 5000, maximumAge: 0})
  }

  useEffect(() => {
    startWatchingLocation();
  },[])

  return {userLocation,error};
  
}

export default useUserLocation;