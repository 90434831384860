import React, {useContext, useState} from "react";
import { AppContext } from "../core/AppContext";
import LeaderboardRowRoundScore from './LeaderboardRowRoundScore';

const LeaderboardRow = ({has_teams,score,index,tied,previous_index,with_handicap,with_score}) => {

    const context = useContext(AppContext) 
    const [showScores, setShowScores] = useState(false)
    let player_name = score.player.name
    let [first_name,last_name] = player_name.split(' ')

    return <>
    <tr className={`${(context && context.current_user && context.current_user.id === score.player.id) && 'table-info'} ${index === 0 && 'table-success'} ${showScores && 'table-dark'} player`} 
    key={`player_score_${score.player.id}`}
    onClick={() => setShowScores(!showScores)}>
    <td className="text-center align-middle" style={{fontSize:"85%"}} ><strong>{tied ? `T-${previous_index + 1}` : index + 1}</strong></td>
    <td>
      <div className="row">
        <div className="col">
          <p className="mb-0">
          <small>{has_teams ? <span>{player_name}</span> : <span>{first_name} {last_name && ` ${last_name[0]}.`}</span>}
            <strong className="ml-1" style={{fontSize:"75%"}}>({score.course_handicap} CH)</strong>
            </small>
          </p>
        </div>
      </div>
    </td>
    {(with_score && with_handicap) && <td className={`text-center align-middle ${score.net_under > 0 && 'text-danger'} ${score.net_under < 0 && 'text-success'}`}>{score.net_under == 0 ? "E" : score.net_under}</td>}
    {(with_score && !with_handicap) && <td className="text-center align-middle">{score.under == 0 ? "E" : score.under}</td>}
    {!with_score && <td className={`text-center align-middle text-success`}>{with_handicap ? score.net_score : score.total_score}</td>}
    {with_score && !has_teams && <td className="text-center align-middle">{with_handicap ? score.net_today_under : score.today_under}</td>}
    {!with_score && !has_teams && <td className="text-center align-middle">{with_handicap ? score.net_today_score : score.today_score}</td>}
    <td className="text-center align-middle">{score.holes_completed % 18 === 0 && score.holes_completed !== 0 ? "F" :  score.holes_completed % 18 }</td>
    { !has_teams && <td className="text-center align-middle">{score.holes_completed}</td> }
  </tr>
  {showScores && <LeaderboardRowRoundScore round_ids={score.round_ids} onClose={() => setShowScores(!showScores)} />}
  </>;

}

export default LeaderboardRow;
