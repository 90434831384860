import React, {useContext, useEffect, useState} from "react";
import { NavLink, useParams } from 'react-router-dom'
import {AppContext} from '../core/AppContext'
import LeaderboardButton from "./LeaderboardButton";
import Loader from '../helpers/Loader'
import Moment from 'react-moment'

export default function Rounds(){
  
  const {tournament_id} = useParams();
  const context = useContext(AppContext);
  const [rounds,setRounds] = useState([])
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState(false)
  
  const loadRounds = () => {
    fetch(`${location.pathname}.json`).then(response => response.json()).then(data => {
      setRounds(data)
      setLoading(false)
    });
  }

  useEffect(() => {
    loadRounds()
  },[])


  let past = location.pathname.indexOf('past') > -1;
  let future = location.pathname.indexOf('future') > -1;

  return <div className="col-md-6 col-xl-4 px-0 align-self-start">
          {tournament_id && <LeaderboardButton key={`leaderboard_for_tournament_${tournament_id}`} tournament_id={tournament_id} />}
          <div className="card shadow-sm flexbox-container rounded-0">
            <div className="card-header text-center">
              Rounds
              {context.current_user.role.name == 'admin' && <NavLink to={tournament_id ? `/tournaments/${tournament_id}/rounds/new` : "/rounds/new"} className="btn btn-sm btn-secondary shadow-sm top-right position-absolute ml-5" ><i className="fas fa-plus"></i></NavLink>}
            </div>
            <div className="card-body">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <NavLink to={tournament_id ? `/tournaments/${tournament_id}/rounds/past` : '/rounds/past'} className="nav-link" activeClassName="active" >Past</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={tournament_id ? `/tournaments/${tournament_id}/rounds` : '/rounds'} exact className="nav-link" activeClassName="active" >Todays</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={tournament_id ? `/tournaments/${tournament_id}/rounds/future` : '/rounds/future'} exact className="nav-link" activeClassName="active" >Future</NavLink>
                </li>
              </ul>
              <hr/>

              {
                loading && <Loader containerClass={"d-flex justify-content-center py-5"} />
              }

              {
                (!loading && rounds.length == 0) &&
                <p className="lead text-center">You do not have any {future && 'future'} rounds {(!past && !future) && 'today'}</p>
              }

              {
                !loading && rounds.map((round) => {
                  
                  let started = round.final_score > 0

                  return <NavLink to={round.round_setting?.tournament_id ? `/tournaments/${round.round_setting?.tournament_id}/rounds/${round.id}` : `/rounds/${round.id}`} exact className={started ? `btn btn-${round.submitted ? "outline-secondary" : "outline-primary"} shadow-sm btn-block mb-3` : "btn btn-outline-dark shadow-sm btn-block mb-3"} >
                          <h5 className="pull-right position-absolute text-primary mx-auto rounded-circle bg-light text-center p-2 border border-primary shadow-sm mt-3 d-flex align-items-center justify-content-center"  style={{width:50,height:50}}>{(round.submitted || started) ? round.final_score : '-'}</h5>
                          <strong>{round.round_setting.name}</strong> <br/> <em>{round.tournament ? round.tournament.name : <Moment format="MM/DD/YYYY">{round.round_setting.start_time}</Moment>}</em> <br/>
                          {
                            started && 
                            <span>{round.submitted ? "View Round" : "Continue Round"}</span>
                          }
                          {
                            !started && 
                            <span>Start Round</span>
                          }
                        </NavLink>;
                })
              }
            </div>
          </div>
        </div>;
    
}